import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { EventDetailsScreen } from '@/feature/event-details-sbk/screens/EventDetails';
import { LeagueHubScreen } from '@/feature/league-hub-sbk/screens/LeagueHub';
import { LiveHubScreen } from '@/feature/live-hub-sbk/screens/LiveHub';
import { FeaturedHubScreen } from '@/feature/lobby-sbk/screens/FeaturedHub';
import { BettingLobbyScreen } from '@/feature/lobby-sbk/screens/Lobby';
import { BettingSearchScreen } from '@/feature/search-sbk/screens/BettingSearchScreen';

import { SBKLobbyStackParamList } from './types';

const Stack = createNativeStackNavigator<SBKLobbyStackParamList>();

export const LobbyStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="BettingLobbyScreen" component={BettingLobbyScreen} />
            <Stack.Screen name="BettingSearchScreen" component={BettingSearchScreen} />
            <Stack.Screen name="EventDetails" component={EventDetailsScreen} />
            <Stack.Screen name="LeagueHub" component={LeagueHubScreen} />
            <Stack.Screen name="LiveHub" component={LiveHubScreen} />
            <Stack.Screen name="FeaturedHub" component={FeaturedHubScreen} />
        </Stack.Navigator>
    );
};
