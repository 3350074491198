import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
        <Path
            fill="#FA052E"
            fillRule="evenodd"
            d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z"
            clipRule="evenodd"
        />
        <Path
            fill="#000"
            fillRule="evenodd"
            d="m10 11.236 3.624 3.623 1.237-1.237-3.623-3.624 3.623-3.624-1.237-1.237L10 8.76 6.376 5.137 5.14 6.374l3.624 3.624-3.624 3.624 1.237 1.237L10 11.236Z"
            clipRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
