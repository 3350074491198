import React from 'react';
import { TouchableOpacity } from 'react-native';

import ShareIcon from '@/assets/icons/share-icon';
import { BadgeStatus, StatusBadge } from '@/components/StatusBadge';
import { Text, TextProps } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { SEPARATOR_HEIGHT, designSystem } from '@/styles/styles';

import { BetSummarySection, BetSummarySectionProps } from './BetSummarySection';
import { DotStatus, ProgressDots } from './ProgressDots';

type BetCardProps = {
    title: string;
    subTitle: string;
    status?: BadgeStatus;
    footer?: string;
    summary: BetSummarySectionProps;
    titleColor?: TextProps['color'];
    subTitleColor?: TextProps['color'];
    progressDots?: Array<DotStatus>;
    outerBorderColor?: TextProps['color'];
    shareIconInHeader?: boolean;
    onSharePress?: () => void;
    testID?: string;
};

export const BetCard = ({
    title,
    subTitle,
    status,
    footer,
    summary,
    titleColor = 'gray1',
    subTitleColor = 'gray2',
    progressDots = [],
    outerBorderColor = 'gray6',
    shareIconInHeader,
    onSharePress,
    testID,
}: BetCardProps) => {
    return (
        <Box borderWidth={1} borderColor={outerBorderColor} borderRadius="r12">
            <Row padding="s16" gap={'s12'}>
                <Row flex={1} justifyContent={'space-between'}>
                    <Column flex={1}>
                        <Text
                            variant="titleMedium"
                            numberOfLines={1}
                            color={titleColor}
                            testID={`betCardTitle-${testID}`}
                        >
                            {title}
                        </Text>
                        <Text variant="bodySmall" numberOfLines={1} color={subTitleColor}>
                            {subTitle}
                        </Text>
                        {progressDots.length > 1 ? (
                            <Box mt="s8">
                                <ProgressDots dots={progressDots} />
                            </Box>
                        ) : null}
                    </Column>
                    {status ? (
                        <Column pl={'s16'} alignItems="flex-end">
                            <StatusBadge status={status} />
                        </Column>
                    ) : null}
                </Row>
                {shareIconInHeader ? (
                    <Row>
                        <Box alignSelf={'center'} backgroundColor={'gray5'} padding={'s12'} borderRadius={'r22'}>
                            <TouchableOpacity onPress={onSharePress}>
                                <ShareIcon width={18} height={18} />
                            </TouchableOpacity>
                        </Box>
                    </Row>
                ) : null}
            </Row>
            <Row
                paddingVertical="s12"
                borderTopWidth={SEPARATOR_HEIGHT}
                borderBottomWidth={SEPARATOR_HEIGHT}
                borderColor={summary.betSummarySeparatorColour}
            >
                <BetSummarySection {...summary} testID={testID} />
            </Row>
            {footer ? (
                <Row paddingVertical="s16" justifyContent={'space-between'}>
                    <Column flex={1} pl="s16">
                        <Text variant="bodySmall" color="gray3" fontSize={12}>
                            {footer}
                        </Text>
                    </Column>
                    {!shareIconInHeader ? (
                        <Column alignItems="flex-end" pr="s16">
                            <TouchableOpacity onPress={onSharePress}>
                                <ShareIcon color={designSystem.colors.gray3} />
                            </TouchableOpacity>
                        </Column>
                    ) : null}
                </Row>
            ) : null}
        </Box>
    );
};
