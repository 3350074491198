import React, { useEffect, useRef, useState } from 'react';
import { Animated, TextInput as RNTextInput, StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import MaskInput, { MaskInputProps } from 'react-native-mask-input';

import { Text } from '@/components/TextComponent';
import { Column, Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';

export type TextInputProps = {
    label: string;
    hasError?: boolean;
    errorText?: string;
    rightIcon?: React.ReactElement;
    icon?: React.ReactElement;
    textInputStyle?: StyleProp<TextStyle>;
    autoFocus?: boolean;
    testID?: string;
} & Exclude<MaskInputProps, 'placeholder'>;

const styles = StyleSheet.create({
    root: {
        height: 60,
    },
    input: {
        paddingLeft: 16,
        fontSize: isWeb ? 16 : 15,
        color: designSystem.colors.white,
        paddingTop: 18,
        height: 60,
    },
    outline: {
        borderWidth: 1,
        borderColor: designSystem.colors.gray5,
        borderRadius: 10,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    outlineFocused: {
        borderWidth: 2,
        borderColor: designSystem.colors.white,
    },
    outlinedError: {
        borderColor: designSystem.colors.red,
    },
    label: {
        position: 'absolute',
        left: 16,
        lineHeight: 24,
    },
    errorTextContainer: {
        marginTop: 4,
        paddingLeft: 16,
        color: designSystem.colors.red,
    },
    hasIcon: {
        paddingLeft: 0,
        left: 0,
    },
});

type AnimatedLabelProps = {
    isFocused: boolean;
    label: string;
    hasValue: boolean;
    hasIcon: boolean;
};

const FONT_SIZE_LABEL = 15;
const FONT_SIZE_LABEL_FOCUSED = 13;
const COLOR_LABEL = '#6D707C';
const COLOR_LABEL_FOCUSED = '#959AA6';
const TOP_LABEL = 18;
const TOP_LABEL_FOCUSED = 6;

const AnimatedLabel: React.FC<AnimatedLabelProps> = ({ isFocused, label, hasValue, hasIcon }) => {
    const animated = useRef(new Animated.Value(hasValue ? 1 : 0)).current;

    const animatedLabelStyle = {
        top: animated.interpolate({
            inputRange: [0, 1],
            outputRange: [TOP_LABEL, TOP_LABEL_FOCUSED],
        }),
        fontSize: animated.interpolate({
            inputRange: [0, 1],
            outputRange: [FONT_SIZE_LABEL, FONT_SIZE_LABEL_FOCUSED],
        }),
        color: animated.interpolate({
            inputRange: [0, 1],
            outputRange: [COLOR_LABEL, COLOR_LABEL_FOCUSED],
        }),
    };

    useEffect(() => {
        Animated.timing(animated, {
            toValue: isFocused || hasValue ? 1 : 0,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }, [hasValue, animated, isFocused]);

    return (
        <Animated.Text allowFontScaling={false} style={[styles.label, animatedLabelStyle, hasIcon && styles.hasIcon]}>
            {label}
        </Animated.Text>
    );
};

export const TextInput = React.forwardRef<RNTextInput, TextInputProps>(
    (
        {
            label,
            value,
            rightIcon,
            hasError = false,
            errorText,
            icon,
            textInputStyle,
            autoFocus = false,
            testID,
            ...textProps
        },
        ref
    ) => {
        const [isFocused, setIsFocused] = useState(false);

        useEffect(() => {
            setIsFocused(autoFocus);
        }, [autoFocus]);

        return (
            <View>
                <View style={styles.root}>
                    <View
                        style={[styles.outline, isFocused && styles.outlineFocused, hasError && styles.outlinedError]}
                    />
                    <Row>
                        {icon ? (
                            <Column alignItems="center" px="s20" justifyContent="center">
                                {icon}
                            </Column>
                        ) : null}
                        <Column flex={1}>
                            <AnimatedLabel isFocused={isFocused} label={label} hasValue={!!value} hasIcon={!!icon} />
                            <MaskInput
                                value={value}
                                ref={ref}
                                style={[styles.input, icon && styles.hasIcon, textInputStyle]}
                                selectionColor={designSystem.colors.white}
                                testID={testID}
                                {...textProps}
                                onFocus={e => {
                                    setIsFocused(true);
                                    textProps?.onFocus?.(e);
                                }}
                                onBlur={e => {
                                    setIsFocused(false);
                                    textProps?.onBlur?.(e);
                                }}
                                placeholder=""
                            />
                        </Column>
                    </Row>
                    {rightIcon}
                </View>

                {hasError && errorText ? (
                    <View style={styles.errorTextContainer}>
                        <Text variant="bodySmall" color={'red'} testID="errorMessage">
                            {errorText}
                        </Text>
                    </View>
                ) : null}
            </View>
        );
    }
);
