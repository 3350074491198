/**
 * This function is used to get the expiration time of the voided warning banner
 * We will keep the banner only for 2 days after the entry has settled or
 * for a week if the entry is still in progress
 * @param {string | undefined} settlementDate - the settlement date of an entry
 * @returns {number}
 */
export const getWarningBannerExpireTime = (settlementDate?: string) => {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; //hours * minutes * seconds * milliseconds
    // the entry is still in progress so we are going to add a week to our current time
    // to make sure the entry settles until we clear it from the storage
    if (!settlementDate) {
        return new Date().getTime() + 7 * oneDayInMilliseconds;
    }

    return Date.parse(settlementDate) + 2 * oneDayInMilliseconds;
};
