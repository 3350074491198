import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Box, Image } from '@/components/lib/components';
import { PlayerImagePlaceholderContainer } from '@/components/player-profile/PlayerProfileImage';
import { designSystem } from '@/styles/styles.js';
import { addResizeParamToURL } from '@/utils/add-resize-param-url';
import { isPlayerImgPlaceholder } from '@/utils/player';

type Props = {
    playerImageUrl?: string;
    onLoadImage: () => void;
    playerNumber?: number | null;
    isLoading?: boolean;
    playerId: string;
};

export const ShareEntryPlayerProfileImage = ({
    playerImageUrl,
    onLoadImage,
    playerNumber,
    isLoading,
    playerId,
}: Props) => {
    const [source, setSource] = useState<{ uri: string }>();
    const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(false);
    const isPlaceholderImage = isPlayerImgPlaceholder(playerImageUrl) || shouldShowPlaceholder;
    const hasPlayerNumber = typeof playerNumber === 'number';
    useEffect(() => {
        playerImageUrl && setSource({ uri: addResizeParamToURL(playerImageUrl) });
        hasPlayerNumber && onLoadImage();
    }, [playerImageUrl, hasPlayerNumber, onLoadImage]);

    const onLoadPlaceholderImage = useCallback(() => {
        !isLoading && onLoadImage();
    }, [isLoading, onLoadImage]);

    return (
        <Box
            style={styles.container}
            overflow={'hidden'}
            borderRadius={'r8'}
            justifyContent={hasPlayerNumber ? 'center' : 'flex-end'}
        >
            {!isPlaceholderImage && playerImageUrl ? (
                <Image
                    source={source}
                    style={styles.imageStyle}
                    onError={() => {
                        // the first time we load the resized image,
                        // so if the source is different from the playerImageUrl,
                        // it means we had an error when loading the resized image
                        if (source?.uri !== playerImageUrl) {
                            // so we need to try the original image
                            setSource({ uri: playerImageUrl });
                        } else {
                            // if the source and playerImageUrl are the same
                            // it means we had an error loading the original image
                            // so we show the placeholder
                            setShouldShowPlaceholder(true);
                        }
                    }}
                    onLoad={onLoadImage}
                />
            ) : hasPlayerNumber ? (
                <Text variant={'headlineLarge'} style={styles.playerNumber}>
                    {playerNumber}
                </Text>
            ) : (
                <PlayerImagePlaceholderContainer
                    id={playerId}
                    placeholderSize={{ width: 54, height: 48 }}
                    onLoad={onLoadPlaceholderImage}
                />
            )}
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: designSystem.colors.gray7,
        width: 40,
        height: 52,
        borderCurve: 'continuous',
        alignItems: 'center',
    },
    imageStyle: {
        width: 52,
        height: 52,
        alignSelf: 'center',
    },
    playerNumber: {
        fontFamily: 'Oswald-SemiBold',
    },
});
