import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { BetPlacedSheet } from '@/feature/betslip-sbk/components/bet-placed-sheet/BetPlacedSheet';
import { NotAddedSelectionsTable } from '@/feature/betslip-sbk/components/bet-placed-sheet/NotAddedSelectionsTable';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { getSubmittedNotAddedSelections } from '@/feature/betslip-sbk/utils/betslip-utils';
import { usePushPrimerEligibility } from '@/feature/push-primer/hooks/use-push-primer-eligibility';
import { SBKBetsStackParamList } from '@/navigation/sbk/types';
import { RootStackParamList } from '@/navigation/types';
import { runStoreUpdate } from '@/utils/zustand';

export const useBetSuccessSheet = () => {
    const { t } = useTranslation(['common', 'bets', 'betslip_sbk']);
    const { showInfoSheet, dismissInfoSheet } = useAlerts();
    const clearBetSlipKeepSelections = useSbkBetSlipStore(state => state.actions.clearBetSlipKeepSelections);
    const pushPrimerEligibility = usePushPrimerEligibility();

    const navigation = useNavigation<StackNavigationProp<SBKBetsStackParamList & RootStackParamList>>();

    const showBetSuccessSheet = useCallback(() => {
        const handleDismiss = () => {
            const { selections, submittedState } = useSbkBetSlipStore.getState();

            const { keepSelectionsInBetSlip } = useSbkBetSlipStore.getState();
            const notAddedSelections = getSubmittedNotAddedSelections(selections, submittedState);

            if (keepSelectionsInBetSlip && notAddedSelections.length > 0) {
                showInfoSheet({
                    title: t('betslip_sbk:selections_not_added'),
                    description: t('betslip_sbk:selections_not_added_description'),
                    footerContent: <NotAddedSelectionsTable notAddedSelections={notAddedSelections} />,
                    buttonLabel: t('bets:got_it'),
                });
            }

            // We want to batch update the store to prevent crash here
            runStoreUpdate(clearBetSlipKeepSelections);
        };

        const handleDismissAndDismissInfoSheet = () => {
            handleDismiss();
            dismissInfoSheet();
        };

        // close betslip before showing success sheet
        setTimeout(() => {
            const currentState = navigation.getState();
            navigation.reset({ ...currentState, index: 0, routes: [currentState.routes[0]] });
        }, 300);
        showInfoSheet({
            description: <BetPlacedSheet handleDismiss={handleDismissAndDismissInfoSheet} />,
            buttonLabel: t('done'),
            onDismiss: handleDismiss,
            handlePress: () => {
                if (pushPrimerEligibility) {
                    navigation.navigate('PushPrimer');
                }
            },
        });
    }, [showInfoSheet, t, clearBetSlipKeepSelections, dismissInfoSheet, navigation, pushPrimerEligibility]);

    return { showBetSuccessSheet };
};
