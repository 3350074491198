import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Clipboard from '@react-native-clipboard/clipboard';

import { EntryDetails } from '@/api/entries/types/types';
import { EventInfo } from '@/api/events/types/types';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { mapPicksToBetSlipPick } from '@/feature/betslip-pickem/utils/betslip-utils';
import { common, designSystem } from '@/styles/styles';
import { Currency, GameType } from '@/types/api.generated';
import { format } from 'date-fns';

import { BetrBucksPromoTag } from './EntryItem';

type EntryFooterProps = {
    entry?: EntryDetails;
    hasFullSeasonPicks?: boolean;
    events: EventInfo[];
    isLeaderboardButtonDisplayed: boolean;
};

export const EntryFooter = ({ entry, hasFullSeasonPicks, events, isLeaderboardButtonDisplayed }: EntryFooterProps) => {
    const { t } = useTranslation(['betslip_pickem', 'common', 'bets', 'lineup_update_modal']);
    const replacePicks = useBetslipStore(state => state.actions.replacePicks);
    const { navigateToPickslip } = useBetslipNavigation();
    const betslip = useBetslipStore(store => store.betslip);
    const { showInfoSheet } = useAlerts();

    const picks = mapPicksToBetSlipPick(entry?.picks ?? [], events);

    const insets = useSafeAreaInsets();

    const addLineupToBetslip = useCallback(() => {
        if (betslip.length > 0) {
            showInfoSheet({
                title: t('lineup_update_modal:replace_lineup_title'),
                description: t('lineup_update_modal:replace_lineup_info'),
                buttonLabel: t('lineup_update_modal:replace_lineup'),
                handlePress: () => {
                    BetrAnalytics.trackEvent(AnalyticsEvent.REUSE_LINEUP_ENTRY_SCREEN);
                    replacePicks(picks, { entryId: entry?.id });
                    navigateToPickslip();
                },
                secondaryLabel: t('lineup_update_modal:keep_lineup'),
            });
        } else {
            BetrAnalytics.trackEvent(AnalyticsEvent.REUSE_LINEUP_ENTRY_SCREEN);
            replacePicks(picks, { entryId: entry?.id });
            navigateToPickslip();
        }
    }, [betslip.length, entry?.id, navigateToPickslip, picks, replacePicks, showInfoSheet, t]);

    const showPromos = entry?.currency === Currency.Fre;

    return (
        <>
            {!entry?.result ? (
                <>
                    <SizedBox value={16} />
                    <Button label={t('betslip_pickem:reuse_lineup')} onPress={addLineupToBetslip} />
                    <SizedBox value={16} />
                </>
            ) : null}
            <SizedBox value={16} />

            <Text variant={'headlineSmall'}>{t('entry_details')}</Text>
            <SizedBox value={16} />
            {showPromos && (
                <>
                    <Promos entry={entry} />
                    <SizedBox value={16} />
                </>
            )}
            <SubmissionLog entry={entry} />
            <SizedBox value={20} />
            {entry?.status === 'PENDING' ? (
                <View>
                    <LineSeparator style={styles.lineSeparator} />
                    <SizedBox value={15} />
                    <Text variant="bodySmall" color={'gray3'}>
                        {t('entry_delay_info')}
                    </Text>
                    {entry?.gameType === GameType.P2P ? (
                        <Text variant="bodySmall" color={'gray3'}>
                            {t('p2p_entry_delay_info')}
                        </Text>
                    ) : null}
                    <SizedBox value={15} />
                </View>
            ) : null}
            {entry?.status === 'PENDING' && hasFullSeasonPicks ? (
                <View>
                    <Text variant="bodySmall" color={'gray3'}>
                        {t('full_season_entry_delay')}
                    </Text>

                    <SizedBox value={15} />
                </View>
            ) : null}
            <LineSeparator style={styles.lineSeparator} />
            <SizedBox value={24} />
            <ContactUs />
            <SizedBox value={isLeaderboardButtonDisplayed ? insets.bottom + 80 : insets.bottom + 14} />
        </>
    );
};

const ContactUs = () => {
    const { t } = useTranslation(['betslip_pickem', 'common']);
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();

    return (
        <Box
            backgroundColor={'gray7'}
            paddingHorizontal={'s16'}
            paddingVertical={'s20'}
            borderRadius={'r20'}
            alignItems={'center'}
            gap={'s16'}
        >
            <Box gap={'s4'} alignItems={'center'}>
                <Text variant="headlineSmall">{t('get_support_any_time')}</Text>
                <Text variant="bodySmall" textAlign={'center'} color={'gray2'}>
                    {t('support_24_7')}
                </Text>
            </Box>
            <Button
                shape={'pill'}
                hierarchy="secondary"
                variant="light"
                label={t('common:contact_us')}
                onPress={launchIntercomMessenger}
            />
        </Box>
    );
};

const Promos = ({}: { entry: EntryDetails }) => {
    const { t } = useTranslation(['betslip_pickem', 'common', 'bets']);

    return (
        <>
            <Text variant={'titleSmall'}>{t('promos')}</Text>
            <SizedBox value={8} />
            <BetrBucksPromoTag />
            <SizedBox value={8} />
        </>
    );
};

const SubmissionLog = ({ entry }: { entry: EntryDetails }) => {
    const { t } = useTranslation(['betslip_pickem', 'common', 'bets']);

    return (
        <>
            <Text variant={'titleSmall'}>{t('submission_log')}</Text>
            <SizedBox value={8} />
            <Box flex={1} gap="s8">
                <TouchableOpacity
                    activeOpacity={0.9}
                    style={[common.row, common.justifySpaceBetween]}
                    onLongPress={() => {
                        Clipboard.setString(entry?.id ?? '');
                        Alert.alert('Entry ID copied to clipboard', entry?.id);
                    }}
                >
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('entry_id')}
                    </Text>
                    <Text variant="bodySmall" color={'gray2'}>
                        {entry?.id}
                    </Text>
                </TouchableOpacity>
                <Row justifyContent={'space-between'}>
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('common:submitted')}:
                    </Text>
                    <Text variant="bodySmall" color={'gray2'}>
                        {entry?.createdDate && Date.parse(entry?.createdDate)
                            ? format(Date.parse(entry?.createdDate), 'MM/dd/yyyy hh:mm aa')
                            : entry?.createdDate}
                    </Text>
                </Row>
                <Row justifyContent={'space-between'}>
                    <Text variant="bodySmall" color={'gray2'}>
                        {t('common:closed')}:
                    </Text>
                    <Text variant="bodySmall" color={'gray2'}>
                        {entry?.settlementDate
                            ? format(Date.parse(entry?.settlementDate), 'MM/dd/yyyy hh:mm aa')
                            : '--'}
                    </Text>
                </Row>
                <Row justifyContent={'space-between'}>
                    <Text variant="bodySmall">{t('bets:result')}:</Text>
                    <Text variant="bodySmall">
                        {entry?.status === undefined
                            ? ''
                            : entry?.status === 'PENDING'
                            ? t('common:pending')
                            : entry?.result === 'WIN'
                            ? t('common:won')
                            : entry?.result === 'LOSS'
                            ? t('common:lost')
                            : t('common:voided')}
                    </Text>
                </Row>
            </Box>
        </>
    );
};

const styles = StyleSheet.create({
    lineSeparator: {
        backgroundColor: designSystem.colors.gray6,
    },
});
