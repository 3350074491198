import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = ({ height = 24, width = 24, color = designSystem.colors.gray1 }) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
        <Path fill={color} d="m16.243 9.071 1.414-1.414L12 2 6.343 7.657 7.757 9.07 11 5.828V16h2V5.828l3.243 3.243Z" />
        <Path fill={color} d="M4.5 14h-2v8h19v-8h-2v6h-15v-6Z" />
    </Svg>
);

export default SvgComponent;
