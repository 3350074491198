import { useCallback, useMemo } from 'react';

import { useFocusEffect } from '@react-navigation/native';

import { DEV, ENV, GLI, Jurisdiction, URLS } from '@/data';
import { productNames } from '@/hooks/use-active-product-name';
import { Product, getProductType, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useResumeEffect } from '@/hooks/use-resume';
import { user } from '@/hooks/use-user';
import { GlobalConfigurationsDocumentDataBodyKeyValuePairsSlice } from '@/types/prismic.generated';
import { GENERIC_SBK_JURISDICTION } from '@/utils/constants';
import { Wallet, getWalletBalances } from '@/utils/fetch-wallet-balance';
import { logger } from '@/utils/logging';
import { toFraction } from '@/utils/numeric/fraction';
import { useQuery } from '@tanstack/react-query';
import { useSnapshot } from 'valtio';
import { create } from 'zustand';

const defaultBalances = {
    total: '0',
    real_amount: '0',
    bonus_amount: '0',
    free_amount: '0',
    real_currency: '0',
};

type ActiveWalletStore = {
    wallets: {
        [key in Product]: Wallet;
    };
    activeWalletProduct: Product;
    loadWallets: () => void;
    setActiveWallet: (product: Product, jurisdiction: string) => void;
    jurisdiction: string;
    resetActiveWallet: () => void;
    walletLoading: boolean;
};

export const useActiveWalletStore = create<ActiveWalletStore>((set, get) => ({
    wallets: {
        [Product.Pickem]: defaultBalances,
        [Product.Sportsbook]: defaultBalances,
        [Product.None]: defaultBalances,
    },
    activeWalletProduct: Product.None,
    setActiveWallet: (product: Product, jurisdiction: string) => {
        set({ activeWalletProduct: product, jurisdiction });
    },
    loadWallets: async () => {
        if (get().walletLoading) {
            return;
        }
        if (user.sessionHasExpired() || user.isGuest()) {
            logger.debug('[wallet] No session or guest user, not loading wallet.');
            return;
        }
        set({ walletLoading: true });
        const data = await getWalletBalances();
        set({
            walletLoading: false,
            wallets: {
                [Product.Pickem]: data.fantasy_wallet,
                [Product.Sportsbook]: data.wallet,
                [Product.None]: defaultBalances,
            },
        });
    },
    jurisdiction: '',
    resetActiveWallet: () => {
        set({ activeWalletProduct: Product.None });
    },
    walletLoading: false,
}));

/**
 * Main hook to fetch all the data for the current active wallet in the Account screen
 */
export const useActiveWallet = () => {
    useInitActiveWallet();
    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const products = useJurisdictionStore(state => state.jurisdictionSettings?.products);
    const { loadWallets, wallets, activeWalletProduct, walletLoading } = useActiveWalletStore(state => {
        return {
            loadWallets: state.loadWallets,
            wallets: state.wallets,
            activeWalletProduct: state.activeWalletProduct,
            walletLoading: state.walletLoading,
        };
    });
    useResumeEffect(
        useCallback(() => {
            loadWallets();
        }, [loadWallets])
    );

    const showWalletSwitcher = useMemo(() => {
        if (!settings?.wallet_switcher_enabled) {
            return false;
        }
        const hasFundsInBothWallets = [wallets[Product.Pickem], wallets[Product.Sportsbook]].every(
            wallet => Number(wallet.total) > 0
        );
        const hasSportsbookProduct = products ? !!products.find(p => p.uid === 'betting') : false;
        const hasFantasyProduct = products ? !!products.find(p => p.uid === 'pickem') : false;
        return hasFundsInBothWallets || (hasSportsbookProduct && hasFantasyProduct);
    }, [products, settings?.wallet_switcher_enabled, wallets]);

    return {
        wallet: wallets[activeWalletProduct],
        balance: toFraction(wallets[activeWalletProduct].real_amount),
        total: toFraction(wallets[activeWalletProduct].total),
        bonus: toFraction(wallets[activeWalletProduct].bonus_amount),
        betrBucks: toFraction(wallets[activeWalletProduct].free_amount),
        activeWalletProductName: productNames[activeWalletProduct],
        activeWalletProduct,
        showWalletSwitcher,
        wallets,
        walletLoading,
    };
};

/**
 * Creates the jurisdiction headers for the active wallet
 */
export const createActiveWalletJurisdictionHeaders = () => {
    const accessToken = user.session?.access_token;
    return {
        JURISDICTION: useActiveWalletStore.getState().jurisdiction,
        Authorization: `Bearer ${accessToken}`,
    };
};

/**
 * Hook that creates the jurisdiction headers for the active wallet
 */
export const useActiveWalletJurisdictionHeaders = () => {
    const userData = useSnapshot(user);
    const jurisdiction = useActiveWalletStore(state => state.jurisdiction);
    return useMemo(() => {
        return {
            JURISDICTION: jurisdiction,
            Authorization: `Bearer ${userData.session?.access_token}`,
        };
    }, [jurisdiction, userData.session?.access_token]);
};

/**
 * Sets the active wallet to the incoming product/jurisdiction
 */
const useInitActiveWallet = () => {
    const switchActiveWallet = useSwitchActiveWallet();

    const jurisdiction = useJurisdictionStore(state => state.jurisdiction);
    const product = useJurisdictionStore(state => state.product);
    const { activeWalletProduct } = useActiveWalletStore(state => {
        return {
            setActiveWallet: state.setActiveWallet,
            activeWalletProduct: state.activeWalletProduct,
        };
    });

    useFocusEffect(
        useCallback(() => {
            if (jurisdiction && activeWalletProduct === Product.None) {
                switchActiveWallet(product);
            }
        }, [jurisdiction, activeWalletProduct, switchActiveWallet, product])
    );
};

/**
 * Fetches the jurisdiction settings for the active wallet
 * @param jurisdiction the jurisdiction to fetch settings for
 */
const fetchJurisdictionSettings = async (jurisdiction: string) => {
    const resp = await fetch(`${URLS.ACS_URL}/${jurisdiction.toLowerCase()}`);
    if (!resp.ok) {
        throw new Error('Error fetching jurisdiction settings for active wallet');
    }
    return await resp.json();
};

/**
 * Selector function to fetch API keys from the ACS response
 * @param data response from ACS
 */
const apiKeySelector = (data: any) => {
    return data?.data?.default_product?.data?.body
        ?.find((it: GlobalConfigurationsDocumentDataBodyKeyValuePairsSlice) => it.primary.name === 'API keys')
        ?.items?.reduce((acc: Record<string, string>, currentValue: { key: string; value: string }) => {
            return { ...acc, [currentValue.key]: currentValue.value };
        }, {});
};

/**
 * Hook that fetches the paysafe api keys for the active wallet
 */
export const useActiveWalletPaysafeCredentials = () => {
    const jurisdiction = useActiveWalletStore(state => state.jurisdiction);
    return useQuery(['paysafe-keys', jurisdiction], () => fetchJurisdictionSettings(jurisdiction), {
        select: apiKeySelector,
        staleTime: Infinity,
        enabled: !!jurisdiction,
        retry: 3,
        retryDelay: 1000,
    });
};

/**
 * Hook that switches the active wallet
 */
export const useSwitchActiveWallet = () => {
    const setActiveWallet = useActiveWalletStore(state => state.setActiveWallet);
    const jurisdiction = useJurisdictionStore(state => state.jurisdiction);
    const jurisdictionProducts = useJurisdictionStore(state => state.jurisdictionSettings?.products);

    return useCallback(
        (product: Product) => {
            switch (product) {
                case Product.Pickem:
                    setActiveWallet(Product.Pickem, Jurisdiction.FANTASY);
                    break;
                case Product.Sportsbook:
                    let isSbkJurisdiction: boolean | undefined;
                    if (ENV === DEV || ENV === GLI) {
                        /* In DEV, most of the jurisdictions have both SBK and Fantasy products available. So we must verify if the `jurisdictionProducts` solely include SBK products. */
                        isSbkJurisdiction =
                            jurisdictionProducts?.every(it => getProductType(it.uid) === Product.Sportsbook) &&
                            !!jurisdiction;
                    } else {
                        isSbkJurisdiction =
                            jurisdictionProducts?.some(it => getProductType(it.uid) === Product.Sportsbook) &&
                            !!jurisdiction;
                    }
                    /* There will be future work to get the correct SBK jurisdiction from the API */
                    const sbkJurisdiction = isSbkJurisdiction && jurisdiction ? jurisdiction : GENERIC_SBK_JURISDICTION;

                    setActiveWallet(Product.Sportsbook, sbkJurisdiction);
                    break;
                default:
                    break;
            }
        },
        [jurisdiction, jurisdictionProducts, setActiveWallet]
    );
};
