import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 20}
            height={props.width || 20}
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <Path
                d="M7.5 5h10M7.5 10h10M7.5 15h10"
                stroke={props.color || designSystem.colors.gray8}
                strokeWidth={1.75}
            />
            <Circle cx={4} cy={5} r={1.5} fill={props.color || designSystem.colors.gray8} />
            <Circle cx={4} cy={10} r={1.5} fill={props.color || designSystem.colors.gray8} />
            <Circle cx={4} cy={15} r={1.5} fill={props.color || designSystem.colors.gray8} />
        </Svg>
    );
}

export default SvgComponent;
