import React from 'react';
import { useTranslation } from 'react-i18next';
import Reanimated, { AnimatedStyle } from 'react-native-reanimated';

import { useSelectionCount } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { common } from '@/styles/styles';

import { EmptyBets } from '../EmptyBets';
import { ComboCard } from '../combo-card/ComboCard';
import { betSlipTabStyle } from './SinglesTab';

export const ComboTabScreen = ({ animatedStyle }: { animatedStyle: AnimatedStyle }) => {
    const selectionCount = useSelectionCount();
    const { t } = useTranslation('betslip_sbk');

    if (selectionCount <= 1) {
        return (
            <EmptyBets
                title={t('no_combos_or_scripts_available')}
                textMessage={t('no_combos_or_scripts_available_description')}
            />
        );
    }
    return (
        <Reanimated.ScrollView style={[betSlipTabStyle.root, common.paddingHorizontal, animatedStyle]}>
            <ComboCard />
        </Reanimated.ScrollView>
    );
};
