import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M9.18427 2.81641L6.00229 5.99839M6.00229 5.99839L2.82031 9.18037M6.00229 5.99839L2.82031 2.81641M6.00229 5.99839L9.18427 9.18037"
            stroke={designSystem.colors.gray8}
            strokeWidth={1.5}
        />
    </Svg>
);
export default SVGComponent;
