import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { NumberPad } from '@/components/NumberPad';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useCurrencyNumberPad } from '@/hooks/use-currency-number-pad';
import { common } from '@/styles/styles';

export const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
    },
    currency: {
        position: 'absolute',
        left: -20,
    },
});

type LimitModalProps = {
    title: string;
    buttonLabel: string;
    handleButtonPress: (amount: number) => void;
    isButtonDisabled?: boolean;
    initialValue?: number;
    errorMessageHandler?: (amount: number) => string | undefined;
    handleRemovePress?: (amount: number) => void;
};

export const LimitsModal: React.FC<LimitModalProps> = ({
    title,
    buttonLabel,
    handleButtonPress,
    isButtonDisabled,
    initialValue,
    errorMessageHandler,
    handleRemovePress,
}) => {
    const { displayValue, number, onNumberPress, onDecimalPress, onBackspacePress } =
        useCurrencyNumberPad(initialValue);
    const disabled = number === 0 || !!errorMessageHandler?.(number) || initialValue === number || isButtonDisabled;

    const { t } = useTranslation('rg');
    return (
        <Screen>
            <ScreenNavBar
                title={title}
                closeIconMode="close"
                handlePressTracking={() => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.AMOUNT_ABORT, { screen_title: title });
                }}
            />
            <ScrollView contentContainerStyle={[common.grow, common.justifyCenter]}>
                <MaxWidthWrapper>
                    <View style={common.alignCenter}>
                        <View style={common.row} testID="iosLimitAmount">
                            <Text variant="headlineLarge" style={styles.currency}>
                                $
                            </Text>
                            <Text variant="displayLarge" numberOfLines={1} adjustsFontSizeToFit testID="limitAmount">
                                {displayValue}
                            </Text>
                        </View>
                        {handleRemovePress ? (
                            <TouchableOpacity onPress={() => handleRemovePress(0)}>
                                <Text textDecorationLine={'underline'} variant="titleMedium" color={'red'}>
                                    {t('remove_limit')}
                                </Text>
                            </TouchableOpacity>
                        ) : null}
                    </View>
                </MaxWidthWrapper>
            </ScrollView>
            <MaxWidthWrapper>
                <NumberPad
                    relativePosition
                    toolbar={
                        <Box pb="s16">
                            <Box mb="s12">
                                <Text color={'red'} textAlign={'center'} variant="bodySmall">
                                    {errorMessageHandler?.(number) ?? ' '}
                                </Text>
                            </Box>
                            <Box style={styles.container}>
                                <Button
                                    label={buttonLabel}
                                    hierarchy={'primary'}
                                    disabled={disabled}
                                    onPress={() => handleButtonPress(number)}
                                    testID="updateLimitBtn"
                                />
                            </Box>
                        </Box>
                    }
                    {...{ onNumberPress, onDecimalPress, onBackspacePress }}
                />
            </MaxWidthWrapper>
        </Screen>
    );
};
