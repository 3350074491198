import { BetType } from '@/feature/bets-sbk/hooks/types';
import { AcceptMultiplierChanges, defaultUserSettings } from '@/hooks/use-auth-user-settings';
import { isOptionOpen } from '@/utils/option-status';

import { BetTypes, ComboBetTypes, SBKBetSlip } from '../types';
import {
    BetIds,
    calculatePayout,
    generateSgpOddsId,
    getComboBetType,
    getComboOdds,
    groupSelectionIdsByEvent,
    isBetBetrBucks,
    isBetValid,
    isComboBetSelectionsValid,
} from './betslip-utils';

export const validBetsSelector = (state: SBKBetSlip) => Object.values(state.bets).filter(bet => isBetValid(bet, state));

export const totalStakeSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => acc + (bet.stake ?? 0), 0);

export const totalCashStakeSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => acc + (!bet.isBetrBucks ? bet.stake ?? 0 : 0), 0);

export const totalBetrBucksStakeSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => acc + (bet.isBetrBucks ? bet.stake ?? 0 : 0), 0);

export const totalPayoutSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => {
        const payout = calculatePayout(bet, state);
        return acc + payout;
    }, 0);

export const betPayoutSelector = (betId: string) => (state: SBKBetSlip) => {
    const bet = state.bets[betId];
    if (!bet) {
        return 0;
    }
    return calculatePayout(bet, state);
};

export const activeSingleBetsSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).filter(bet => bet.betType === BetTypes.Single && bet.stake);

// TODO: to remove this selector and consolidate with just combo
export const activeScriptBetCountSelector = (state: SBKBetSlip) => {
    const bet = state.bets[BetIds.COMBO];
    if (!bet) {
        return 0;
    }
    const comboBetType = getComboBetType(state);
    if (comboBetType === ComboBetTypes.SGP && isComboBetSelectionsValid(state) && bet.stake) {
        return 1;
    }
    return 0;
};

export const activeComboBetCountSelector = (state: SBKBetSlip) => {
    const bet = state.bets[BetIds.COMBO];
    if (!bet) {
        return 0;
    }
    const comboBetType = getComboBetType(state);
    if (
        (comboBetType === ComboBetTypes.Parlay || comboBetType === ComboBetTypes.SGP_Plus) &&
        isComboBetSelectionsValid(state) &&
        bet.stake
    ) {
        return 1;
    }
    return 0;
};

export const totalBetCountSelector = (state: SBKBetSlip) => {
    return validBetsSelector(state).filter(bet => bet?.stake && bet?.stake > 0).length;
};

export const selectionCountSelector = (state: SBKBetSlip) => Object.values(state.selections).length;

export const eventSelector = (eventId: string) => (state: SBKBetSlip) => state.events[eventId];

export const selectionSelector = (selectionId: string) => (state: SBKBetSlip) => state.selections[selectionId];

export const betSlipBarSummarySelector = (state: SBKBetSlip): [BetType | undefined, number] => {
    let highestOdds = 1;
    let betType: BetType | undefined;
    // check singles bets for max odds
    Object.values(state.options).forEach(option => {
        if (
            isOptionOpen(option.status, state.producerStatus, state.markets[option.marketId].published) &&
            option.odds > highestOdds
        ) {
            highestOdds = option.odds;
            betType = 'SINGLE';
        }
    });

    // check combo bet for max odds
    const comboOdds = getComboOdds(state);

    if (comboOdds > highestOdds) {
        highestOdds = comboOdds;
        betType = 'COMBO';
    }

    return [betType, highestOdds];
};

export const isBetSlipValidSelector = (state: SBKBetSlip) => {
    const hasStake = validBetsSelector(state).some(bet => bet?.stake && bet?.stake > 0);
    if (!hasStake) {
        return false;
    }
    const hasTotalStakeError = state.totalStakeErrors.length > 0;
    const hasStakeInputError = validBetsSelector(state).some(bet => !!bet.stakeInputError && !!bet.stake);
    return !hasTotalStakeError && !hasStakeInputError && !state.isSgpFetching;
};

export const orderedSelectionIdsSelector = (eventId: string) => (state: SBKBetSlip) => {
    return Object.keys(state.selections)
        .filter(selectionId => state.selections[selectionId].eventId === eventId)
        .sort((a: string, b: string) => state.selectionOrder.indexOf(a) - state.selectionOrder.indexOf(b));
};

export const isOddsBetrBucksSelector = (betId: string) => (state: SBKBetSlip) => isBetBetrBucks(betId, state);

export const showOddsChangeButtonSelector = (state: SBKBetSlip) => {
    const { userSettings = defaultUserSettings } = state;
    if (userSettings?.accept_multiplier_changes === AcceptMultiplierChanges.ALL) {
        return false;
    }
    if (userSettings?.accept_multiplier_changes === AcceptMultiplierChanges.HIGHER) {
        // Show when the odds decreased
        const isSingleBetOddsDecreased = Object.keys(state.oddsChanges).some(id => {
            const prevOdds = state.oddsChanges[id];
            const currentOdds = state.options[id]?.odds;
            return prevOdds > currentOdds;
        });

        const selectionIdsByEvent = groupSelectionIdsByEvent(state);
        const isSgpBetOddsDecreased = Object.entries(selectionIdsByEvent).some(([eventId, ids]) => {
            const spgId = generateSgpOddsId(ids, eventId);
            const currentOdds = state.sgpOdds[spgId];
            const previousOdds = state.sgpOddsChanges[spgId];
            if (currentOdds && previousOdds && currentOdds < previousOdds) {
                return true;
            }
        });
        return isSingleBetOddsDecreased || isSgpBetOddsDecreased;
    }
    return (
        (Object.keys(state.sgpOddsChanges).length > 0 || Object.keys(state.oddsChanges).length > 0) &&
        !state.totalStakeErrors.length
    );
};

export const comboOddsSelector = (state: SBKBetSlip) => getComboOdds(state);

export const previousComboOddsSelector = (state: SBKBetSlip) => getComboOdds(state, true);

export const hasComboOddsChangedSelector = (state: SBKBetSlip) => {
    const comboBetType = getComboBetType(state);
    if (comboBetType === ComboBetTypes.SGP || comboBetType === ComboBetTypes.SGP_Plus) {
        const selectionIdsByEvent = groupSelectionIdsByEvent(state);
        return Object.entries(selectionIdsByEvent).some(([eventId, selectionIds]) => {
            const spgId = generateSgpOddsId(selectionIds, eventId);
            return !!state.sgpOddsChanges[spgId];
        });
    }
    return Object.values(state.selections)
        .filter(s => s.isComboEnabled)
        .some(s => !!state.oddsChanges[s.id]);
};
