import { BetSlipEvent, BetSlipMarket, BetSlipOption, MAX_SELECTIONS, SBKBetSlip } from '@/feature/betslip-sbk/types';
import { addEventOrder } from '@/feature/betslip-sbk/utils/betslip-utils';

/**
 * Add selections for betslip
 * @param state - current betslip state
 * @param option - option to add
 * @param market - market to add
 * @param event - event to add
 */
export const addSelection = (
    state: Pick<
        SBKBetSlip,
        'sgpEventDisabled' | 'selectionOrder' | 'eventOrder' | 'options' | 'markets' | 'events' | 'selections'
    >,
    option: BetSlipOption,
    market: BetSlipMarket,
    event: BetSlipEvent
) => {
    if (state.selectionOrder.length === MAX_SELECTIONS) {
        return {};
    }
    const selection = {
        id: option.id,
        optionId: option.id,
        marketId: market.id,
        eventId: event.id,
        isComboEnabled: !state.sgpEventDisabled[event.id],
    };
    const eventOrder = addEventOrder(event.id, state.eventOrder);
    return {
        eventOrder,
        selectionOrder: [...state.selectionOrder, selection.id],
        options: { ...state.options, [option.id]: option },
        markets: { ...state.markets, [market.id]: market },
        events: { ...state.events, [event.id]: event },
        selections: { ...state.selections, [selection.id]: selection },
        sgpEventDisabled: { ...state.sgpEventDisabled, [event.id]: !event.is_sgp_enabled },
    };
};
