import { useEffect } from 'react';

import { PROD, getEnv, getFirebaseWebConfig } from '@/data';
import { initializeApp } from 'firebase/app';
import { activate, fetchAndActivate, fetchConfig, getAll, getRemoteConfig, getValue } from 'firebase/remote-config';

const firebaseConfig = getFirebaseWebConfig();
export const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);

export const useFetchAndActivateFirebaseRemoteConfigs = () => {
    useEffect(() => {
        fetchAndActivate(remoteConfig);
    }, []);
};

export const getAllRemoteConfigs = () => getAll(remoteConfig);

export const getRemoteConfigByKey = (key: string) => getValue(remoteConfig, key);

export const activateRemoteConfig = () => activate(remoteConfig);

export const refetchRemoteConfigs = () => {
    if (getEnv() !== PROD) {
        remoteConfig.settings.minimumFetchIntervalMillis = 3000;
    }
    return fetchConfig(remoteConfig);
};
