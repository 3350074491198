export const betSelectionItemStyle = {
    s: {
        firstVerticalBarStyles: {
            height: 6,
        },
        thirdVerticalBarContainerStyles: {
            paddingLeft: 11,
            height: 16,
        },
        betInfoColumnStyles: {
            firstLeg: {
                paddingTop: 's12' as const,
            },
            notFirstLeg: {
                paddingTop: 's8' as const,
            },
        },
        outerColumnStyles: {
            paddingLeft: 's4' as const,
        },
        iconStyles: {
            width: 16,
            height: 16,
        },
        strikedDotIconStyles: {
            width: 10.5,
            height: 10.5,
        },
    },
    l: {
        firstVerticalBarStyles: {
            height: 14,
        },
        thirdVerticalBarContainerStyles: {
            height: 32,
            paddingLeft: 17,
        },
        betInfoColumnStyles: {
            firstLeg: {
                paddingTop: 's20' as const,
            },
            notFirstLeg: {
                paddingTop: 's16' as const,
            },
        },
        outerColumnStyles: {
            paddingLeft: 's8' as const,
        },
        iconStyles: {
            width: 20,
            height: 20,
        },
        strikedDotIconStyles: {
            width: 14.5,
            height: 14.5,
        },
    },
};
