import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { Box, Row } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import { SingleProjectionIcon } from '@/feature/lobby/components/PlayerDetails';
import { designSystem } from '@/styles/styles';
import { ProjectionType } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import '@/utils/fantasy-attribute-utils';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

import { leagueConfigSelector, useLeagueConfigsStore } from '../hooks/use-league-configs-store';
import { PlayerWithTeam } from '../types';

type CustomSize = {
    width: number;
    height: number;
};

type PlayerIconsDetails = {
    player: PlayerWithTeam;
    customSize?: CustomSize;
    style?: ViewStyle;
};

type Props = {
    playerIconsDetails: PlayerIconsDetails[];
    hasBoostedProjections: boolean;
    scaleStyle?: ViewStyle;
    containerDimensions?: ViewStyle;
};

export const PlayersIconsGroup = ({
    playerIconsDetails,
    hasBoostedProjections,
    scaleStyle,
    containerDimensions,
}: Props) => {
    const icons = playerIconsDetails.slice(0, 3);

    return (
        <Box style={containerDimensions}>
            <Row style={[styles.root, scaleStyle]} position={'relative'}>
                {icons.map((playerData, index) => (
                    <PlayerIcon
                        playerData={playerData}
                        index={index}
                        iconsCount={icons.length}
                        key={`${playerData.player.icon}-${index}`}
                    />
                ))}
                {hasBoostedProjections ? (
                    <Box style={[styles.boostedIcon, BOOSTED_ICON_STYLE[icons.length]]}>
                        <SingleProjectionIcon selectedProjection={{ type: ProjectionType.Boosted }} />
                    </Box>
                ) : null}
            </Row>
        </Box>
    );
};

const PlayerIcon = ({
    playerData,
    index,
    iconsCount,
}: {
    playerData: PlayerIconsDetails;
    index: number;
    iconsCount: number;
}) => {
    const { arcText, teamLogo } = getPlayerArcDetails(
        playerData.player,
        playerData.player.league,
        playerData.player.team
    );
    const { leagueIcon, leagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(playerData.player.league),
        defaultZustandCompareFunction
    );
    const playerNumber = getPlayerJerseyNumber(playerData.player.league, playerData.player?.jerseyNumber);

    return (
        <View style={[styles.commonIconStyle, IMAGE_WRAPPER_STYLE[iconsCount][index]]}>
            <PlayerProfileImage
                imageVariant="small"
                playerImageUrl={playerData.player.icon}
                teamImageUrl={teamLogo ?? leagueIcon}
                teamColor={playerData.player.team?.color ?? leagueColor}
                teamSecondaryColor={playerData.player.team?.secondaryColor}
                arcText={arcText}
                playerNumber={playerNumber}
                playerId={playerData.player.id}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    root: {
        width: 64,
        height: 56,
        position: 'relative',
    },
    twoIconsLeft: {
        zIndex: designSystem.zIndex.zIndex1,
        elevation: 11,
        left: 4,
        top: 3,
        transform: [
            {
                translateX: -10.2,
            },
            {
                translateY: -9.9,
            },
            {
                rotate: '-10deg',
            },
            {
                scale: 44 / 68,
            },
        ],
        shadowOffset: {
            height: 1.777,
            width: 0,
        },
        shadowRadius: 7.109,
        shadowOpacity: 0.8,
        shadowColor: 'rgba(0,0,0)',
    },
    commonIconStyle: {
        backgroundColor: designSystem.colors.gray6,
        borderRadius: 10,
    },
    twoIconsRight: {
        position: 'absolute',
        zIndex: designSystem.zIndex.zIndexNegative1,
        top: 7.5,
        left: 28.25,
        shadowOpacity: 0.4,
        transform: [
            {
                translateX: -8.4,
            },
            {
                translateY: -12.1,
            },
            {
                rotate: '15deg',
            },
            {
                scale: 44 / 68,
            },
        ],
    },
    threeIconsFirst: {
        shadowOffset: {
            height: 2,
            width: 0,
        },
        shadowRadius: 8,
        shadowOpacity: 0.8,
        shadowColor: 'rgba(0,0,0)',
        elevation: 11,
        position: 'absolute',
        top: 3,
        zIndex: designSystem.zIndex.zIndex3,
        left: 7,
        transform: [
            {
                translateX: -6.4,
            },
            {
                translateY: -9.1,
            },
            {
                rotate: '-20deg',
            },
            {
                scale: 50 / 68,
            },
        ],
    },
    threeIconsSecond: {
        shadowOffset: {
            height: 2,
            width: 0,
        },
        shadowRadius: 8,
        shadowOpacity: 0.4,
        shadowColor: 'rgba(0,0,0)',
        position: 'absolute',
        left: 18,
        top: 3,
        zIndex: designSystem.zIndex.zIndex2,
        transform: [
            {
                translateX: -8.5,
            },
            {
                translateY: -12,
            },
            {
                rotate: '5deg',
            },
            {
                scale: 44 / 68,
            },
        ],
    },
    threeIconsThird: {
        shadowOffset: {
            height: 2,
            width: 0,
        },
        position: 'absolute',
        top: 7,
        zIndex: designSystem.zIndex.zIndex1,
        left: 29,
        shadowRadius: 8,
        shadowOpacity: 0.2,
        shadowColor: 'rgba(0,0,0)',
        transform: [
            {
                translateX: -10.6,
            },
            {
                translateY: -15,
            },
            {
                rotate: '30deg',
            },
            {
                scale: 38 / 68,
            },
        ],
    },
    boostedIcon: {
        position: 'absolute',
        zIndex: designSystem.zIndex.zIndex4,
        shadowOpacity: 0.8,
    },
    boostedIconTwo: {
        top: 30,
        left: 23,
        shadowOpacity: 0.8,
    },
    boostedIconThree: {
        top: 30,
        left: 40,
        shadowOpacity: 0.8,
    },
});

// ! objects created to easy access necessary properties by access operator: IMAGE_SIZE[icons_length][index_icon]
const IMAGE_WRAPPER_STYLE: { [key: number]: ViewStyle[] } = {
    2: [styles.twoIconsLeft, styles.twoIconsRight],
    3: [styles.threeIconsFirst, styles.threeIconsSecond, styles.threeIconsThird],
};

const BOOSTED_ICON_STYLE: { [key: number]: ViewStyle } = {
    2: styles.boostedIconTwo,
    3: styles.boostedIconThree,
};
