import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M9.5 4.25L6.35355 7.39645C6.15829 7.59171 5.84171 7.59171 5.64645 7.39645L2.5 4.25"
            stroke={designSystem.colors.gray8}
            strokeWidth={1.5}
        />
    </Svg>
);
export default SVGComponent;
