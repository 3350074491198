import React, { useCallback } from 'react';
import { FlatList } from 'react-native';

import { common } from '@/styles/styles';

import { BetEvent, BetSelection } from '../../hooks/types';
import { BetSelectionItem } from './BetSelectionItem';

type BetSelectionListProps = {
    event: BetEvent;
    isLastEvent: boolean;
    isCashedOut: boolean;
    variant: 's' | 'l';
};

export const BetSelectionList = ({ event, isLastEvent, isCashedOut, variant }: BetSelectionListProps) => {
    const renderItem = useCallback(
        ({ item: selection, index }: { item: BetSelection; index: number }) => {
            const isLastSelection = selection.id === event.selections[event.selections.length - 1].id;
            const isFirstLeg = index === 0;

            return (
                <BetSelectionItem
                    key={selection.id}
                    selection={selection}
                    event={event}
                    isCashedOut={isCashedOut}
                    isLastEvent={isLastEvent}
                    isLastLeg={isLastSelection}
                    isFirstLeg={isFirstLeg}
                    variant={variant}
                />
            );
        },
        [event, isCashedOut, isLastEvent, variant]
    );

    return (
        <FlatList
            scrollEnabled={false}
            keyExtractor={item => item.id}
            data={event.selections}
            renderItem={renderItem}
            style={[common.full, common.zIndexNegative1]}
        />
    );
};
