import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { Share } from '@/feature/entry-share/utils/share';
import { captureViewShot } from '@/feature/entry-share/utils/view-shot';
import { logger } from '@/utils/logging';

export const useBetShare = (viewRef: React.MutableRefObject<View | null>) => {
    const { t } = useTranslation('pickem_share_entry_modal');
    const navigation = useNavigation();
    const { showToast } = useAlerts();
    const imageCaptured = useRef(false);

    const capture = useCallback(async () => {
        try {
            const screenshot = await captureViewShot(viewRef, {
                snapshotContentContainer: false,
                format: 'png',
            });

            Share.open({
                url: screenshot,
            }).finally(() => {
                navigation.goBack();
            });
        } catch (e) {
            logger.debug('SBK EntryShare', e);
            showToast({ message: t('sharing_failed_msg'), toastType: 'warning' });
            navigation.goBack();
        }
    }, [navigation, showToast, t, viewRef]);

    useEffect(() => {
        if (!imageCaptured.current) {
            imageCaptured.current = true;
            setTimeout(() => {
                capture();
                // 0.5 seconds delay to allow the view to render before capturing the screenshot
            }, 500);
        }
    }, [capture]);

    return { capture };
};
