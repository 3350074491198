import * as React from 'react';
import Svg, { Defs, Ellipse, FeGaussianBlur, Filter, G, Mask, Path, SvgProps } from 'react-native-svg';

type SvgComponentProps = {
    id: string;
} & SvgProps;

function SvgComponent(props: SvgComponentProps) {
    let placeholderMaskId = `player-mask-${props.id}`;
    return (
        <Svg width={props.width ?? 48} height={props.height ?? 46} viewBox="0 0 48 46" fill="none" {...props}>
            <Defs>
                <Filter id="placeholder-image-blur" x="-20%" y="-20%" width="150%" height="150%">
                    <FeGaussianBlur stdDeviation="5" />
                </Filter>
            </Defs>
            <Mask id={placeholderMaskId} maskUnits="userSpaceOnUse" x={0} y={0} width={48} height={49}>
                <Ellipse cx={24} cy={15.5} rx={24} ry={24.5} fill="#FB4F14" filter={'url(#placeholder-image-blur'} />
            </Mask>
            <G mask={`url(#${placeholderMaskId})`}>
                <Path
                    d="M48 44.29V46H0v-1.71c0-.96.185-1.915.591-2.777.358-.76.83-1.365 1.622-2.1.998-.92 2.594-2.283 3.8-2.806 2.163-.941 4.164-2.833 6.768-3.455 1.173-.28 2.489-1.076 3.674-1.368.239-.061.456-.17.633-.328.013-.463.013-1.045-.01-1.736l.04-3.09s-.096-1.835-.752-2.043a.537.537 0 00-.108-.033c-.017-.003-.035-.008-.054-.01a2.422 2.422 0 01-.646-.204c-.21-.11-.38-.27-.443-.506-.062-.206-.018-.427-.033-.643-.029-.41-.275-.765-.455-1.131-.316-.648-.439-1.383-.483-2.108-.025-.394-.018-.816.207-1.139.221-.322.736-.44.98-.137-.061-1.512-.12-3.035.055-4.54.182-1.503.608-3.003 1.451-4.246.591-.874 1.37-1.599 2.203-2.237 1.185-.91 3.344-1.678 4.96-1.652 1.616-.026 3.775.742 4.96 1.65.833.638 1.612 1.362 2.203 2.237.843 1.243 1.269 2.743 1.451 4.245.175 1.505.116 3.028.055 4.54.244-.302.759-.185.98.138.224.323.232.745.207 1.139-.044.724-.167 1.459-.483 2.107-.18.366-.426.722-.456 1.132-.014.216.03.437-.032.643-.064.236-.234.396-.443.506a2.422 2.422 0 01-.646.203c-.02.003-.037.008-.054.01a.537.537 0 00-.108.033c-.656.209-.752 2.044-.752 2.044l.04 3.089a29.988 29.988 0 00-.01 1.736c.177.158.394.267.633.328 1.185.293 2.501 1.088 3.674 1.368 2.604.623 4.605 2.514 6.769 3.455 1.205.524 2.801 1.886 3.8 2.806.79.735 1.266 1.34 1.62 2.1.405.865.592 1.818.592 2.776v.003z"
                    fill="#fff"
                    fillOpacity={0.08}
                />
            </G>
        </Svg>
    );
}

export default SvgComponent;
