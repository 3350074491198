import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent, EVENT_TYPES, LOG_TAG, RUDDERSTACK_EVENTS } from '@/feature/analytics/constants';
import { trackRudderStackEvent } from '@/feature/analytics/trackRudderStackEvent';
import { useCheckIdComply } from '@/feature/kyc/hooks/use-check-id-comply';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import { UserInfo, getUserInfo } from '@/hooks/use-auth-user-info';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { common } from '@/styles/styles';
import { logger } from '@/utils/logging';
import { useQueryClient } from '@tanstack/react-query';

import { kycRouteNames } from '../../const';

type TrackKYCSuccessEventParams = {
    userInfo: UserInfo;
};

export const trackKYCSuccessEvent = ({ userInfo }: TrackKYCSuccessEventParams) => {
    trackRudderStackEvent(RUDDERSTACK_EVENTS.trigger('KYC Success'), {
        eventType: EVENT_TYPES.KYC,
        ...(userInfo?.data ? userInfo.data : {}),
    });
};

export const trackKYCSuccessEventAsync = async () => {
    try {
        const userInfo = await getUserInfo();
        if (userInfo?.data) {
            trackKYCSuccessEvent({ userInfo });
        }
    } catch (e) {
        logger.error(LOG_TAG, 'Error loading user info and unable to track KYC success event', e);
    }
};

export const VerifyingIdentity = () => {
    const navigation = useNavigation();
    const { t } = useTranslation('kyc');
    usePreventAndroidBackNavigation();
    const { mutate: verifyIdentity } = useCheckIdComply();

    const queryClient = useQueryClient();

    const invalidateAuthUserKeys = useCallback(() => queryClient.invalidateQueries(authUserKeys.all), [queryClient]);

    const verificationFailed = useCallback(() => {
        invalidateAuthUserKeys();
        BetrAnalytics.trackProductEvent(AnalyticsEvent.KYC_FAILURE);

        navigation.navigate(kycRouteNames.VERIFICATION_FAILED);
    }, [invalidateAuthUserKeys, navigation]);

    const verificationSuccess = useCallback(() => {
        invalidateAuthUserKeys();
        trackKYCSuccessEventAsync();
        BetrAnalytics.trackProductEvent(AnalyticsEvent.KYC_SUCCESS);

        navigation.navigate(kycRouteNames.VERIFICATION_SUCCESS);
    }, [invalidateAuthUserKeys, navigation]);

    useEffect(() => {
        verifyIdentity(
            {},
            {
                onSuccess: async resp => {
                    if (resp.verification_success) {
                        verificationSuccess();
                    } else if (resp.force_idpv) {
                        navigation.navigate(kycRouteNames.SCAN_ID);
                    } else {
                        verificationFailed();
                    }
                },
                onError: () => {
                    verificationFailed();
                },
            }
        );
    }, [verifyIdentity, navigation, verificationFailed, verificationSuccess, queryClient]);

    return (
        <Screen>
            <View style={[common.flex, common.alignCenter, common.justifyCenter]}>
                <LoadingSpinner />
                <SizedBox value={16} />
                <Text variant="titleSmall">{t('verifying_identity')}</Text>
            </View>
        </Screen>
    );
};
