import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import VenmoIcon from '@/assets/icons/venmo';
import { AnalyticsEvent } from '@/feature/analytics/constants';

import { AddPaymentMethod } from './AddPaymentMethod';

type PaymentMethodVenmoProps = {
    selectedAmount: string;
};

export const AddPaymentMethodVenmo = ({ selectedAmount }: PaymentMethodVenmoProps) => {
    const { t } = useTranslation('transactions');
    const { navigate } = useNavigation();
    return (
        <AddPaymentMethod
            icon={<VenmoIcon />}
            title={t('venmo')}
            description={t('venmo_description')}
            onPress={() =>
                navigate('VenmoDepositScreen', {
                    selectedAmount,
                })
            }
            analyticsEvent={AnalyticsEvent.DEPOSIT_METHOD_VENMO}
        />
    );
};
