import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg
            width={props.width ?? 24}
            height={props.height ?? 25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Circle
                cx={props.width / 2 || 12}
                cy={props.width / 2 || 12}
                r={props.r ?? 10}
                fill={props.color ?? designSystem.colors.gray3}
            />
            <Path d="M6.29 12h11.428" stroke={props.stroke ?? designSystem.colors.gray8} strokeWidth={2} />
        </Svg>
    );
}

export default SvgComponent;
