import { isFailedTransaction } from '@/feature/transactions/components/helper';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { toTitleCase } from '@/utils/toTitleCase';
import { format } from 'date-fns';
import { t } from 'i18next';

import { TRANSACTION_REQUEST_STATUS } from '../const';
import { Transaction, TransactionRequestStatusType, TransactionStatus } from '../hooks/use-transaction-details';

/**
 * Format a transaction for display
 * @param transaction - Transaction object
 */
export const formatTransaction = (transaction?: Transaction) => {
    if (!transaction) {
        return null;
    }

    const isDepositOrWithdrawal = transaction.action === 'deposit' || transaction.action === 'withdrawal';
    const baseFields = {
        header: formatHeader(transaction),
        initiated: formatInitiated(transaction),
        status: formatStatus(transaction),
        beforeBalance: formatBalance(transaction.before_balance),
        afterBalance: formatBalance(transaction.after_balance),
        transactionId: transaction.transaction_id,
        isFailed: isFailedTransaction(transaction.status),
        requestStatus: formatTransactionRequestStatus(transaction),
        ...(isDepositOrWithdrawal && {
            dailyDailyDepositLimitAfter: formatBalance(transaction.daily_deposit_limit_after),
            weeklyDepositLimitAfter: formatBalance(transaction.weekly_deposit_limit_after),
            monthlyDepositLimitAfter: formatBalance(transaction.monthly_deposit_limit_after),
        }),
    };

    switch (transaction.action) {
        case 'withdrawal':
            return {
                subText: `Withdrawal to ${formatCardType(transaction)}${formatLast4(transaction)} from ${
                    transaction.to
                }`,
                from: transaction.to,
                to: formatCardDetails(transaction),
                ...baseFields,
            };
        case 'deposit':
            return {
                subText: `Deposit from ${formatCardType(transaction)}${formatLast4(transaction)} to ${transaction.to}`,
                from: formatCardDetails(transaction),
                to: transaction.to,
                ...baseFields,
            };
        case 'debit':
            return {
                subText: `Debit from ${transaction.to}`,
                from: transaction.to,
                to: '',
                ...baseFields,
            };
        case 'credit':
            return {
                subText: `Credit to ${transaction.to}`,
                from: '',
                to: transaction.to,
                ...baseFields,
            };
        case 'bonus':
        case 'fre_bonus':
            const isCredit = transaction.status === 'CREDIT';
            const isFreeBetBonus = transaction.action === 'fre_bonus';
            return {
                subText: `${isFreeBetBonus ? 'Betr bucks' : 'Play-through cash'} ${isCredit ? 'to' : 'removed from'} ${
                    transaction.to
                }`,
                from: isCredit ? '' : transaction.to,
                to: isCredit ? transaction.to : '',
                ...baseFields,
            };
    }
};

/**
 * Format the Transaction Details header
 * @param transaction - Transaction object
 */
export const formatHeader = (transaction: Transaction) => {
    const currency = transaction.amount.toFixed(2);
    return transaction.amount > 0 ? `+${currency}` : currency;
};

/**
 * Format the `Initiated` row as `m/dd/yyyy at h:mm am/pm`
 * @param transaction - Transaction object
 */
export const formatInitiated = (transaction: Transaction) => formatDateTime(transaction.initiated);

/**
 * Format the `Status` row  as `{Status} - m/dd/yyyy at h:mm am/pm`
 * @param transaction - Transaction object
 */
export const formatStatus = (transaction: Transaction) =>
    `${formatStatusText(transaction.status)} - ${formatDateTime(transaction.created_at)}`;

/**
 * Format the currency rows to locale currency
 * @param value
 */
export const formatBalance = (value: string | null | number) => {
    return value === undefined || value === null ? undefined : toLocaleCurrency(value);
};

/**
 * Format a date to `m/dd/yyyy at h:mm am/pm`
 * @param date
 */
const formatDateTime = (date: number) => `${format(date, 'M/d/yy')} at ${format(date, 'h:mm a')}`;

/**
 * Format transaction status to a readable text
 * @param status - Transaction status
 */
const formatStatusText = (status: TransactionStatus) => {
    switch (status) {
        case 'WITHDRAWAL':
        case 'PAYMENT_CONFIRMED':
        case 'CREDIT':
        case 'DEBIT':
            return 'Completed';
        case 'WITHDRAWAL_ATTEMPT':
        case 'PAYMENT_CREATED':
            return t('transactions:attempted');
        case 'WITHDRAWAL_FAILED':
            return 'Failed';
        case 'PAYMENT_CANCELLED':
        case 'WITHDRAWAL_CANCELLED':
            return 'Cancelled';
        case 'PAYMENT_ABORTED':
            return 'Aborted';
        case 'PAYMENT_DECLINED':
            return 'Declined';
        case 'PAYMENT_EXPIRED':
            return 'Expired';
        case 'PAYMENT_PENDING':
            return 'Pending';
        case 'PAYMENT_PENDING_VERIFICATION':
            return 'Pending Verification';
        case 'PAYMENT_REVERSED':
            return 'Reversed';
    }
};

/**
 * Format the card type for a transaction
 * @param transaction - Transaction object
 */
export const formatCardType = (transaction: Transaction) => {
    switch (transaction.payment_method) {
        case 'paysafe':
            return 'debit card';
        case 'paysafe_mazooma':
            return 'account';
        case 'paysafe_paypal':
            return 'PayPal';
        case 'paysafe_pay_by_bank':
            return 'bank';
        case 'paysafe_venmo':
            return 'Venmo';
    }
};

/**
 * Format the card details for a transaction
 * @param transaction - Transaction object
 */
export const formatCardDetails = (transaction: Transaction) => {
    switch (transaction.payment_method) {
        case 'paysafe':
        case 'paysafe_mazooma':
            let fromText = '';
            if (transaction.cc_type) {
                fromText = `${transaction.cc_type} `;
            }
            fromText = `${fromText}${formatCardType(transaction)}`;
            if (transaction.cc_last4) {
                fromText = `${fromText} (•••• ${transaction.cc_last4})`;
            }
            return fromText;
        case 'paysafe_paypal':
            if (transaction.cc_last4) {
                return `Card ending in ${transaction.cc_last4}`;
            }
            if (transaction.email) {
                return transaction.email;
            }
            return 'Paypal';
        case 'paysafe_venmo':
            if (transaction.venmo_id) {
                return `Venmo for ${transaction.venmo_id}`;
            }
            return 'Venmo';
    }
};

/**
 * Format the last 4 digits of a card for a transaction
 * @param transaction - Transaction object
 */
export const formatLast4 = (transaction: Transaction) => {
    return transaction.cc_last4 ? ` ending in ${transaction.cc_last4}` : '';
};

const statusOrder = ['requested', 'reviewed', 'complete'] as const;

/**
 * Formats the transaction `status_events`.
 *
 * @param transaction - The transaction detail object containing `status_events`.
 * @returns An array of formatted status objects with label, status, and completedAt properties.
 */
export const formatTransactionRequestStatus = (transaction: Transaction) => {
    let previousStatus: TransactionRequestStatusType | null = null;

    return statusOrder
        .map(key => {
            const statusEvent = transaction.status_events[key];

            if (!statusEvent) {
                return;
            }

            const { status, completed_at } = statusEvent;

            let formattedStatus: TransactionRequestStatusType = status;

            if (
                status === TRANSACTION_REQUEST_STATUS.PENDING &&
                previousStatus !== TRANSACTION_REQUEST_STATUS.SUCCESS
            ) {
                formattedStatus = 'NONE';
            }

            previousStatus = status;

            return {
                label: toTitleCase(key), // e.g. "Requested", "Reviewed", "Complete"
                status: formattedStatus,
                completedAt:
                    status === TRANSACTION_REQUEST_STATUS.SUCCESS && completed_at
                        ? formatDateTime(completed_at)
                        : undefined,
                testID: `txnDetails-${key}`,
            };
        })
        .filter(event => event !== undefined);
};
