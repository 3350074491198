import { PaymentMethodTypes, ccTypeMap } from '@/feature/deposit/const';
import { ClosedLoopMethodProps } from '@/feature/withdraw/components/closedLoop.types';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { WithdrawMethod, WithdrawMethodTypes } from '../hooks/use-withdrawal-methods';

export const paymentMethodLabels: Record<string, string> = {
    paysafe: 'Debit card',
    paysafe_paypal: 'Complete on PayPal',
    paysafe_mazooma: 'Account',
    paysafe_venmo: 'Venmo',
};

export const LOG_TAG = '[Withdrawal]';
export const LOG_TAG_MULTIPLE = '[Multiple Withdrawal]';

/**
 * Get withdrawal method name for withdrawal method list (PayPal, Debit card, Account name, etc.)
 */
export const getWithdrawalMethodName = (methodType: WithdrawMethodTypes, cc_type: string = '') => {
    switch (methodType) {
        case PaymentMethodTypes.PaysafePaypal:
            return 'PayPal';
        case PaymentMethodTypes.PaysafeMazooma:
        case PaymentMethodTypes.PaysafePayByBank:
            return `${cc_type}`;
        case PaymentMethodTypes.PaysafeVenmo:
            return 'Venmo';
        default:
            return `${cc_type ? ccTypeMap[cc_type] : 'Debit card'}`;
    }
};

/**
 * Function to add a new distribution item to the list of closed-loop
 * withdrawal methods and its distribution amount.
 * @param distributionArray - List (array) of closed-loop withdrawal methods
 * @param method - Withdrawal method
 * @param amountToReturn - Amount to withdraw to each method (distribution value)
 */
export const addDistribution = (
    distributionArray: ClosedLoopMethodProps[],
    method: WithdrawMethod,
    amountToReturn: number
) => {
    const distributionObject = {
        ...method,
        amountToReturn,
    };
    const newDistributionObject = [...distributionArray, distributionObject];
    return newDistributionObject;
};

/**
 * Function used to get the closed-loop methods array with the withdrawal distribution
 * for each withdrawal method.
 */
export const getDistribution = (
    methods: WithdrawMethod[],
    selectedMethod: WithdrawMethod,
    amountToWithdraw: number
): ClosedLoopMethodProps[] => {
    /* Getting the methods list without the selected method. */
    const filteredMethods = methods.filter(method => selectedMethod.payment_type_id !== method.payment_type_id);
    if (!filteredMethods.length) {
        return [];
    }
    /* Sorting filtered methods list in descending order based on closed_loop_remain value */
    const methodsDesc = filteredMethods.sort((a, b) => b.closed_loop_remain - a.closed_loop_remain);
    let closedLoopMethodsArray: ClosedLoopMethodProps[] = [];
    let amountToWithdrawRemaining = amountToWithdraw;

    /* Get the required amount to return to the selected method */
    let selectedMethodAmountToReturn = selectedMethod.closed_loop_remain;
    /* subtracting the closed loop remain of the selected method from the total amount to withdraw */
    amountToWithdrawRemaining -= selectedMethod.closed_loop_remain;

    /* Adding non-selected methods distribution to closed loop methods list */
    methodsDesc.forEach((method: WithdrawMethod) => {
        if (amountToWithdrawRemaining > 0) {
            const maxAmountToWithdrawByMethod =
                amountToWithdrawRemaining > method?.closed_loop_remain
                    ? method?.closed_loop_remain
                    : amountToWithdrawRemaining;

            closedLoopMethodsArray = addDistribution(closedLoopMethodsArray, method, maxAmountToWithdrawByMethod);

            amountToWithdrawRemaining -= maxAmountToWithdrawByMethod;
        }
    });
    /* Add the remain amount to selected method */
    if (amountToWithdrawRemaining > 0) {
        selectedMethodAmountToReturn += amountToWithdrawRemaining;
    }

    closedLoopMethodsArray = addDistribution(closedLoopMethodsArray, selectedMethod, selectedMethodAmountToReturn);

    return closedLoopMethodsArray;
};

/**
 * Generates an error message based on the withdrawal amount input and various limits.
 *
 * @param maxWithdrawalLimit - The maximum amount that can be withdrawn in a single transaction.
 * @param amountInput - The input amount as a string.
 * @param balance - The current balance available for withdrawal.
 * @param isPicksActive - Optional flag indicating if picks are active.
 * @param minWithdrawalLimit - Optional minimum amount that can be withdrawn.
 * @returns A string containing the appropriate error message, or an empty string if no errors are found.
 */
export const getErrorMessage = (
    maxWithdrawalLimit: number,
    amountInput: string,
    balance: number,
    isPicksActive?: boolean,
    minWithdrawalLimit?: number
): string => {
    const validateArray = amountInput.split('.');
    if (validateArray.length > 2 || validateArray[0] === '' || validateArray[1] === '' || amountInput.includes(',')) {
        return 'Please enter a valid amount.';
    }

    const amount = parseFloat(amountInput);
    const displayMinimumWithdrawError =
        minWithdrawalLimit && amount < minWithdrawalLimit && amount !== 0 && isPicksActive;

    if (amount > maxWithdrawalLimit) {
        return `Maximum single withdraw amount: ${toLocaleCurrency(maxWithdrawalLimit)}`;
    } else if (amount > balance) {
        return `Maximum withdrawable amount: ${toLocaleCurrency(balance)}`;
    } else if (displayMinimumWithdrawError) {
        return `Minimum withdrawable amount: ${toLocaleCurrency(minWithdrawalLimit)}`;
    } else {
        return '';
    }
};
