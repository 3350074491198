import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { usePlayerFiltersStore } from '@/feature/lobby/hooks/use-player-filters';
import { useGetOrderedLeaguesByEvents } from '@/hooks/use-fantasy-league-configs';
import { PickemLobbyStackParamList } from '@/navigation/pickem/types';
import { League } from '@/types/api.generated';
import { NFL_FULL_SEASON } from '@/utils/constants';

export type LeagueType = {
    imageUrl?: string;
    label: string;
    id: League;
};

export const useLeagueSelector = (defaultLeague: League) => {
    const { sortedLeagues: leaguesData } = useGetOrderedLeaguesByEvents(false, 'cache-first');
    const { setParams } = useNavigation<StackNavigationProp<PickemLobbyStackParamList>>();

    const leagues: LeagueType[] = useMemo(
        () =>
            leaguesData
                ?.filter(league => league.eventsCount > 0)
                ?.map(league => ({
                    id: (league.league.league ?? '') as League,
                    label: league.league.league === League.NflFullSeason ? NFL_FULL_SEASON : league.league?.label || '',
                    imageUrl: league.league.icon || undefined,
                })) || [],
        [leaguesData]
    );
    const { t } = useTranslation('common');

    const [selectedLeague, setSelectedLeague] = useState<LeagueType | undefined>(
        leagues.find(l => l.id === defaultLeague) ?? leagues[0]
    );

    useEffect(() => {
        if (leaguesData && !selectedLeague) {
            setSelectedLeague(leagues.find(l => l.id === defaultLeague) ?? leagues[0]);
        }
    }, [defaultLeague, leagues, leaguesData, selectedLeague]);

    const resetAllFilters = usePlayerFiltersStore(state => state.actions.resetAllFilters);
    const { showSelectionSheet } = useAlerts();

    const onPressLeague = useCallback(
        (selection: LeagueType) => {
            setSelectedLeague(selection);

            // updates the url on web
            setParams({ league: selection.id });
            resetAllFilters();
        },
        [resetAllFilters, setParams]
    );

    const showLeagueSelector = () => {
        if (selectedLeague) {
            showSelectionSheet({
                items: leagues,
                title: t('leagues'),
                selection: selectedLeague,
                onItemSelected: league => onPressLeague(league),
            });
        }
    };

    return { selectedLeague, showLeagueSelector };
};
