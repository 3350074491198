import { SharedEntryInfoFragment } from '@/feature/entry-share/api/query.generated';
import { ProjectionType } from '@/types/api.generated';
import { isEventAcceptingBets } from '@/utils/filterEvents';

export const sortPicksByProjectionType = (prebuiltLineup: SharedEntryInfoFragment): SharedEntryInfoFragment => {
    const { picks } = prebuiltLineup.entry;

    picks.sort((a, b) => {
        const boostedA = a.projection.type === ProjectionType.Boosted ? -1 : 1;
        const boostedB = b.projection.type === ProjectionType.Boosted ? -1 : 1;

        const isSpecialA = a.projection.type === ProjectionType.Special;
        const isSpecialB = b.projection.type === ProjectionType.Special;

        // Objects with 'Boosted' type come first
        if (boostedA !== boostedB) {
            return boostedA - boostedB;
        }

        // Next, special projections
        if (isSpecialA && !isSpecialB) {
            return -1;
        }
        if (!isSpecialA && isSpecialB) {
            return 1;
        }

        return 0;
    });

    return prebuiltLineup;
};

const isLineupValid = (lineup: SharedEntryInfoFragment) => {
    const hasAcceptingBetsEvents = lineup.entry.picks.some(pick =>
        isEventAcceptingBets({ league: pick.league, status: pick.eventStatus })
    );

    return hasAcceptingBetsEvents;
};

/*
 * This function returns a random index of a valid lineup.
 * a valid lineup is a lineup that has at least one event that is accepting bets or has not passed yet.
 * */
export const findValidLineupIndex = (lineups: SharedEntryInfoFragment[]) => {
    const validLineups = lineups.filter(isLineupValid);
    if (validLineups.length === 0) {
        return -1;
    }
    const randomIndex = Math.floor(Math.random() * validLineups.length);
    return lineups.indexOf(validLineups[randomIndex]);
};
