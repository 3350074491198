import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetSummaryProps, BetSummarySection, BetSummarySectionProps } from '@/components/BetSummarySection';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { getMultiplier } from '../../utils/bet-summary-section-utils';
import { useBetDetailsVoidedBetSummary, useSharedBetDetailsBetSummary } from './utils';

/**
 * Helper Hooks
 */
const useOpenMultiBetSummary = (props: BetSummaryProps): BetSummarySectionProps => {
    const { bet } = props;
    return {
        ...useSharedBetDetailsBetSummary(props),
        ...getMultiplier(bet),
        body3Text: toLocaleCurrency(bet.potential_payout),
    };
};

const useSettledMultiBetSummary = (props: BetSummaryProps): BetSummarySectionProps => {
    const { bet } = props;
    const { t } = useTranslation('bets');
    return {
        ...useSharedBetDetailsBetSummary(props),
        ...getMultiplier(bet),
        header3Text: t('paid'),
        body3Text: toLocaleCurrency(bet.amount_won),
        header3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
        body3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
    };
};

/**
 * Components
 */
const OpenMultiBetSummary = (props: BetSummaryProps) => <BetSummarySection {...useOpenMultiBetSummary(props)} />;

const VoidedMultiBetSummary = (props: BetSummaryProps) => (
    <BetSummarySection {...useBetDetailsVoidedBetSummary(props)} />
);

const SettledMultiBetSummary = (props: BetSummaryProps) => <BetSummarySection {...useSettledMultiBetSummary(props)} />;

// Handles both Script and Combo bet types
export const MultiBetSummarySection = (props: BetSummaryProps) => {
    const { bet } = props;
    switch (bet.result) {
        case 'PENDING':
            return <OpenMultiBetSummary {...props} />;
        case 'WON':
        case 'LOST':
        case 'CASHEDOUT':
            return <SettledMultiBetSummary {...props} />;
        case 'CANCELLED':
        case 'PUSHED':
            return <VoidedMultiBetSummary {...props} />;
        default:
            return <OpenMultiBetSummary {...props} />;
    }
};
