import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Keyboard, Platform, StyleSheet, useWindowDimensions } from 'react-native';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import Animated, {
    Extrapolation,
    interpolate,
    runOnJS,
    useAnimatedStyle,
    useDerivedValue,
    useSharedValue,
    withDelay,
    withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SceneRendererProps, TabBar, TabView } from 'react-native-tab-view';

import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { LeagueConfigsFragment } from '@/api/leagues/query.generated';
import { Loading } from '@/components/Loading';
import { PRODUCT_SWITCH_BUTTON_HEIGHT } from '@/components/LobbyProductSwitchButton';
import { TopTabBarLabel } from '@/components/TopTabBar';
import { Box } from '@/components/lib/components/Box';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useBackHandler } from '@/hooks/use-back-handler';
import { useDebounce } from '@/hooks/use-debounce';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { MAX_WEB_WIDTH, isWeb } from '@/utils/constants-platform-specific';
import { runAfterInteractions } from '@/utils/runAfterInteractions';

import { SearchBar } from '../components/SearchBar';
import { SearchResults } from '../components/SearchResults';
import { useAvailableLeagues } from '../hooks/use-available-leagues';

type Route = {
    key: League;
    title: League;
    label: string;
};

type NavigationState = {
    index: number;
    routes: Route[];
};

export type SearchScreenRouteProp = RouteProp<RootStackParamList, 'SearchScreen'>;
export type SearchStackScreenProp = StackNavigationProp<RootStackParamList, 'SearchScreen'>;

export const FADE_IN_DURATION = 300;
export const FADE_IN_TABS_DELAY = 400;
export const FADE_IN_TABS_DURATION = 200;

export const SEARCH_BAR_PADDING_TOP = 8;

/**
 * The animation of the search bar functions as follows:
 * - The LobbyScreen contains a search bar, which is only visible when the LobbyScreen is in focus.
 * - When navigating to the SearchScreen, there is no visual transition applied during the navigation process.
 * - Upon entering the SearchScreen, its initial state is configured as follows:
 *       - The background is fully transparent, the tabs have their opacity set to 0, and the search bar is positioned at the top of the screen.
 *       - The search bar in the SearchScreen is aligned to match the exact position of the search bar in the LobbyScreen.
 * - The animation then begins, smoothly transitioning the search bar, the background, and the tabs into their intended positions.
 *
 * The key point to note is that when we navigate from the LobbyScreen to the SearchScreen without any transition effect,
 * a second search bar is effectively overlaid directly on top of the one in the LobbyScreen.
 * After the navigation to the SearchScreen is complete, the search bar in the LobbyScreen is hidden,
 * and the search bar in the SearchScreen begins animating, making it appear as if the transition between the two screens was seamless.
 */

export const SearchScreen = () => {
    const { navigate } = useNavigation();
    const { params } = useRoute<SearchScreenRouteProp>();
    const [hasFinishedFadeIn, setHasFinishedFadeIn] = useState(false);
    // we need to delay the fade animation until all state changes are done
    const [shouldStartFadingOut, setShouldStartFadingOut] = useState(false);
    // when we start the fade out animation, we need to clear the query to correctly animate the search bar
    // but we do not want to trigger a render on the tabs, so we hide the query input only for the search bar
    const [hideQuery, setHideQuery] = useState(false);

    const fadeInOpacity = useSharedValue(0);
    const fadeInOpacityDelayed = useSharedValue(0);

    const translateValue = useDerivedValue(() => {
        const finalValue = params.searchBarPosition ?? PRODUCT_SWITCH_BUTTON_HEIGHT;

        return interpolate(fadeInOpacity.value, [0, 1], [finalValue, 0], Extrapolation.CLAMP);
    });

    const fadeIn = useCallback(() => {
        fadeInOpacity.value = withTiming(1, {
            duration: FADE_IN_DURATION,
        });

        fadeInOpacityDelayed.value = withDelay(
            FADE_IN_TABS_DELAY,
            withTiming(
                1,
                {
                    duration: FADE_IN_TABS_DURATION,
                },
                () => {
                    runOnJS(setHasFinishedFadeIn)(true);
                }
            )
        );
    }, [fadeInOpacity, fadeInOpacityDelayed]);

    const fadeOut = useCallback(() => {
        runAfterInteractions(() => {
            Keyboard.dismiss();
            setHideQuery(true);
            // we need to wait for the keyboard to hide on android
            if (Platform.OS === 'android') {
                setTimeout(() => {
                    setShouldStartFadingOut(true);
                }, 100);
            } else {
                setShouldStartFadingOut(true);
            }
        });
    }, []);

    const navigateBack = useCallback(() => {
        navigate('PickemHome', {
            screen: 'Lobby',
            params: {
                screen: 'LobbyScreen',
            },
        });
    }, [navigate]);

    const startFadeOutAnimation = useCallback(() => {
        const fade = () => {
            fadeInOpacity.value = withTiming(0, {
                duration: FADE_IN_DURATION,
            });
            fadeInOpacityDelayed.value = withTiming(
                0,
                {
                    duration: FADE_IN_DURATION,
                },
                () => {
                    // ! do not use `goBack` since it will flicker the screen after the navigation finishes
                    runOnJS(navigateBack)();
                }
            );
        };

        runAfterInteractions(fade);
    }, [fadeInOpacity, fadeInOpacityDelayed, navigateBack]);

    useEffect(() => {
        if (shouldStartFadingOut && hideQuery) {
            startFadeOutAnimation();
        }
    }, [hideQuery, shouldStartFadingOut, startFadeOutAnimation]);

    const animatedOpacityStyle = useAnimatedStyle(() => {
        return {
            opacity: fadeInOpacity.value,
        };
    });

    const animatedDelayedOpacityStyle = useAnimatedStyle(() => {
        return {
            opacity: fadeInOpacityDelayed.value,
        };
    });

    const animatedPaddingStyle = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: translateValue.value,
                },
            ],
        };
    });

    const leagues = useAvailableLeagues();
    const [index, setIndex] = useState(0);
    // this is the query that is shown in the search bar
    // this need to be updated instantly to show the query in the search bar (with no debounce)
    const [inputQuery, setQuery] = useState('');
    const inputRef = useRef<TextInput>(null);

    // this is the query that is used to search the players
    // this need to be debounced to avoid too many re-renders
    const query = useDebounce(200, inputQuery);

    const routes: Route[] = useMemo(() => {
        return leagues.map(league => ({
            key: league.league,
            title: league.league,
            label: league.label,
        }));
    }, [leagues]);

    const { width } = useWindowDimensions();
    const { top } = useSafeAreaInsets();

    useEffect(() => {
        // fade in the components on mount only
        fadeIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFocused = useIsFocused();

    useEffect(() => {
        if (inputRef.current && leagues.length && isFocused) {
            setTimeout(() => inputRef?.current?.focus(), FADE_IN_DURATION);
        }
    }, [hasFinishedFadeIn, leagues.length, isFocused]);

    const handleBackButtonAndroid = useCallback(() => {
        fadeOut();
        return true;
    }, [fadeOut]);

    useBackHandler(handleBackButtonAndroid);

    useEffect(() => {
        if (leagues?.[index]) {
            BetrAnalytics.trackEvent(AnalyticsEvent.SEARCH_LEAGUE_SELECTED, { league: leagues[index].league });
        }
    }, [index, leagues]);

    const scenes = useCallback(
        ({ route }: { route: Route }) => renderScene(route, query, !hasFinishedFadeIn || shouldStartFadingOut),
        [hasFinishedFadeIn, query, shouldStartFadingOut]
    );

    return (
        <Animated.View style={[styles.container, { paddingTop: top + SEARCH_BAR_PADDING_TOP }]}>
            <Animated.View style={[StyleSheet.absoluteFillObject, styles.backgroundContainer, animatedOpacityStyle]} />
            <Animated.View style={[styles.container, animatedPaddingStyle]}>
                <MaxWidthWrapper>
                    <Box paddingLeft={'s16'} pb={'s8'}>
                        <SearchBar
                            setQuery={setQuery}
                            query={hideQuery ? '' : inputQuery}
                            display={'search'}
                            inputRef={inputRef}
                            animatedValue={fadeInOpacity}
                            onPressCancel={fadeOut}
                            disableCancelButton={hideQuery}
                        />
                    </Box>
                </MaxWidthWrapper>
                <Animated.View style={[common.flex, animatedDelayedOpacityStyle]}>
                    {leagues.length === 0 ? (
                        <MaxWidthWrapper flex={1} justifyContent="center" alignItems={'center'}>
                            <Loading />
                        </MaxWidthWrapper>
                    ) : (
                        <TabView
                            navigationState={{ index, routes }}
                            renderScene={scenes}
                            onIndexChange={setIndex}
                            renderTabBar={props => {
                                if (isWeb) {
                                    return (
                                        <MaxWidthWrapper>
                                            <ScrollView horizontal>{renderTabBar(props, width, leagues)}</ScrollView>
                                        </MaxWidthWrapper>
                                    );
                                }
                                return <MaxWidthWrapper>{renderTabBar(props, width, leagues)}</MaxWidthWrapper>;
                            }}
                            initialLayout={{ width }}
                            keyboardDismissMode="none"
                        />
                    )}
                </Animated.View>
            </Animated.View>
        </Animated.View>
    );
};

const renderScene = (route: Route, query: string, hasFinishedFadeIn: boolean) => {
    return <SearchResults query={query} league={route.key} animationIsInProgress={hasFinishedFadeIn} />;
};

const renderTabBar = (
    props: SceneRendererProps & {
        navigationState: NavigationState;
    },
    screenWidth: number,
    leagues: LeagueConfigsFragment[]
) => {
    const maxScreenWidth = screenWidth > MAX_WEB_WIDTH ? MAX_WEB_WIDTH : screenWidth;
    return (
        <TabBar
            {...props}
            scrollEnabled
            style={[styles.tabBarStyle]}
            indicatorStyle={styles.indicator}
            tabStyle={[styles.tabStyle, { minWidth: maxScreenWidth / leagues.length }]}
            renderLabel={({ route, focused }) => {
                return <TopTabBarLabel focused={focused}>{route.label}</TopTabBarLabel>;
            }}
        />
    );
};

const styles = StyleSheet.create({
    indicator: {
        height: 4,
        backgroundColor: designSystem.colors.gray1,
        marginBottom: -4,
    },
    tabBarStyle: {
        backgroundColor: designSystem.colors.gray8,
        borderBottomWidth: 4,
        borderBottomColor: designSystem.colors.gray6,
        flexDirection: 'row',
    },
    tabStyle: {
        flexGrow: 1,
        width: 'auto',
        paddingHorizontal: 16,
        height: 48,
    },
    container: {
        flex: 1,
        backgroundColor: 'transparent',
    },
    backgroundContainer: {
        backgroundColor: designSystem.colors.gray8,
        zIndex: -1,
    },
});
