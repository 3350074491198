import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import ChevronRight from '@/assets/icons/chevronRight';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { SbkLobbyTabNavigationProp } from '@/navigation/sbk/types';
import { TouchableOpacity } from '@gorhom/bottom-sheet';

import { useFeaturedBets } from '../hooks/use-featured-bets';
import { FeaturedBetCard } from './FeaturedBets/CardEvent/FeaturedBetCard';

export const FeaturedBetsSection = () => {
    const { data: featuredBets, refetch } = useFeaturedBets({ limit: 20 });
    const { t } = useTranslation('bets');
    useFetchOnResume(refetch);
    const navigation = useNavigation<SbkLobbyTabNavigationProp>();
    const [randomIndex, setRandomIndex] = useState<number | null>(null);

    useEffect(() => {
        if (randomIndex === null && featuredBets && featuredBets.length > 0) {
            const newRandomIndex = Math.floor(Math.random() * featuredBets.length);
            setRandomIndex(Math.min(newRandomIndex, featuredBets.length - 1));
        }
    }, [featuredBets, randomIndex]);

    const randomFeaturedBet = randomIndex !== null ? featuredBets?.[randomIndex] : null;

    if (!randomFeaturedBet) {
        return null;
    }

    return (
        <Box flexShrink={1} px="s16">
            <TouchableOpacity style={styles.headerButton} onPress={() => navigation.navigate('FeaturedHub')}>
                <Row>
                    <Text variant="headlineMedium" lineHeight={28} numberOfLines={1} mr="s12">
                        {t('featured_bets')}
                    </Text>
                    <Box
                        backgroundColor="gray6"
                        width={28}
                        height={28}
                        borderRadius="r40"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ChevronRight />
                    </Box>
                </Row>
            </TouchableOpacity>
            <SizedBox value={16} />
            <FeaturedBetCard featuredBet={randomFeaturedBet} />
            <SizedBox value={16} />
        </Box>
    );
};

const styles = StyleSheet.create({
    headerButton: {
        alignSelf: 'flex-start',
    },
});
