import * as React from 'react';
import Svg, { Circle, Defs, FeGaussianBlur, Filter, G, Mask, Path, SvgProps } from 'react-native-svg';

type SvgComponentProps = {
    id: string;
} & SvgProps;

function SvgComponent(props: SvgComponentProps) {
    let placeholderMaskId = `popular-player-mask-${props.id}`;

    return (
        <Svg width={72} height={71} viewBox="0 0 72 71" fill="none" {...props}>
            <Defs>
                <Filter id="placeholder-image-blur" x="-20%" y="-20%" width="150%" height="150%">
                    <FeGaussianBlur stdDeviation="5" />
                </Filter>
            </Defs>
            <Mask id={placeholderMaskId} maskUnits="userSpaceOnUse" x={0} y={-10} width={72} height={72}>
                <Circle cx={36} cy={26} r={36} fill="#FB4F14" filter={'url(#placeholder-image-blur'} />
            </Mask>
            <G mask={`url(#${placeholderMaskId})`}>
                <Path
                    d="M72 68.434V71H0v-2.566c0-1.438.277-2.872.887-4.165.536-1.14 1.246-2.047 2.432-3.15 1.497-1.38 3.892-3.424 5.7-4.21 3.245-1.41 6.246-4.247 10.153-5.182 1.759-.42 3.733-1.613 5.51-2.051a2.26 2.26 0 00.95-.492c.019-.694.019-1.567-.014-2.605l.059-4.633s-.144-2.753-1.128-3.066a.808.808 0 00-.162-.05c-.026-.003-.052-.01-.082-.015-.321-.06-.672-.152-.968-.305-.314-.164-.569-.404-.665-.758-.093-.31-.026-.641-.048-.965-.044-.614-.414-1.148-.684-1.697-.473-.973-.658-2.075-.724-3.162-.037-.59-.026-1.224.31-1.708.333-.484 1.105-.66 1.471-.206-.092-2.269-.181-4.553.081-6.81.274-2.254.913-4.504 2.177-6.369.887-1.312 2.056-2.398 3.305-3.355 1.778-1.366 5.015-2.517 7.44-2.48 2.425-.037 5.662 1.114 7.44 2.476 1.25.957 2.418 2.044 3.305 3.355 1.264 1.865 1.903 4.115 2.177 6.369.262 2.257.173 4.541.08 6.81.367-.453 1.14-.278 1.472.206.336.485.347 1.118.31 1.709-.066 1.086-.25 2.189-.724 3.161-.27.55-.64 1.083-.684 1.697-.022.324.044.656-.048.965-.096.354-.351.595-.665.759-.296.152-.647.244-.968.305-.03.003-.056.011-.082.015a.808.808 0 00-.163.05c-.983.312-1.127 3.065-1.127 3.065l.06 4.634a45.005 45.005 0 00-.015 2.604c.266.236.59.4.95.492 1.777.439 3.751 1.632 5.51 2.052 3.907.934 6.908 3.771 10.153 5.182 1.808.785 4.203 2.83 5.7 4.21 1.186 1.102 1.9 2.01 2.432 3.15.606 1.296.887 2.726.887 4.164v.004z"
                    fill="#fff"
                    fillOpacity={0.08}
                />
            </G>
        </Svg>
    );
}

export default SvgComponent;
