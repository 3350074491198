import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { Loading } from '@/components/Loading';
import { usePaymentFlags } from '@/feature/devsettings/hooks/use-payment-flags-store';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { common } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { isAndroid } from '@/utils/constants-platform-specific';

import { PaymentMethodTypes, paymentMethodOrder } from '../const';
import {
    PaymentMethod,
    PreferredPayment,
    hasSavedNuveiMethodsSelector,
    useDepositPaymentMethods,
} from '../hooks/use-deposit-payment-methods';
import { AddPaymentMethodBankTransfers } from './AddPaymentMethodBankTransfers';
import { AddPaymentMethodDebitCard } from './AddPaymentMethodDebitCard';
import { AddPaymentMethodPayByBank } from './AddPaymentMethodPayByBank';
import { AddPaymentMethodPayPal } from './AddPaymentMethodPayPal';
import { AddPaymentMethodVenmo } from './AddPaymentMethodVenmo';
import { PaymentMethodItem } from './PaymentMethodItem';

const mockNuveiPaymentMethod: PaymentMethod = {
    type: PaymentMethodTypes.PaysafeMazooma,
    value: 'paysafe_mazooma',
    name: 'txt.mazooma',
    supplier: 'paysafe',
    iframe: true,
    min: 0.01,
    max: 100000,
    logos: ['https://via.placeholder.com/150'],
    methods: {
        bank_transfer: {
            name: 'Bank Transfer',
            logos: ['https://via.placeholder.com/150'],
            position: 1,
        },
    },
    accountId: 'mock-nuvei-account-id',
    order: 3,
};

type PaymentMethodsProps = {
    handleSelect: (method: PaymentMethod, preferredMethod?: PreferredPayment) => void;
    selectedAmount: string;
    selectedPaymentMethod?: PaymentMethod;
    selectedPreferredMethod?: PreferredPayment;
};

/**
 * Show online bank transfers first, as it costs less to use over PayPal
 * TODO: Remove this order logic when we have https://pitchtech.atlassian.net/browse/CSP-470 done by BE
 *
 * @param PaymentMethod[] payments - The payment methods to order
 *
 * @returns PaymentMethod[] - The ordered payment methods
 */
const orderPaymentMethods = (payments: PaymentMethod[]) => {
    return payments.sort((a, b) => paymentMethodOrder.indexOf(a.type) - paymentMethodOrder.indexOf(b.type));
};

export const PaymentMethods: FC<PaymentMethodsProps> = ({
    selectedAmount,
    selectedPaymentMethod,
    selectedPreferredMethod,
    handleSelect,
}) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const { flags } = usePaymentFlags();

    const { data: depositPaymentMethods, isInitialLoading } = useDepositPaymentMethods();
    const jurisdictionSettings = useJurisdictionStore(state => state.jurisdictionSettings);

    const isDebugMode = flags.isDebugMode.enabled;

    const nuveiEnabled = isDebugMode
        ? flags.nuveiEnabled.enabled
        : jurisdictionSettings?.globalSettings?.featureFlags?.enable_nuvei?.enabled ?? false;

    // Enable Pay By Bank (Paysafe) for jurisdictions that have it enabled
    const enablePayByBank = isDebugMode
        ? flags.enablePayByBank?.enabled
        : jurisdictionSettings?.productConfig?.settings?.deposit_pay_by_bank_enabled ?? false;

    // Enable Venmo (Paysafe) for jurisdictions that have it enabled
    const enableVenmo = isDebugMode
        ? flags.enableVenmo?.enabled
        : jurisdictionSettings?.productConfig?.settings?.deposit_venmo_enabled ?? false;

    // Mazooma (Nuvei) is the same as IBT (Paysafe) in this context
    const enableInstantBankTransfers = isDebugMode
        ? flags.enableInstantBankTransfers.enabled
        : jurisdictionSettings?.productConfig?.settings?.deposit_instant_bank_transfers_enabled ?? false;

    // ACH (Paysafe) is the same as Debit Card (Paysafe) in this context
    const enableDebitCardDeposit = isDebugMode
        ? flags.enableDebitCardDeposit.enabled
        : jurisdictionSettings?.productConfig?.settings?.deposit_debit_card_enabled ?? false;

    // Control visibility of Instant Bank Transfer (IBT) option
    // Reference: https://github.com/betrapp/pickem/pull/2454
    // Note: This flag is primarily used for the web version of the app
    // For web: hide IBT if hide_deposit_ibt is enabled, regardless of enable_nuvei
    // For mobile: this flag is not typically used, as IBT visibility is controlled differently
    const showDepositIbt = isDebugMode
        ? flags.showDepositIbt.enabled
        : !jurisdictionSettings?.globalSettings?.featureFlags?.hide_deposit_ibt?.enabled;

    // Control visibility of Pay By Bank (Paysafe) option for web, it includes saved payment methods
    const showPayByBankWeb = !(
        jurisdictionSettings?.globalSettings?.featureFlags?.hide_pay_by_bank_web?.enabled ?? true
    );

    const hasSavedNuveiMethods = isDebugMode
        ? flags.hasSavedNuveiMethods.enabled
        : hasSavedNuveiMethodsSelector(depositPaymentMethods);

    useEffect(() => {
        if (!depositPaymentMethods?.data) {
            return;
        }

        const isDebugModeWithSavedMethods = isDebugMode && hasSavedNuveiMethods;

        // Filter payment methods based on debug mode
        let methodsToFilter = isDebugModeWithSavedMethods
            ? // In debug mode with saved methods, only keep PayPal payment method
              depositPaymentMethods.data.filter(method => method.type === PaymentMethodTypes.PaysafePaypal)
            : // In normal mode, keep all payment methods
              depositPaymentMethods.data;

        // Include mock Nuvei method if in debug mode with saved methods
        if (isDebugModeWithSavedMethods) {
            methodsToFilter = [...methodsToFilter, mockNuveiPaymentMethod];
        }

        const filteredMethods = methodsToFilter.filter(method => {
            switch (method.type) {
                case PaymentMethodTypes.PaysafePayByBank:
                    if (isWeb) {
                        return showPayByBankWeb;
                    }
                    return enablePayByBank;
                case PaymentMethodTypes.PaysafeMazooma:
                    const showMazooma = enableInstantBankTransfers && (nuveiEnabled || hasSavedNuveiMethods);
                    return showMazooma;
                case PaymentMethodTypes.Paysafe:
                    return enableDebitCardDeposit;
                case PaymentMethodTypes.PaysafePaypal:
                    return true;
                case PaymentMethodTypes.PaysafeVenmo:
                    if (isWeb) {
                        return null;
                    }
                    return enableVenmo;
                default:
                    return false;
            }
        });

        const amountFilteredMethods = filteredMethods.filter(method => {
            const amount = parseFloat(selectedAmount);
            const isIncluded = amount >= method.min;
            return isIncluded;
        });

        const orderedMethods = orderPaymentMethods(amountFilteredMethods);

        setPaymentMethods(orderedMethods);
    }, [
        isDebugMode,
        depositPaymentMethods?.data,
        nuveiEnabled,
        hasSavedNuveiMethods,
        enableInstantBankTransfers,
        enableDebitCardDeposit,
        showDepositIbt,
        showPayByBankWeb,
        selectedAmount,
        enablePayByBank,
        enableVenmo,
    ]);

    const paypalPaymentMethod = useMemo(() => {
        return paymentMethods.find(payment => payment.type === PaymentMethodTypes.PaysafePaypal);
    }, [paymentMethods]);

    const isPayPalPreferredMethod = useMemo(() => {
        return depositPaymentMethods?.preferred_payments?.some(
            payment => payment.type === PaymentMethodTypes.PaysafePaypal
        );
    }, [depositPaymentMethods]);

    const isVenmoPreferredMethod = useMemo(() => {
        return depositPaymentMethods?.preferred_payments?.some(
            payment => payment.type === PaymentMethodTypes.PaysafeVenmo
        );
    }, [depositPaymentMethods]);

    const paysafeDebit = paymentMethods.find(payment => payment.type === PaymentMethodTypes.Paysafe);
    // Determine whether to show Nuvei (Mazooma) payment methods
    const shouldShowNuveiMethod = (method: PaymentMethod): boolean => {
        if (isWeb) {
            // Web-specific logic
            // Show the option if nuvei is enabled and not hidden by showDepositIbt
            return nuveiEnabled && showDepositIbt;
        }
        // Always show saved Nuvei methods for existing users, regardless of other flags
        if (method.type === PaymentMethodTypes.PaysafeMazooma && hasSavedNuveiMethods) {
            return true;
        }

        // For new Nuvei methods, only show if both instant bank transfers are enabled and Nuvei is enabled
        if (method.type === PaymentMethodTypes.PaysafeMazooma) {
            return enableInstantBankTransfers && nuveiEnabled;
        }

        // For other method types, defer to the instant bank transfers flag
        return enableInstantBankTransfers;
    };

    // Determine whether to show the option to add new bank transfers
    const shouldShowAddBankTransfers = (): boolean => {
        if (isWeb) {
            // Web-specific logic
            // Show the option if nuvei is enabled and not hidden by showDepositIbt
            // Web does not use enableInstantBankTransfers flag because app logic is different
            return nuveiEnabled && showDepositIbt;
        } else {
            // Mobile-specific logic (iOS, Android)
            // On mobile, we don't use showDepositIbt flag
            if (enableInstantBankTransfers) {
                // If Nuvei is enabled, show for all users
                if (nuveiEnabled) {
                    return true;
                }
                // If Nuvei is disabled, only show for users who don't have saved Nuvei methods
                return !hasSavedNuveiMethods;
            }
        }
        return false;
    };

    const shouldShowAddPayByBank = (): boolean => {
        if (isWeb) {
            // Web-specific logic
            // Show the option if pay by bank is enabled and not hidden by hide_pay_by_bank_web
            return showPayByBankWeb;
        } else {
            return enablePayByBank ?? false;
        }
    };

    const shouldShowAddVenmo = (): boolean => {
        if (isWeb || isVenmoPreferredMethod) {
            return false;
        }

        return enableVenmo ?? false;
    };

    if (isInitialLoading) {
        return (
            <View style={[common.paddingVertical]} testID="loadingIndicator">
                <Loading />
            </View>
        );
    }

    return (
        <>
            {paymentMethods.map(method => {
                return (
                    <View key={method.type} testID={method.type}>
                        <PaymentMethodItem
                            item={method}
                            preferredPayments={depositPaymentMethods?.preferred_payments ?? []}
                            isSelected={selectedPaymentMethod?.type === method.type}
                            selectedPreferredItem={selectedPreferredMethod}
                            handleSelect={handleSelect}
                            showNuvei={shouldShowNuveiMethod(method)}
                        />
                    </View>
                );
            })}
            {enableDebitCardDeposit && paysafeDebit ? (
                <View testID="AddPaymentMethodDebitCard">
                    <AddPaymentMethodDebitCard
                        selectedAmount={selectedAmount}
                        paysafeAccountId={paysafeDebit.accountId}
                    />
                </View>
            ) : null}
            {shouldShowAddPayByBank() ? (
                <View testID="AddPaymentMethodPayByBank">
                    <AddPaymentMethodPayByBank selectedAmount={selectedAmount} />
                </View>
            ) : null}
            {!isPayPalPreferredMethod && paypalPaymentMethod ? (
                <View testID="AddPaymentMethodPayPal">
                    <AddPaymentMethodPayPal paymentMethod={paypalPaymentMethod} selectedAmount={selectedAmount} />
                </View>
            ) : null}
            {shouldShowAddBankTransfers() ? (
                <View testID="AddPaymentMethodBankTransfers">
                    <AddPaymentMethodBankTransfers selectedAmount={selectedAmount} />
                </View>
            ) : null}
            {shouldShowAddVenmo() ? (
                // This zIndex -1 is necessary for Android fix, otherwise it will prevent
                // the other methods to be selected
                <View style={isAndroid ? common.zIndexNegative1 : {}} testID="AddPaymentMethodVenmo">
                    <AddPaymentMethodVenmo selectedAmount={selectedAmount} />
                </View>
            ) : null}
        </>
    );
};
