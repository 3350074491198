import { useUser } from '@/hooks/use-user';
import { RequestPolicy } from 'urql';

/**
 * Provides the request policy to be used for GQL requests based on the user's status.
 * If the user is a guest, the policy will be 'cache-first' to avoid making too many requests.
 * If the user is not a guest, the policy will be 'cache-and-network' by default, or the one provided.
 *
 * @param strategy - the strategy to used for non-guest users, fallbacks to 'cache-and-network'
 * @returns - the request policy to be used
 */
export const useGQLRequestPolicy = (strategy?: RequestPolicy): RequestPolicy => {
    const { guest } = useUser();
    return guest ? 'cache-first' : strategy ?? 'cache-and-network';
};
