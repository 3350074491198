import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M2.38281 5.75631L4.64477 8.01826C4.84003 8.21352 5.1566 8.21352 5.35186 8.01827L9.62035 3.75"
            stroke={designSystem.colors.gray8}
            strokeWidth={1.5}
        />
    </Svg>
);
export default SVGComponent;
