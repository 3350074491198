import { useCallback } from 'react';

import { getWalletBalances } from '@/utils/fetch-wallet-balance';
import { Wallet } from '@/utils/fetch-wallet-balance';
import { logger } from '@/utils/logging';
import { create } from 'zustand';

import { Product, useJurisdictionStore } from './use-jurisdiction';
import { useResumeEffect } from './use-resume';
import { user } from './use-user';

type WalletStore = {
    balance: number;
    bonus: number;
    total: number;
    realMoneyTotal: number;
    betrBucks: number;
    walletLoading: boolean;
    wallet: null | Wallet;
    actions: {
        setBonus: (value: number) => void;
        loadUserWallet: () => Promise<void>;
        setupGuestWallet: (wallet: Wallet) => void;
    };
};

export const useWalletStore = create<WalletStore>()((set, get) => {
    return {
        balance: 0,
        bonus: 0,
        total: 0,
        realMoneyTotal: 0,
        betrBucks: 0,
        walletLoading: false,
        wallet: null,
        actions: {
            setBonus: (value: number) => set({ bonus: value }),
            loadUserWallet: async () => {
                if (get().walletLoading) {
                    return;
                }
                if (user.sessionHasExpired() || user.isGuest()) {
                    logger.debug('[wallet] No session or guest user, not loading wallet');
                    return;
                }
                set({ walletLoading: true });
                try {
                    const wallets = await getWalletBalances();
                    const wallet =
                        useJurisdictionStore.getState().product === Product.Pickem
                            ? wallets.fantasy_wallet
                            : wallets.wallet;
                    const total = Number(wallet.total);
                    const realMoneyTotal = Number(wallet.bonus_amount) + Number(wallet.real_amount);
                    const balance = Number(wallet.real_amount);
                    const bonus = Number(wallet.bonus_amount);
                    const betrBucks = Number(wallet.free_amount);
                    set({
                        wallet,
                        total,
                        realMoneyTotal,
                        balance,
                        bonus,
                        betrBucks,
                    });
                } catch (e) {
                    logger.error('[wallet] Error loading user wallet', e);
                }
                set({ walletLoading: false });
            },
            setupGuestWallet: (wallet: Wallet) => {
                set({
                    wallet,
                    total: Number(wallet.total),
                    realMoneyTotal: Number(wallet.bonus_amount) + Number(wallet.real_amount),
                    balance: Number(wallet.real_amount),
                    bonus: Number(wallet.bonus_amount),
                    betrBucks: Number(wallet.free_amount),
                });
            },
        },
    };
});

export const useWallet = () => {
    const store = useWalletStore();

    useResumeEffect(
        useCallback(() => {
            store.actions.loadUserWallet();
        }, [store.actions])
    );

    return store;
};
