import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsFocused } from '@react-navigation/native';

import { MarketsUnavailable } from '@/feature/league-hub-sbk/components/MarketsUnavailable';
import { LeagueEventListMarketLayout } from '@/feature/league-hub-sbk/components/market-layouts/LeagueEventListMarketLayout';
import { LoadingScreen } from '@/screens/LoadingScreen';

import { useLiveHubMarketData } from '../hooks/use-live-hub-market-data';

export const LiveLeagueMarket = ({
    leagueId,
    isTabBarVisible,
    headerHeight,
    tabKey,
}: {
    leagueId: string;
    isTabBarVisible: boolean;
    headerHeight: number;
    tabKey: string;
}) => {
    const { t } = useTranslation(['league_hub_sbk']);
    const isFocused = useIsFocused();

    const { league, gameLineMarketCategoryId, marketCategories, events, isInitialLoading, refetch } =
        useLiveHubMarketData(leagueId, isFocused);

    const sportName = league?.sport?.name ?? '';

    if (isInitialLoading) {
        return <LoadingScreen />;
    }

    const doesLeagueHaveGameLineMarketCategory = !!(!isInitialLoading && league && gameLineMarketCategoryId);

    const doesMarketCategoryHaveRecords = !!(!isInitialLoading && marketCategories && marketCategories.length > 0);

    if (!doesLeagueHaveGameLineMarketCategory || !doesMarketCategoryHaveRecords) {
        return (
            <MarketsUnavailable
                paddingHorizontal={16}
                offHeight={headerHeight}
                description={t('league_markets_unavailable_description')}
            />
        );
    }

    return (
        <LeagueEventListMarketLayout
            events={events}
            leagueId={leagueId}
            marketCategories={marketCategories}
            sportName={sportName}
            refetch={refetch}
            tabKey={tabKey}
            isTabBarVisible={isTabBarVisible}
            hasGameSection={false}
        />
    );
};
