import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, StyleSheet } from 'react-native';

import { useIsFocused } from '@react-navigation/native';

import { BottomFooterRG } from '@/components/FooterRG';
import { LineSeparator } from '@/components/LineSeparator';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components/Box';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { FeaturedBetCard } from '@/feature/lobby-sbk/components/FeaturedBets/CardEvent/FeaturedBetCard';
import { FeaturedBet } from '@/feature/lobby-sbk/components/FeaturedBets/types';
import { useFeaturedBets } from '@/feature/lobby-sbk/hooks/use-featured-bets';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { LoadingScreen } from '@/screens/LoadingScreen';
import { common, designSystem } from '@/styles/styles';

const ItemSeparator = () => <SizedBox value={16} />;

export const FeaturedHubScreen = () => {
    const { t } = useTranslation('bets');

    const isFocused = useIsFocused();
    const { data: featuredBets, refetch, isInitialLoading } = useFeaturedBets({ limit: 20 }, isFocused);
    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);
    useFetchOnResume(refetch);

    const renderCardBodyCallback = useCallback(({ item }: { item: FeaturedBet }) => {
        return <FeaturedBetCard key={item.id} featuredBet={item} />;
    }, []);

    if (isInitialLoading) {
        return <LoadingScreen />;
    }

    return (
        <Screen>
            <ScreenNavBar title={t('featured_bets')} />
            <LineSeparator style={common.hairlineHeight} />
            <Box paddingHorizontal="s16">
                <FlatList
                    contentContainerStyle={styles.list}
                    keyExtractor={featuredBet => `${featuredBet.id}`}
                    data={featuredBets}
                    renderItem={renderCardBodyCallback}
                    ItemSeparatorComponent={ItemSeparator}
                    refreshControl={
                        <RefreshControl
                            enabled
                            onRefresh={onRefresh}
                            refreshing={forceRefreshing}
                            colors={[designSystem.colors.white]}
                            tintColor={designSystem.colors.white}
                        />
                    }
                    ListFooterComponent={<BottomFooterRG footerMarginTop="s64" />}
                />
            </Box>
        </Screen>
    );
};

const styles = StyleSheet.create({
    list: {
        paddingBottom: 64,
        paddingTop: 16,
    },
});
