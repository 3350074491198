import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

import DatePicker from './DatePicker';

type DobPickerProps = {
    date: Date;
    title: string;
    showDobPicker?: boolean;
    setShowDobPicker?: (show: boolean) => void;
    handleDatePickerChange?: (value: Date) => void;
};

export const DobPicker = ({ date, title, showDobPicker, setShowDobPicker, handleDatePickerChange }: DobPickerProps) => {
    const { t } = useTranslation(['common']);
    const slideAnim = useRef(new Animated.Value(300)).current;

    useEffect(() => {
        if (showDobPicker) {
            Animated.timing(slideAnim, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true,
            }).start();
        } else {
            Animated.timing(slideAnim, {
                toValue: 300,
                duration: 300,
                useNativeDriver: true,
            }).start();
        }
    }, [showDobPicker, slideAnim]);

    const handleOverlayPress = () => {
        setShowDobPicker?.(false);
    };

    if (!showDobPicker) {
        return null;
    }

    return (
        <>
            <TouchableWithoutFeedback onPress={handleOverlayPress}>
                <View style={styles.overlay} />
            </TouchableWithoutFeedback>
            <Animated.View
                style={[
                    styles.datePickerContainer,
                    {
                        transform: [{ translateY: slideAnim }],
                    },
                ]}
            >
                <Box flexDirection="row" justifyContent="flex-end" paddingVertical="s12" paddingHorizontal="s12">
                    <Text textAlign="right" onPress={() => setShowDobPicker?.(false)}>
                        {t('common:done')}
                    </Text>
                </Box>
                <Box flexDirection="row" justifyContent="center">
                    <DatePicker
                        modal={false}
                        open={showDobPicker}
                        date={date}
                        title={title}
                        mode="date"
                        theme="dark"
                        onDateChange={handleDatePickerChange}
                    />
                </Box>
            </Animated.View>
        </>
    );
};

const styles = StyleSheet.create({
    datePickerContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: designSystem.colors.gray5,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: -2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 5,
        zIndex: 1,
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});
