import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path d="M1.5 6H10.5" stroke={designSystem.colors.gray8} strokeWidth={1.5} />
    </Svg>
);
export default SVGComponent;
