import { useEffect, useState } from 'react';
import { RESULTS, checkNotifications } from 'react-native-permissions';

import { read } from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

const LOG_TAG = '[PushPrimer]: ';

// Local storage keys for Push Primer
export const PUSH_PRIMER_SKIP_COUNT = 'PushPrimer_SkipCount';
export const PUSH_PRIMER_IGNORE_PERIOD = 'PushPrimer_IgnorePeriod';

/**
 * Checks if the user is eligible to see the Push Primer screen.
 *
 * - If the platform is web, the user is not eligible.
 * - If notifications are already granted, the user is not eligible.
 * - If notifications are not granted and the user has never skipped the Push Primer screen (skipCount is false), the user is eligible.
 * - If notifications are not granted and the user has skipped the Push Primer screen before, but the ignore period has passed, the user is eligible.
 *
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the user is eligible to see the Push Primer screen.
 */
const checkPushPrimerEligibility = async (): Promise<boolean> => {
    if (isWeb) {
        return false;
    }

    const { status } = await checkNotifications();
    if (status === RESULTS.GRANTED) {
        return false;
    }

    const skipCount = Number((await read(PUSH_PRIMER_SKIP_COUNT)) || 0);
    const storedIgnorePeriod = await read(PUSH_PRIMER_IGNORE_PERIOD);

    // When PushPrimer screen is called, PushPrimer_SkipCount is set
    if (!skipCount) {
        return true;
    }
    if (storedIgnorePeriod) {
        try {
            const savedIgnoreUntil = new Date(storedIgnorePeriod).getTime();
            if (Date.now() > savedIgnoreUntil) {
                return true;
            }
        } catch (e) {
            logger.error(LOG_TAG, 'Invalid storedIgnorePeriod:', storedIgnorePeriod, e);
        }
    }
    return false;
};

export const usePushPrimerEligibility = () => {
    const [pushPrimerEligibility, setPushPrimerEligibility] = useState<boolean>(false);

    useEffect(() => {
        const fn = async () => {
            const eligibility = await checkPushPrimerEligibility();
            setPushPrimerEligibility(eligibility);
        };
        fn();
    });

    return pushPrimerEligibility;
};
