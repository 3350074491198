import { useMemo } from 'react';

import { GameMode, ProjectionType } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';

import { sortEntryRules } from '../utils/betslip-utils';
import { getGameModeError } from '../utils/game-mode-utils';
import { useBetslipStore } from './use-betslip-store';
import { useEntryRules } from './use-entry-rules';

export const useBetslipData = (_tag?: string) => {
    const plays = useBetslipStore(state => state.plays);

    const selectedGameModes = useMemo(() => {
        // a game is selected if it has a non-zero amount
        return Object.entries(plays)
            .filter(([_key, value]) => !!value.amount)
            .map(([key]) => key as GameMode);
    }, [plays]);

    const betslip = useBetslipStore(state => state.betslip, defaultZustandCompareFunction);

    const validationData = useBetslipStore(state => state.validationData);

    const { allEntryRules, canPickBoosted, canPickRegular, canPickSpecial } = useEntryRules();

    const dynamicMultipliers = {
        DYNAMIC:
            validationData.DYNAMIC?.betValidation?.dynamicMultipliers?.filter(
                it => it.numberOfPicks === betslip.length
            ) ?? [],
    } as const;

    const boostedPicks = betslip.filter(pick => pick.projection.type === ProjectionType.Boosted);
    const hasBoostedLineup = boostedPicks.length > 0;

    const dynamicBoostedMultiplier = validationData.DYNAMIC.betValidation?.boostedMultipliers?.find(
        it => it.numberOfPicks === betslip.length && it.numberOfBoostedPicks === boostedPicks.length
    );

    const perfectRegularMultiplier = validationData.PERFECT.betValidation?.perfectRegularMultiplier;
    const perfectBoostedMultiplier = validationData.PERFECT.betValidation?.perfectBoostedMultiplier;
    const perfectActiveMultiplier = hasBoostedLineup ? perfectBoostedMultiplier : perfectRegularMultiplier;

    const validPicks = useMemo(() => {
        if (betslip.length === 0) {
            return false;
        }

        // go through each game mode and check if it has any INVALID_CRITERIA errors
        // we should look into data with betValidation - this contains the errors
        const availableBetValidations = Object.values(validationData).filter(data => !!data.betValidation);
        const validModes = availableBetValidations.filter(
            data => (data.betValidation?.errors ?? []).filter(it => it.key === 'INVALID_CRITERIA').length === 0
        );

        // if at least 1 game mode is valid, the betslip is valid
        return validModes.length > 0;
    }, [betslip, validationData]);

    const { error: dynamicModeError, disabled: dynamicModeDisabled } = getGameModeError(
        betslip,
        allEntryRules,
        validationData.DYNAMIC.betValidation
    );

    const { error: perfectModeError, disabled: perfectModeDisabled } = getGameModeError(
        betslip,
        allEntryRules,
        validationData.PERFECT.betValidation
    );

    const sortedEntryRules = useMemo(
        () => sortEntryRules(allEntryRules, dynamicModeDisabled, betslip.length),
        [allEntryRules, betslip.length, dynamicModeDisabled]
    );

    const edgeCombos =
        validationData.PERFECT.betValidation?.edgeCombos ?? validationData.DYNAMIC.betValidation?.edgeCombos ?? [];

    return {
        betslip,
        dynamicMultipliers,
        /**
         * `true` if the user can still add picks to the lineup
         */
        canPick: canPickBoosted || canPickRegular || canPickSpecial,
        canPickBoosted,
        canPickRegular,
        canPickSpecial,
        /**
         * `true` if the lineup has boosted picks, `false` otherwise
         */
        hasBoostedLineup,
        /**
         * The created edge combos for the lineup, as returned by `validatePicks`
         */
        edgeCombos,
        /**
         * `true` if the lineup has valid picks, and at least 1 game mode is available without errros, `false` otherwise
         */
        validPicks,
        /**
         * `true` if dynamic mode can't be selected due to errors, `false` otherwise
         */
        dynamicModeDisabled,
        dynamicModeError,
        dynamicBoostedMultiplier,
        /**
         * The multiplier for perfect mode without boosted picks, for the current number of picks
         */
        perfectRegularMultiplier,
        /**
         * The multiplier for perfect mode with boosted picks, for the current number of picks
         */
        perfectBoostedMultiplier,
        /**
         * If the lineup has boosted picks, this is the boosted multiplier, otherwise this is the regular multiplier
         */
        perfectActiveMultiplier,
        /**
         * `true` if perfect mode can't be selected due to errors, `false` otherwise
         */
        perfectModeDisabled,
        perfectModeError,
        selectedGameModes,
        sortedEntryRules,
    };
};
