import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetSummaryProps, BetSummarySection, BetSummarySectionProps } from '@/components/BetSummarySection';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useBetDetailsVoidedBetSummary, useSharedBetDetailsBetSummary } from './utils';

/**
 * Helper Hooks
 */
const useBetDetailsOpenSingleBetSummary = (props: BetSummaryProps): BetSummarySectionProps => {
    const { bet } = props;
    return {
        ...useSharedBetDetailsBetSummary(props),
        body3Text: toLocaleCurrency(bet.potential_payout),
    };
};

const useBetDetailsSettledSingleBetSummary = (props: BetSummaryProps): BetSummarySectionProps => {
    const { bet } = props;
    const { t } = useTranslation('bets');
    return {
        ...useSharedBetDetailsBetSummary(props),
        header3Text: t('paid'),
        header3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
        body3Text: toLocaleCurrency(bet.amount_won),
        body3TextColor: bet.amount_won > 0 ? 'green' : 'gray2',
    };
};

/**
 * Components
 */
const OpenSingleBetSummary = (props: BetSummaryProps) => (
    <BetSummarySection {...useBetDetailsOpenSingleBetSummary(props)} />
);

const VoidedSingleBetSummary = (props: BetSummaryProps) => (
    <BetSummarySection {...useBetDetailsVoidedBetSummary(props)} />
);

const SettledSingleBetSummary = (props: BetSummaryProps) => (
    <BetSummarySection {...useBetDetailsSettledSingleBetSummary(props)} />
);

export const SingleBetSummarySection = (props: BetSummaryProps) => {
    const { bet } = props;
    switch (bet.result) {
        case 'PENDING':
            return <OpenSingleBetSummary {...props} />;
        case 'WON':
        case 'LOST':
        case 'CASHEDOUT':
            return <SettledSingleBetSummary {...props} />;
        case 'CANCELLED':
        case 'PUSHED':
            return <VoidedSingleBetSummary {...props} />;
        default:
            return <OpenSingleBetSummary {...props} />;
    }
};
