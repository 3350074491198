import React, { useCallback, useMemo } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import { useNavigation, useRoute } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { Loading } from '@/components/Loading';
import { SizedBox } from '@/components/SizedBox';
import { useStickyTabList } from '@/components/StickyTabsProvider';
import { Box } from '@/components/lib/components';
import EntryItem, { EntryItemType } from '@/feature/entries-pickem/components/EntryItem';
import { useInfiniteFilteredEntries } from '@/feature/entries-pickem/hooks/use-infinite-filtered-entries';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useResponsiveColumnCount } from '@/feature/responsive-design/hooks/use-responsive-column-count';
import { useResumeEffect } from '@/hooks/use-resume';
import { PickemEntriesTabNavigationProp } from '@/navigation/pickem/types';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import EntriesEmptyList from '../components/EntriesEmptyList';
import { EntriesTabParamsList } from '../screens/EntriesTopTabBarScreen';

const styles = StyleSheet.create({
    columns: { gap: 10 },
    container: { paddingHorizontal: 16 },
});

const keyExtractor = ({ id }: EntryItemType) => id;

export type EntriesListParamList = StackScreenProps<EntriesTabParamsList, 'Entries-OPEN'>;

export const EntriesList = () => {
    const navigation = useNavigation<PickemEntriesTabNavigationProp>();
    const columnCount = useResponsiveColumnCount([1, 1, 2, 3, 4]);

    const {
        params: { currentSection },
    } = useRoute<EntriesListParamList['route']>();

    const { data, isInitialLoading, isFetching, fetchNextPage, refetch } = useInfiniteFilteredEntries({
        result: currentSection.result,
        status: currentSection.status,
    });

    const entries = useMemo(
        () =>
            (data?.pages ?? [])
                .map(page => page.content)
                .filter(Boolean)
                .flat(),
        [data?.pages]
    );

    const navigatePickemHome = useCallback(() => {
        navigation.navigate('Lobby', { screen: 'LobbyScreen' });
    }, [navigation]);

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);

    const silentRefresh = useCallback(() => {
        refetch();
    }, [refetch]);

    const { scrollableProps, setStickyRef } = useStickyTabList(currentSection.label, forceRefreshing, onRefresh, true);

    useResumeEffect(silentRefresh);

    const lastFetchedPageIndex = data?.pages?.length || 0;
    const totalPages = data?.pages?.[data?.pages.length - 1]?.totalPages || 0;

    return (
        <Box flex={1}>
            {isInitialLoading ? (
                <Box flex={1} justifyContent={'center'}>
                    <ActivityIndicator />
                </Box>
            ) : (
                <Animated.FlatList<EntryItemType>
                    scrollEventThrottle={16}
                    key={`${columnCount}`}
                    ListEmptyComponent={
                        <EntriesEmptyList currentSection={currentSection} navigateHome={navigatePickemHome} />
                    }
                    numColumns={columnCount}
                    data={entries}
                    keyExtractor={keyExtractor}
                    ItemSeparatorComponent={ItemSeparator}
                    columnWrapperStyle={columnCount !== 1 ? styles.columns : undefined}
                    renderItem={({ item }) => <EntryItem item={item} />}
                    onEndReachedThreshold={1.5}
                    onEndReached={() => !isFetching && fetchNextPage()}
                    showsVerticalScrollIndicator={showScrollIndicator}
                    ListHeaderComponent={ItemSeparator}
                    ListFooterComponent={
                        isFetching && lastFetchedPageIndex < totalPages ? ListFooterComponent : ItemSeparator
                    }
                    {...scrollableProps}
                    contentContainerStyle={[scrollableProps.contentContainerStyle, styles.container]}
                    ref={setStickyRef}
                />
            )}
        </Box>
    );
};

const ItemSeparator = () => {
    return <SizedBox value={16} />;
};

const ListFooterComponent = () => (
    <Box paddingBottom={'s12'} paddingTop={'s24'}>
        <Loading />
    </Box>
);
