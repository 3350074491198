import React from 'react';

import { BetSummaryProps } from '@/components/BetSummarySection';

import { MultiBetSummarySection } from './MultiBetSummarySection';
import { SingleBetSummarySection } from './SingleBetSummarySection';

export const BetSummarySection = (props: BetSummaryProps) => {
    const { bet } = props;
    switch (bet.bet_type) {
        case 'SINGLE':
            return <SingleBetSummarySection {...props} />;
        case 'SCRIPT':
        case 'COMBO':
            return <MultiBetSummarySection {...props} />;
    }
};
