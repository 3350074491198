import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, Platform, StyleSheet, View } from 'react-native';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { ErrorMessagePayment } from '@/components/ErrorMessagePayment';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { NumberPad } from '@/components/NumberPad';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { URLS } from '@/data';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { activeLimitsSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useCurrencyNumberPad } from '@/hooks/use-currency-number-pad';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { standardValue } from '@/utils/constants';

import { DepositButton } from '../components/DepositButton';
import { DepositNumberSection } from '../components/DepositNumberSection';
import { PaymentMethods } from '../components/PaymentMethods';
import { PromotionalBanner } from '../components/PromotionalBanner';
import { QuickDepositButtonsSection } from '../components/QuickDepositButtonsSection';
import { PaymentMethodTypes } from '../const';
import { PaymentMethod, PreferredPayment } from '../hooks/use-deposit-payment-methods';
import { useUserSignupPromoDetail } from '../hooks/use-get-promo-codes';
import { usePrefilledAmounts } from '../hooks/use-prefilled-amounts';
import { getDepositErrorMessage } from '../utils';

const styles = StyleSheet.create({
    loaderContainer: { backgroundColor: designSystem.colors.gray8 },
});

const CTA_BUTTON_HEIGHT = 76;

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'DepositScreen'>;

export const DepositScreen = ({ route }: ScreenProps) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['wallet', 'common']);

    const product = useJurisdictionStore(state => state.product);
    const { settings, ftdSetting } = useJurisdictionStore(state => ({
        settings: state.jurisdictionSettings?.globalSettings?.keyValuePairs,
        ftdSetting: state.jurisdictionSettings?.globalSettings?.ftdSetting,
    }));

    const depositLimit = settings?.TransactionSettings?.single_deposit_limit || '50000';
    const minimumDeposit: string = settings?.TransactionSettings?.minimum_deposit || '5';

    const { data: hasMadeFirstDeposit } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });

    const [isEditing, setIsEditing] = useState(true);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
    const [selectedPreferredMethod, setSelectedPreferredMethod] = useState<PreferredPayment>();
    const [errorMessage, setErrorMessage] = useState('');
    const [numPadHeight, setNumPadHeight] = useState<number>(0);
    const { bottom: safeAreaInsetBottom } = useSafeAreaInsets();

    const isOnboarding = Boolean(!hasMadeFirstDeposit && route?.params?.isRedirectedFromVerificationSuccess);
    const showPromotionalBanner = Boolean(
        isEditing && hasMadeFirstDeposit !== undefined && !hasMadeFirstDeposit && ftdSetting
    );

    const onLayout = (event: LayoutChangeEvent): void => {
        const { height } = event.nativeEvent.layout;

        if (height > numPadHeight) {
            setNumPadHeight(height + safeAreaInsetBottom + CTA_BUTTON_HEIGHT);
        }
    };

    const { data: depositLimitsData, isLoading } = useUserLimits({ select: activeLimitsSelector('DEPOSIT') });
    const prefilledAmount = usePrefilledAmounts();
    const { displayValue, number, onNumberPress, onDecimalPress, onBackspacePress, setInputValue } =
        useCurrencyNumberPad(Number(route?.params?.selectedAmount));

    useEffect(() => {
        if (prefilledAmount) {
            setInputValue(prefilledAmount);
        }
    }, [setInputValue, prefilledAmount]);

    useEffect(() => {
        if (number !== 0) {
            const message = getDepositErrorMessage(
                parseFloat(depositLimit),
                number.toString(),
                depositLimitsData,
                minimumDeposit
            );
            setErrorMessage(message);
        }
    }, [displayValue, depositLimitsData, number, depositLimit, minimumDeposit]);

    const promoCodeDetail = useUserSignupPromoDetail();

    const getAnalyticsEvent = (method: PaymentMethod) => {
        switch (method.type) {
            case PaymentMethodTypes.Paysafe:
                return AnalyticsEvent.DEPOSIT_METHOD_SAVED_DEBIT;
            case PaymentMethodTypes.PaysafeMazooma:
                return AnalyticsEvent.DEPOSIT_METHOD_SAVED_IBT;
            case PaymentMethodTypes.PaysafePaypal:
                return AnalyticsEvent.DEPOSIT_METHOD_PAYPAL;
            case PaymentMethodTypes.PaysafeVenmo:
                return AnalyticsEvent.DEPOSIT_METHOD_VENMO;
        }
    };

    const handleSelect = (method: PaymentMethod, preferredPaymentMethod?: PreferredPayment) => {
        const event = getAnalyticsEvent(method);
        if (event) {
            BetrAnalytics.trackProductEvent(event);
        }

        setSelectedPaymentMethod(method);
        setSelectedPreferredMethod(preferredPaymentMethod);
    };

    const quickDepositValues = settings?.QuickDepositButtonsFantasy;
    const handleOnSkip = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.FTD_SKIP);
        navigation.navigate('ProductTransitionScreen', { product });
    };

    const pbToolbar = isEditing ? 0 : safeAreaInsetBottom + standardValue;
    const showQuickButtons = !!quickDepositValues && isEditing;
    const bannerMessage = promoCodeDetail ?? `${ftdSetting?.ftd_banner_heading} ${ftdSetting?.ftd_banner_body}`;
    const rightIcon = isOnboarding ? (
        <TouchableOpacity onPress={handleOnSkip}>
            <Text variant="titleLarge">{t('common:skip')}</Text>
        </TouchableOpacity>
    ) : null;

    if (isLoading) {
        return (
            <View style={[common.flex, styles.loaderContainer, common.justifyCenter]}>
                <LoadingSpinner />
            </View>
        );
    }

    return (
        <Screen edges={['bottom', 'top']}>
            <ScreenNavBar
                title={t('wallet:deposit_funds')}
                rightIcon={rightIcon}
                closeIconMode={isOnboarding ? 'none' : 'close'}
                handlePressTracking={() => {
                    const event = isEditing ? AnalyticsEvent.DEPOSIT_ABORT : AnalyticsEvent.DEPOSIT_METHOD_ABORT;
                    BetrAnalytics.trackProductEvent(event);
                }}
            />
            <ScrollView contentContainerStyle={[common.grow, common.justifyCenter]}>
                <MaxWidthWrapper>
                    <DepositNumberSection
                        {...{
                            isEditing,
                            setIsEditing,
                            errorMessage,
                            displayValue,
                        }}
                        quickDepositValuesComponent={
                            showQuickButtons ? (
                                <QuickDepositButtonsSection
                                    selectedValue={number.toString()}
                                    setInputValue={setInputValue}
                                    quickDepositValues={quickDepositValues}
                                />
                            ) : null
                        }
                    />

                    {!isEditing ? (
                        <ScrollView>
                            <PaymentMethods
                                {...{
                                    handleSelect,
                                    selectedAmount: number.toString(),
                                    selectedPaymentMethod,
                                    selectedPreferredMethod,
                                }}
                            />
                        </ScrollView>
                    ) : null}
                    {!isEditing && selectedPreferredMethod?.is_expired ? (
                        <Box padding="s16">
                            <Text
                                color="gray2"
                                variant="bodySmall"
                                textDecorationLine="underline"
                                onPress={() => {
                                    navigation.navigate('ModalWebView', {
                                        uri: URLS.HOW_TO_UPDATE_DEBIT_CARD_URL,
                                    });
                                }}
                            >
                                {t('wallet:how_do_i_update_card')}
                            </Text>
                        </Box>
                    ) : null}
                </MaxWidthWrapper>
            </ScrollView>
            <MaxWidthWrapper>
                <NumberPad
                    relativePosition={isEditing}
                    isEditing={isEditing}
                    customTransitionDelay={200}
                    showKeyboard={Platform.OS === 'android' ? isEditing : true}
                    toolbar={
                        errorMessage || isEditing ? (
                            <Box style={{ paddingBottom: pbToolbar }}>
                                <ErrorMessagePayment errorMessage={errorMessage} />
                                <PromotionalBanner isVisible={showPromotionalBanner} message={bannerMessage} />
                            </Box>
                        ) : undefined
                    }
                    hasBottomSafeArea={false}
                    onLayout={onLayout}
                    height={numPadHeight}
                    {...{ onNumberPress, onDecimalPress, onBackspacePress }}
                />

                <Box p="s16" justifyContent={'center'} height={CTA_BUTTON_HEIGHT}>
                    <DepositButton
                        {...{
                            isEditing,
                            setIsEditing,
                            errorMessage,
                            hasMadeFirstDeposit,
                            selectedAmount: number.toString(),
                            selectedPaymentMethod,
                            selectedPreferredMethod,
                        }}
                    />
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};
