import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

export const CloseIcon = props => (
    <Svg
        width={props.width ?? '24'}
        height={props.height ?? '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        testID="closeIcon"
        {...props}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.585 12L4.22107 5.63602L5.63528 4.2218L11.9992 10.5858L18.3632 4.2218L19.7774 5.63602L13.4135 12L19.7774 18.3639L18.3632 19.7782L11.9992 13.4142L5.63528 19.7782L4.22107 18.3639L10.585 12Z"
            fill={props.color ?? designSystem.colors.gray1}
        />
    </Svg>
);
