import React from 'react';

import { Box, BoxProps, Column, Row } from '@/components/lib/components';
import { MarketName } from '@/feature/event-details-sbk/components/MarketName';
import { OptionName, OptionNameProps } from '@/feature/event-details-sbk/components/OptionName';

import { BetEvent, BetSelection } from '../../hooks/types';
import { BetSelectionIcon } from './BetSelectionIcon';
import { BetSelectionVoidedInfo } from './BetSelectionVoidedInfo';
import { betSelectionItemStyle } from './Styles';

const getBetSelectionItemStyles = (variant: 's' | 'l') => {
    const { s, l } = betSelectionItemStyle;
    return {
        firstVerticalBarStyles: variant === 's' ? s.firstVerticalBarStyles : l.firstVerticalBarStyles,
        betInfoColumnStyles: variant === 's' ? s.betInfoColumnStyles : l.betInfoColumnStyles,
        outerColumnStyles: variant === 's' ? s.outerColumnStyles : l.outerColumnStyles,
        thirdVerticalBarContainerStyles:
            variant === 's' ? s.thirdVerticalBarContainerStyles : l.thirdVerticalBarContainerStyles,
    };
};

type BetSelectionItemProps = {
    isFirstLeg: boolean;
    isLastLeg: boolean;
    isLastEvent: boolean;
    selection: BetSelection;
    event: BetEvent;
    isCashedOut: boolean;
    variant: 's' | 'l';
};

export const BetSelectionItem = ({
    isFirstLeg,
    isLastLeg,
    isLastEvent,
    selection,
    isCashedOut,
    event,
    variant,
}: BetSelectionItemProps) => {
    const voidedResults = ['CANCELLED', 'PUSHED'];
    const isVoided = voidedResults.includes(selection.result);
    const isSelectionCashedOut = !selection.result && isCashedOut;
    const isInactive = isVoided || isSelectionCashedOut;

    const styles = getBetSelectionItemStyles(variant);

    const showVoidedInfo = variant === 'l' && isInactive;
    const isLastItem = isLastLeg && isLastEvent;

    const marketNameProps = {
        event: event,
        sportName: event.sport.name,
        marketType: selection.market.market_type,
        fallback: selection.market.description,
        player: selection.market.player,
        appendPlayerName: true,
        variant: 'bodySmall',
        color: isInactive ? 'gray4' : 'gray2',
        textDecorationLine: isInactive ? 'line-through' : 'none',
        appendMicroMarketDescription: selection.market.is_micro_market,
        appendTeamName: !selection.market.is_micro_market,
        isExtendedName: true,
        testID: 'betCardMarketName',
    } as const;

    const optionNameProps: OptionNameProps = {
        optionType: selection.option.option_type,
        marketType: selection.market.market_type,
        homeTeam: event.home_team,
        awayTeam: event.away_team,
        fallback: selection.option.description,
        sportName: event.sport.name,
        variant: variant === 'l' ? 'titleMedium' : 'titleSmall',
        color: isInactive ? 'gray4' : 'gray1',
        textDecorationLine: isInactive ? 'line-through' : 'none',
        testID: 'betCardSelectionName',
    };

    return (
        <>
            <Row>
                <Column
                    pl={styles.outerColumnStyles.paddingLeft}
                    pr={'s16'}
                    pt={isFirstLeg ? 's4' : 's0'}
                    alignItems="center"
                >
                    <VerticalBar
                        height={styles.firstVerticalBarStyles.height}
                        borderTopLeftRadius={isFirstLeg ? 'r2' : undefined}
                        borderTopRightRadius={isFirstLeg ? 'r2' : undefined}
                        borderBottomLeftRadius={'r2'}
                        borderBottomRightRadius={'r2'}
                    />
                    <Box my="s4">
                        <BetSelectionIcon
                            variant={variant}
                            result={isSelectionCashedOut ? 'CASHEDOUT' : selection.result}
                        />
                    </Box>
                    {!isLastItem ? (
                        <VerticalBar
                            flexGrow={1}
                            borderTopLeftRadius={'r2'}
                            borderTopRightRadius={'r2'}
                            borderBottomLeftRadius={undefined}
                            borderBottomRightRadius={undefined}
                        />
                    ) : null}
                </Column>
                <Column
                    pt={
                        isFirstLeg
                            ? styles.betInfoColumnStyles.firstLeg.paddingTop
                            : styles.betInfoColumnStyles.notFirstLeg.paddingTop
                    }
                    flex={1}
                >
                    <Row>
                        {showVoidedInfo ? (
                            <BetSelectionVoidedInfo selection={selection} isCashedOut={isCashedOut} />
                        ) : (
                            <OptionName {...optionNameProps} />
                        )}
                    </Row>
                    <Box>
                        {showVoidedInfo ? (
                            <>
                                <OptionName {...optionNameProps} />
                                <MarketName {...marketNameProps} />
                            </>
                        ) : (
                            <MarketName {...marketNameProps} />
                        )}
                    </Box>
                </Column>
            </Row>

            {/** End connector **/}
            {isLastLeg && !isLastEvent ? (
                <Row>
                    <Column
                        height={styles.thirdVerticalBarContainerStyles.height}
                        pb="s4"
                        style={{ paddingLeft: styles.thirdVerticalBarContainerStyles.paddingLeft }}
                    >
                        <VerticalBar
                            height="100%"
                            borderTopLeftRadius={undefined}
                            borderTopRightRadius={undefined}
                            borderBottomLeftRadius={'r2'}
                            borderBottomRightRadius={'r2'}
                        />
                    </Column>
                </Row>
            ) : null}
        </>
    );
};

const VerticalBar = ({
    height,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    flexGrow,
}: {
    height?: BoxProps['height'];
    borderTopLeftRadius: BoxProps['borderTopLeftRadius'];
    borderTopRightRadius: BoxProps['borderTopRightRadius'];
    borderBottomLeftRadius: BoxProps['borderBottomLeftRadius'];
    borderBottomRightRadius: BoxProps['borderBottomRightRadius'];
    flexGrow?: number;
}) => {
    return (
        <Box
            backgroundColor="gray6"
            width={2}
            height={height}
            borderTopLeftRadius={borderTopLeftRadius}
            borderTopRightRadius={borderTopRightRadius}
            borderBottomLeftRadius={borderBottomLeftRadius}
            borderBottomRightRadius={borderBottomRightRadius}
            flexGrow={flexGrow}
        />
    );
};
