import { URLS, createJurisdictionHeaders } from '@/data';
import { user } from '@/hooks/use-user';
import { ErrorResponse } from '@/utils/errors/response-handler';
import { WalletSchema } from '@/utils/fetch-wallet-balance';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// can add all the auth user config error_codes here.
type AuthUserConfigErrorCodes = 'user_account_block';

export type AuthUserConfigErrorType = ErrorResponse<AuthUserConfigErrorCodes>;

export enum DueDiligence {
    EDD_SUCCESS = 'EDD Success',
    EDD_FAILED = 'EDD Failed',
    CDD = 'CDD',
}

export enum DueDiligenceReasons {
    MANDATORY = 'Mandatory',
    PASSIVE = 'Passive',
    UNKNOWN_SOURCE = 'Unknown Source',
}

export type DueDiligenceType = DueDiligence | null;
export type DueDiligenceReasonType = DueDiligenceReasons | null;

const AuthUserConfigSchema = z.object({
    data: z.object({
        config: z.object({
            USER_KYC: z.boolean(),
            HAS_USER_DEPOSITED: z.boolean(),
            FAILED_KYC_COUNT: z.number(),
            CAN_DEPOSIT: z.boolean().optional(),
            CAN_WITHDRAW: z.boolean().optional(),
            IS_BIOMETRIC_ENABLED: z.boolean(),
            GLOBAL_USER_COHORT: z.string(),
            GLOBAL_USER_EMAIL: z.string(),
            GLOBAL_USER_ID: z.string(),
            USER_DUE_DILIGENCE: z.nativeEnum(DueDiligence).nullable(),
            USER_DUE_DILIGENCE_REASON: z.nativeEnum(DueDiligenceReasons).nullable(),
            IDPV_ALLOWED_IN_KYC: z.boolean(),
            FORCE_IDPV: z.boolean().optional(),
            APPSFLYER_ID: z.string().nullable().optional(),
            SITE_ID: z.number().nullable().optional(),
            IS_MFA_ENABLED: z.boolean().optional(),
            USER_IS_SELF_EXCLUDED: z.boolean().optional(),
            // TODO: add additional fields when needed
        }),
    }),
    wallet: WalletSchema,
    fantasy_wallet: WalletSchema,
});
type AuthUserConfig = z.infer<typeof AuthUserConfigSchema>;

/**
 * Query function for fetching authenticated user config
 * GET /auth/user/config
 */
export const getAuthUserConfig = async () => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/config`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return AuthUserConfigSchema.parse(data);
};

export const authUserKeys = {
    all: ['auth-user'] as const,
    config: () => [...authUserKeys.all, 'config'] as const,
    info: () => [...authUserKeys.all, 'info'] as const,
    settings: () => [...authUserKeys.all, 'settings'] as const,
};

type UserAuthUserConfigQueryOptions<TData> = UseQueryOptions<
    AuthUserConfig,
    unknown,
    TData,
    ReturnType<typeof authUserKeys.config>
>;

/**
 * Custom react-query hook for fetching authenticated user config
 * @param options - react-query options object
 */
export const useAuthUserConfig = <TData = AuthUserConfig>(options?: UserAuthUserConfigQueryOptions<TData>) => {
    return useQuery(authUserKeys.config(), getAuthUserConfig, {
        enabled: !user.sessionHasExpired(),
        staleTime: 1000 * 60 * 3,
        ...options,
    });
};

/**
 * Selector for checking if the user is KYC verified
 * Selector for checking if the user has deposited
 * Selector for user lifetime's KYC failures
 * @param resp - getAuthUserConfig response
 */
export const kycVerifiedSelector = (resp: AuthUserConfig) => resp.data.config.USER_KYC;
export const hasMadeFirstDepositSelector = (resp: AuthUserConfig) => resp.data.config.HAS_USER_DEPOSITED;
export const isBiometricEnabledSelector = (resp: AuthUserConfig) => resp.data.config.IS_BIOMETRIC_ENABLED;
export const failedKycCountSelector = (resp: AuthUserConfig) => resp.data.config.FAILED_KYC_COUNT;
export const canWithdrawSelector = (resp: AuthUserConfig) => resp.data.config.CAN_WITHDRAW;
export const userIdSelector = (resp: AuthUserConfig) => resp.data.config.GLOBAL_USER_ID;
export const userDueDiligenceSelector = (resp: AuthUserConfig) => resp.data.config.USER_DUE_DILIGENCE;
export const isIdpvAllowedInKycSelector = (resp: AuthUserConfig) => resp.data.config.IDPV_ALLOWED_IN_KYC;
export const userDueDiligenceReasonSelector = (resp: AuthUserConfig) => resp.data.config.USER_DUE_DILIGENCE_REASON;
