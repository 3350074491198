import * as React from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated';

import { designSystem } from '@/styles/styles';

interface LiveIndicatorProps {
    color?: string;
    size?: number;
}

const LiveIndicator: React.FC<LiveIndicatorProps> = ({ color = designSystem.colors.red, size = 16 }) => {
    const opacity = useSharedValue(0.6);

    React.useEffect(() => {
        opacity.value = withRepeat(withTiming(0.2, { duration: 1000 }), -1, true);
    }, [opacity]);

    const animatedStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
    }));

    const styles = React.useMemo(
        () => ({
            container: {
                width: size,
                height: size,
                position: 'relative' as const,
                alignItems: 'center' as const,
                justifyContent: 'center' as const,
            },
            pulsingCircle: {
                position: 'absolute' as const,
                width: size,
                height: size,
                borderRadius: size / 2,
                backgroundColor: color,
            },
            centerCircle: {
                width: size * 0.375,
                height: size * 0.375,
                borderRadius: (size * 0.375) / 2,
                backgroundColor: color,
            },
        }),
        [color, size]
    );

    return (
        <View style={styles.container}>
            <Animated.View style={[styles.pulsingCircle, animatedStyle]} />
            <View style={styles.centerCircle} />
        </View>
    );
};

export default LiveIndicator;
