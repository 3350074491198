import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import { useGetEntryDetailsQuery } from '@/api/entries/query.generated';
import EdgeComboIcon from '@/assets/icons/edge-combo-icon.js';
import Gradient from '@/assets/icons/gradient';
import { Box } from '@/components/lib/components/Box';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { playersHaveEdgeCombo } from '@/feature/betslip-pickem/utils/betslip-edge-combos';
import { useShareEntry } from '@/feature/entries-pickem/components/ShareEntryProvider';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { getEntryStatus } from '@/hooks/use-entries-utils';
import { Product } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { isWeb, showScrollIndicator } from '@/utils/constants-platform-specific';
import { getEntryPayout } from '@/utils/getEntryPayout';
import { logger } from '@/utils/logging';
import { groupPicksByEvents } from '@/utils/map-to-events';

import { Header } from '../components/Header';
import { ShareEntryPlayerCard } from '../components/ShareEntryPlayerCard';
import { useFantasyEntryShare } from '../hooks/use-fantasy-entry-share';
import { useSocialLink } from '../hooks/use-social-link';
import { getRadialGradientStyle, gradientWidth } from '../utils/get-radial-gradient-style';
import { captureViewShot } from '../utils/view-shot';
import { ShareEntryHeader } from './ShareEntryHeader';

const styles = StyleSheet.create({
    header: {
        paddingHorizontal: 0,
    },
    image: {
        height: 216,
        width: gradientWidth,
        position: 'absolute',
        top: -1, //make sure gradient image is under the 1px border
    },
    container: { paddingHorizontal: 16 },
    listPadding: { top: -15 },
    loaderWrapper: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentContainer: {
        position: 'absolute',
        width: '100%',
    },
    edgeComboIcon: {
        paddingLeft: 28,
    },
});

type EntryShareRouteProp = RouteProp<RootStackParamList, 'EntryShare'>;

export const EntryShare = () => {
    const viewRef = useRef<View>(null);
    const imageCaptured = useRef(false);
    const route = useRoute<EntryShareRouteProp>();
    const { id } = route.params;
    const navigation = useNavigation();
    const { t } = useTranslation('pickem_share_entry_modal');
    const errorToast = t('sharing_failed_msg');

    const [{ data, fetching }] = useGetEntryDetailsQuery({
        variables: { id: id ?? '' },
        requestPolicy: 'network-only',
    });
    const entry = data?.getEntry;
    const sections = useMemo(
        () => groupPicksByEvents(entry?.picks ?? [], entry?.edgeCombos ?? []),
        [entry?.edgeCombos, entry?.picks]
    );

    const entryPayout = useMemo(() => getEntryPayout({ picks: entry?.picks }), [entry]);

    const entryStatus = getEntryStatus(entry);
    const isVoided = useMemo(() => entry?.result === 'VOID', [entry?.result]);
    const { width } = useWindowDimensions();
    const gradientStyle = getRadialGradientStyle(width);
    const { showToast } = useAlerts();
    const { showShareEntryModal } = useShareEntry();
    const [loadedImages, setLoadedImages] = useState(0);
    const [loadedEvents, setLoadedEvents] = useState(0);

    const [timerPassed, setTimerPassed] = useState(false);

    useEffect(() => {
        // Start a 3-second timer to show the share modal and not letting user wait for too long
        if (entry) {
            const timer = setTimeout(() => {
                setTimerPassed(true);
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [entry]);

    const onLoadImage = useCallback(() => {
        setLoadedImages(prevLoadedImages => prevLoadedImages + 1);
    }, []);

    const onLoadEvent = useCallback(() => {
        setLoadedEvents(prevLoadedEv => prevLoadedEv + 1);
    }, []);

    const { uploadImageMetaToLink } = useSocialLink();
    const { createFantasyShareLink } = useFantasyEntryShare();

    const capture = useCallback(async () => {
        try {
            const screenshot = await captureViewShot(viewRef, {
                snapshotContentContainer: false,
                format: 'png',
            });

            if (entry) {
                //create the share link and show the share sheet
                createFantasyShareLink(entry)
                    .then(shareLink => {
                        logger.debug('ShareableLink', shareLink);
                        showShareEntryModal({
                            showToast,
                            imageUri: screenshot,
                            link: shareLink?.shortUrl,
                            onDismiss: navigation.goBack,
                        });
                        if (shareLink && Platform.OS !== 'web') {
                            //take a lower quality screenshot to upload to the l.betr.app metadata
                            captureViewShot(viewRef, {
                                snapshotContentContainer: false,
                                format: 'jpg',
                                quality: 0.25,
                            })
                                .then(res => uploadImageMetaToLink(shareLink.id, res))
                                .catch(err => logger.warn('ShareableLink', 'Could not upload link preview', err));
                        }
                    })
                    .catch(err => {
                        logger.warn('ShareableLink', err);
                        showToast({ message: errorToast, toastType: 'warning' });
                        navigation.goBack();
                    });
            }
        } catch (e) {
            logger.warn('EntryShare', e);
            showToast({ message: errorToast, toastType: 'warning' });
            navigation.goBack();
        }
    }, [createFantasyShareLink, entry, errorToast, navigation, showShareEntryModal, showToast, uploadImageMetaToLink]);

    useEffect(() => {
        const allImagesLoaded = loadedImages === entry?.picks.length || timerPassed;
        const allEventsLoaded = loadedEvents === entry?.picks.length;
        if (entry && allImagesLoaded && allEventsLoaded && !imageCaptured?.current) {
            imageCaptured.current = true;
            setTimeout(() => {
                capture();
            }, 100);
        } else if (!fetching && !entry) {
            navigation.goBack();
            showToast({ message: errorToast, toastType: 'warning' });
        }
    }, [capture, fetching, entry, navigation, showToast, loadedImages, errorToast, loadedEvents, timerPassed]);

    return (
        <SafeAreaView style={common.flex} edges={['top']}>
            <ScrollView
                showsVerticalScrollIndicator={showScrollIndicator}
                contentContainerStyle={fetching && common.flex}
            >
                <MaxWidthWrapper>
                    <Box flex={1} backgroundColor={'gray8'} ref={viewRef}>
                        {fetching ? (
                            <Box style={styles.loaderWrapper}>
                                <ActivityIndicator size={'large'} />
                            </Box>
                        ) : (
                            <>
                                <Box alignItems={'center'}>
                                    <Gradient style={[styles.image, gradientStyle]} />
                                </Box>
                                <View collapsable={false} style={isWeb ? styles.contentContainer : null}>
                                    <Header entry={entry} product={Product.Pickem} />
                                    <View style={styles.container}>
                                        <ShareEntryHeader
                                            entry={entry}
                                            picks={entry?.picks}
                                            entryPayout={entryPayout}
                                            entryStatus={entryStatus}
                                            isLoading={false}
                                            isVoided={isVoided}
                                            shareable={true}
                                        />
                                        <View style={styles.listPadding}>
                                            {sections.map((section, sectionIndex) => {
                                                const isLastSection = sectionIndex === sections.length - 1;
                                                return section.data.map((item, index) => {
                                                    const isLastPlayer = index === section.data.length - 1;
                                                    const isLast = isLastSection && isLastPlayer;
                                                    const nextPlayer = section.data[index + 1];
                                                    const isEdgeCombo = playersHaveEdgeCombo(
                                                        entry?.edgeCombos ?? [],
                                                        item.player,
                                                        nextPlayer?.player
                                                    );
                                                    return (
                                                        <View key={item.player.id}>
                                                            <ShareEntryPlayerCard
                                                                isLast={isLast}
                                                                {...item}
                                                                projection={item.projection}
                                                                onLoadImage={onLoadImage}
                                                                onLoadEvent={onLoadEvent}
                                                            />
                                                            {isEdgeCombo ? (
                                                                <Box style={styles.edgeComboIcon}>
                                                                    <EdgeComboIcon
                                                                        color={designSystem.colors.gray3}
                                                                        width={16}
                                                                        height={16}
                                                                    />
                                                                </Box>
                                                            ) : null}
                                                        </View>
                                                    );
                                                });
                                            })}
                                        </View>
                                    </View>
                                </View>
                            </>
                        )}
                    </Box>
                </MaxWidthWrapper>
            </ScrollView>
        </SafeAreaView>
    );
};
