import { useMemo } from 'react';

import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { ProductDocumentDataBodyLobbycomponentsSliceItem } from '@/types/prismic.generated';

import { DEFAULT_LOBBY_ORDERING, LobbyComponent } from '../utils/lobbySections';

const findEnabledLobbyComponentByOrderingKey = (
    components: ProductDocumentDataBodyLobbycomponentsSliceItem[],
    orderingKey: string
) => components.find(component => component.orderingkey === orderingKey && component.enabled);

/**
 * Hook that returns a new object with the variables for the lobby query
 * It checks if the components are enabled and skips the query if they are not
 * @returns {
 *  skipSharedEntries: boolean, // skip the shared entries query
 *  skipTopTen: boolean, // skip the top ten players query
 *  skipTrendingPlayers: boolean // skip the trending players query
 * }
 */
export const useLobbyQueryVariables = () => {
    const lobbyComponents = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.lobbyComponents?.items ?? DEFAULT_LOBBY_ORDERING
    );

    const lobbyQueryVariables = useMemo(() => {
        return {
            skipSharedEntries: !findEnabledLobbyComponentByOrderingKey(lobbyComponents, LobbyComponent.PrebuiltLineups),
            skipTopTen: !findEnabledLobbyComponentByOrderingKey(lobbyComponents, LobbyComponent.PopularPlayers),
            skipTrendingPlayers: !findEnabledLobbyComponentByOrderingKey(
                lobbyComponents,
                LobbyComponent.TrendingPlayersList
            ),
        };
    }, [lobbyComponents]);

    return lobbyQueryVariables;
};
