import React from 'react';
import { StyleSheet, View } from 'react-native';

import { EntryDetails } from '@/api/entries/types/types';
import BetrLogo from '@/assets/icons/betr-logo';
import Picks from '@/assets/icons/picks';
import Sportbooks from '@/assets/icons/sportbooks';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';
import { GameModeIcon } from '@/feature/entries-pickem/components/GameModeIcon';
import { Product } from '@/hooks/use-jurisdiction';
import { common } from '@/styles/styles';
import { Result } from '@/types/api.generated';

const styles = StyleSheet.create({
    textLineHeight: { lineHeight: 24, fontWeight: '700' },
    headerWrapper: { alignItems: 'center', paddingTop: 4, paddingBottom: 20 },
    tag: { position: 'absolute', top: 0, right: 0 },
    separator: { opacity: 0.1 },
    partialWrapper: { paddingTop: 16 },
    selfCentered: { alignSelf: 'center' },
});

export type HeaderProps = {
    entry?: EntryDetails;
    product: Product;
};

export const Header = ({ entry, product }: HeaderProps) => {
    const isVoided = entry?.result === Result.Void;

    const getProductIcon = () => {
        switch (product) {
            case Product.Sportsbook:
                return <Sportbooks />;
            case Product.Pickem:
                return <Picks />;
            default:
                return null;
        }
    };

    return (
        <>
            <View style={styles.headerWrapper}>
                <View style={[common.row, styles.partialWrapper]}>
                    <SizedBox value={8} />
                    <BetrLogo />
                    <SizedBox value={12} />
                    <LineSeparator vertical variant="light" style={styles.separator} />
                    <SizedBox value={12} />
                    <Box style={styles.selfCentered}>{getProductIcon()}</Box>
                </View>
                <Box position={'absolute'} top={20} right={16}>
                    <GameModeIcon grayedOut={isVoided} gameMode={entry?.gameMode} />
                </Box>
            </View>
        </>
    );
};
