import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ListRenderItem } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { RouteProp, useIsFocused, useNavigation, useScrollToTop } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useGetLobbyContentQuery } from '@/api/lobby/query.generated';
import { LobbyProductSwitchButton } from '@/components/LobbyProductSwitchButton';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';
import { AppSpacing } from '@/components/lib/theme';
import { TrendingPlayers } from '@/feature/lobby/components/TrendingPlayers';
import { LobbyCarousel } from '@/feature/promohub/components/LobbyCarousel';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { SEARCH_BAR_HEIGHT, SearchBar } from '@/feature/search/components/SearchBar';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useResumeEffectWithDelay } from '@/hooks/use-resume';
import { useInitOneTrustScript } from '@/hooks/use-script';
import { ROOT_NAVIGATOR_ID } from '@/navigation/container';
import { PickemLobbyStackParamList } from '@/navigation/pickem/types';
import { ProductDocumentDataBodyLobbycomponentsSliceItem } from '@/types/prismic.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { ResponsiveValue } from '@shopify/restyle';

import { FeaturedLineupSection } from '../components/FeaturedLineupSection';
import { LobbyLeagues } from '../components/LobbyLeagues';
import { PopularPlayersList } from '../components/PopularPlayersList';
import { useLobbyQueryVariables } from '../hooks/use-lobby-query-variables';
import { DEFAULT_LOBBY_ORDERING, LobbyComponent } from '../utils/lobbySections';

type OrderedComponent = { component: ReactNode | null; paddingBottom?: ResponsiveValue<AppSpacing, {}> };

const MOCK_FLATLIST_DATA = ['logo', 'search', 'content'];

/**
 * Returns ordered component and spacing bottom for every component
 * figma file - https://www.figma.com/design/vE0hEOT3oww2l2aQ8MRONm/Lobby-Redesign?node-id=21909-11061&m=dev
 */
const getOrderedComponent = (component: ProductDocumentDataBodyLobbycomponentsSliceItem): OrderedComponent => {
    switch (component.orderingkey) {
        case LobbyComponent.PopularPlayers:
            return {
                component: <PopularPlayersList title={component.header as string} />,
            };
        case LobbyComponent.PromoCarousel:
            return {
                component: <LobbyCarousel />,
            };
        case LobbyComponent.Leagues:
            return {
                component: <LobbyLeagues />,
            };
        case LobbyComponent.TrendingPlayersList:
            return {
                component: <TrendingPlayers title={component.header as string} />,
            };
        case LobbyComponent.PrebuiltLineups:
            return {
                component: <FeaturedLineupSection title={component.header as string} />,
            };
        default:
            return { component: null, paddingBottom: undefined };
    }
};

export type LobbyScreenRouteProp = RouteProp<PickemLobbyStackParamList, 'LobbyScreen'>;

export type LobbyStackScreenProp = StackNavigationProp<
    PickemLobbyStackParamList,
    'LobbyScreen',
    typeof ROOT_NAVIGATOR_ID
>;

export const LobbyScreen = () => {
    const scrollableRef = useRef<FlatList>(null);

    const lobbyQueryVariables = useLobbyQueryVariables();

    // ignore first parameter, we are executing the query only to load the cache
    const [, execute] = useGetLobbyContentQuery({
        pause: true,
        variables: lobbyQueryVariables,
    });

    const refresh = useCallback(() => {
        execute({
            requestPolicy: 'cache-and-network',
        });
    }, [execute]);

    useResumeEffectWithDelay(refresh);

    const productConfig = useActiveProductConfig();

    const { enabled: isMaintenanceEnabled } = productConfig?.appMaintenanceConfig?.primary || {};

    const lobbyComponents = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.lobbyComponents?.items ?? DEFAULT_LOBBY_ORDERING
    );

    const searchEnabled = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.search_bar_enabled
    );

    useScrollToTop(scrollableRef);
    useInitOneTrustScript();

    const renderItem: ListRenderItem<string> = useCallback(
        ({ item }) => {
            switch (item) {
                case 'logo':
                    return (
                        <MaxWidthWrapper>
                            <LobbyProductSwitchButton />
                        </MaxWidthWrapper>
                    );
                case 'search':
                    if (!searchEnabled) {
                        return null;
                    }
                    return <SearchContainer />;
                case 'content':
                    return (
                        <MaxWidthWrapper>
                            {lobbyComponents.map(lobbyComponent => {
                                const { component } = getOrderedComponent(lobbyComponent);

                                return component && lobbyComponent.enabled ? (
                                    <Box key={lobbyComponent.orderingkey}>{component}</Box>
                                ) : null;
                            })}
                        </MaxWidthWrapper>
                    );
                default:
                    return null;
            }
        },
        [lobbyComponents, searchEnabled]
    );
    return !isMaintenanceEnabled ? (
        <Screen>
            {/*
                TrendingPlayers contains another FlatList
                and we cannot have two nested ScrollViews with the same orientation
                unless both of them are virtualized

                That's why we have here a FlatList with a single element
            */}
            <FlatList
                ref={scrollableRef}
                data={MOCK_FLATLIST_DATA}
                stickyHeaderIndices={STICKY_HEADER_INDICES}
                renderItem={renderItem}
            />
        </Screen>
    ) : null;
};

const STICKY_HEADER_INDICES = [1];

// extracted the search button as a separated component so we do not trigger re-renders of the whole LobbyScreen
const SearchContainer = () => {
    const navigation = useNavigation<LobbyStackScreenProp>();

    const [shouldHideSearchBar, setShouldHideSearchBar] = useState(false);

    useEffect(() => {
        // get parent navigator since the SearchScreen is in the root stack navigator
        return navigation.getParent(ROOT_NAVIGATOR_ID)?.addListener('state', e => {
            const index = e.data.state.index;
            const route = e.data.state.routes[index];
            if (route.name === 'SearchScreen') {
                setShouldHideSearchBar(true);
            } else {
                setShouldHideSearchBar(false);
            }
        });
    }, [navigation]);
    const isFocused = useIsFocused();

    const shouldShow = isWeb ? isFocused : !shouldHideSearchBar;

    return (
        <MaxWidthWrapper>
            <Box pb={'s16'} pt={'s8'} px={'s16'} mb={'s16'} backgroundColor={'gray8'}>
                {shouldShow ? <SearchBar /> : <SizedBox value={SEARCH_BAR_HEIGHT} />}
            </Box>
        </MaxWidthWrapper>
    );
};
