import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';
import { useOptionName } from '@/feature/event-details-sbk/hooks/use-option-name';
import { useShallow } from 'zustand/react/shallow';

import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';

type OptionNameProps = TextProps & { optionId: string };

export const OptionName = ({ optionId, ...textProps }: OptionNameProps) => {
    const optionNameParams = useSbkBetSlipStore(
        useShallow(state => {
            const option = state.options[optionId];
            const selection = state.selections[optionId];
            const event = state.events[selection.eventId];
            const market = state.markets[selection.marketId];
            const sportName = event.sport.name;
            return {
                optionType: option.optionType,
                marketType: market.marketType,
                homeTeam: event.home_team,
                awayTeam: event.away_team,
                fallback: option.description,
                sportName,
            };
        })
    );
    const optionName = useOptionName(optionNameParams);

    return (
        <Text {...textProps} testID="optionName">
            {optionName}
        </Text>
    );
};
