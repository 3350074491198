import React, { useEffect } from 'react';
import { Dimensions, Platform } from 'react-native';
import { useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';

import { Screen } from '@/components/ScreenComponent';
import { TOP_TAB_BAR_CONFIG } from '@/components/TopTabBar';
import { Box } from '@/components/lib/components';
import { useResumeBetFailsafe } from '@/feature/betslip-sbk/hooks/use-resume-bet-failsafe';
import { useAuthUserSettings } from '@/hooks/use-auth-user-settings';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';

import { BetSlipTabBar } from '../components/BetSlipTabBar';
import { BetSlipActionTray } from '../components/betslip-action-tray/BetSlipActionTray';
import { BetSlipNumPad } from '../components/betslip-num-pad/BetSlipNumPad';
import { ComboTabScreen } from '../components/betslip-tabs/ComboTab';
import { SinglesTabScreen } from '../components/betslip-tabs/SinglesTab';
import { useIsBetSlipSubmitting } from '../hooks/betslip-hooks';
import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';
import { useTrackToggleBetSlip } from '../hooks/use-track-toggle-bet-slip';
import { BetSlipParamsList, BetSlipTab, BetSlipTabScreenProps, SBKBetSlip } from '../types';
import { selectionCountSelector } from '../utils/betslip-selectors';

const INITIAL_HEIGHT_OF_PLACE_BETS_BUTTON_CONTAINER = 113;
const BETSLIP_NUMPAD_TOP_BORDER_OFFSET = 2; // Used to match design with figma

const Tab = createMaterialTopTabNavigator<BetSlipParamsList>();

export const BetSlipScreen = () => {
    useResumeBetFailsafe();
    const hasComboBets = useSbkBetSlipStore(state => Object.keys(state.selections).length > 1);
    const showKeyboard = useSbkBetSlipStore(state => state.showKeyboard);
    const isBetSlipSubmitting = useIsBetSlipSubmitting();
    const animatedPosition = useSharedValue(0);
    const placeBetsContainerHeight = React.useRef(INITIAL_HEIGHT_OF_PLACE_BETS_BUTTON_CONTAINER);

    const windowHeight = Dimensions.get('window').height;

    const animatedStyle = useAnimatedStyle(() => {
        const marginBottom = windowHeight - animatedPosition.value - placeBetsContainerHeight.current;
        return {
            marginBottom: marginBottom > 0 ? marginBottom : 0,
        };
    });

    const setShowKeyboard = useSbkBetSlipStore(state => state.actions.setShowKeyboard);

    // update user settings with latest data when user open the bet slip
    const updateUserSettings = useSbkBetSlipStore(state => state.actions.updateUserSettings);
    const { data: userSettingsData } = useAuthUserSettings();
    useEffect(() => {
        if (userSettingsData) {
            updateUserSettings(userSettingsData);
        }
    }, [updateUserSettings, userSettingsData]);

    // Close bet slip if there are no selections
    const hasSelections = useSbkBetSlipStore((state: SBKBetSlip) => selectionCountSelector(state) > 0);
    const { goBack, navigate } = useNavigation<BetSlipTabScreenProps>();
    useEffect(() => {
        if (!hasSelections) {
            goBack();
        }
        return () => {
            setShowKeyboard(false); // hide keyboard when leaving the bet slip
        };
    }, [goBack, hasSelections, setShowKeyboard]);

    // Set tab selected by default before rendering Bet Slip
    const alreadyRendered = React.useRef(false);

    useEffect(() => {
        if (!alreadyRendered.current) {
            alreadyRendered.current = true;
            let tab: BetSlipTab = 'Singles';
            if (hasComboBets) {
                tab = 'Combo';
            }
            navigate(tab);
        }
    }, [hasComboBets, navigate]);

    usePreventAndroidBackNavigation(isBetSlipSubmitting);
    const { bottom, top } = useSafeAreaInsets();

    const getContentPaddingBottom = () => {
        let paddingBottom = placeBetsContainerHeight.current - bottom;
        if (Platform.OS === 'android' && showKeyboard) {
            paddingBottom += top;
        }
        return paddingBottom;
    };

    const { screenListeners } = useTrackToggleBetSlip();
    const numpadBottomHeight = placeBetsContainerHeight.current - BETSLIP_NUMPAD_TOP_BORDER_OFFSET;
    return (
        <Screen edges={['top', 'bottom']}>
            <Box flex={1} style={{ paddingBottom: getContentPaddingBottom() }} accessible={false}>
                <Tab.Navigator
                    screenListeners={screenListeners}
                    tabBar={BetSlipTabBar}
                    initialRouteName="Singles"
                    screenOptions={TOP_TAB_BAR_CONFIG}
                >
                    <Tab.Screen name={'Combo'} options={{ title: 'Combo', tabBarTestID: 'comboTab' }}>
                        {() => <ComboTabScreen animatedStyle={animatedStyle} />}
                    </Tab.Screen>
                    <Tab.Screen name={'Singles'} options={{ title: 'Singles', tabBarTestID: 'singlesTab' }}>
                        {() => <SinglesTabScreen animatedStyle={animatedStyle} />}
                    </Tab.Screen>
                </Tab.Navigator>
            </Box>
            <BetSlipNumPad animatedPosition={animatedPosition} bottomHeight={numpadBottomHeight} />
            <Box
                position="absolute"
                backgroundColor="gray8"
                bottom={0}
                left={0}
                right={0}
                height={numpadBottomHeight}
                onLayout={event => {
                    placeBetsContainerHeight.current = event.nativeEvent.layout.height;
                }}
            >
                <BetSlipActionTray />
            </Box>
        </Screen>
    );
};
