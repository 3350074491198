import * as React from 'react';
import Svg, { Mask, Path, Rect } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={48} height={36} fill="none" {...props}>
        <Path fill="#E5E5E5" d="M0 0h48v36H0z" />
        <Path fill="#2F3038" d="M-2798-610h5885v1854h-5885z" />
        <Mask id="a" fill="#fff">
            <Path d="M0-20h361v76H0v-76Z" />
        </Mask>
        <Path fill="#101114" d="M0-20h361v76H0v-76Z" />
        <Path fill="#2F3038" d="M361 55.67H0v.66h361v-.66Z" mask="url(#a)" />
        <Rect width={47} height={35} x={0.5} y={0.5} stroke="#202126" rx={7.5} />
        <Path
            fill="#fff"
            d="M31.935 9c.68 1.124.987 2.28.987 3.743 0 4.662-3.98 10.72-7.21 14.972h-7.379l-2.959-17.694 6.46-.614L23.4 21.998c1.462-2.381 3.266-6.124 3.266-8.676 0-1.396-.24-2.348-.613-3.131L31.935 9Z"
        />
    </Svg>
);
export default SvgComponent;
