import * as React from 'react';

const SvgComponent = props => {
    //We need this id to display the gradient on share entry screen for web.
    //Otherwise on win entries (where we have the green gradient on entry overview) the gradient on share entry won't show up.
    let uniqueId = `id-${Math.random().toString(36).substring(7)}`;
    return (
        <svg width="744" height="216" viewBox="0 0 393 216" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="744" height="216" transform="translate(-175)" fill={`url(#${uniqueId})`} />
            <defs>
                <radialGradient
                    id={uniqueId}
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(372) rotate(90) scale(215 370.278)"
                >
                    <stop stopColor={props.color ?? '#CF51F5'} stopOpacity="0.33" />
                    <stop offset="1" stopColor={props.color ?? '#CF51F5'} stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
};
export default SvgComponent;
