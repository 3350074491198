import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { common, designSystem, withOpacity } from '@/styles/styles';
import { EventStatus, Result } from '@/types/api.generated';

const styles = StyleSheet.create({
    dot: {
        width: 8,
        height: 8,
        borderRadius: 100,
        backgroundColor: designSystem.colors.gray6,
    },
    inProgress: {
        backgroundColor: designSystem.colors.white,
    },
    win: {
        backgroundColor: designSystem.colors.green,
    },
    loss: {
        backgroundColor: designSystem.colors.red,
    },
    void: {
        backgroundColor: designSystem.colors.gray4,
    },
    light: {
        backgroundColor: withOpacity(designSystem.colors.white, 0.2),
    },
});

type Pick = {
    result?: Result;
    eventStatus?: EventStatus;
};

const resultStyleMap = {
    [Result.Void]: 'void',
    [Result.Win]: 'win',
    [Result.Loss]: 'loss',
} as const;

type Props = {
    picks: Pick[];
    gapSize?: number;
    light?: boolean;
};

export const PickProgressDots = ({ picks, gapSize = 4, light = false }: Props) => {
    if (!Array.isArray(picks)) {
        return null;
    }

    const renderDot = (idx: number, pick: Pick) => {
        const resultStyle = pick.result ? resultStyleMap[pick.result] : 'inProgress';
        let style: ViewStyle | undefined = styles[resultStyle];
        if (resultStyle === 'inProgress' && pick.eventStatus !== EventStatus.InProgress) {
            style = undefined;
        }
        return <View key={`pick-${pick?.result}-${idx}`} style={[styles.dot, light ? styles.light : null, style]} />;
    };

    return <View style={[common.row, { gap: gapSize }]}>{picks?.map((pick, i) => renderDot(i, pick))}</View>;
};
