import React from 'react';

import LossSbkIcon from '@/assets/icons/lossSBK';
import StrikedThroughDotLarge from '@/assets/icons/striked-through-dot-large';
import WonIcon from '@/assets/icons/won';
import { Box } from '@/components/lib/components';

import { Result } from '../../hooks/types';
import { betSelectionItemStyle } from './Styles';

type BetSelectionIconProps = {
    result: Result;
    variant: 's' | 'l';
};

export const BetSelectionIcon = ({ result, variant }: BetSelectionIconProps) => {
    const { iconStyles, strikedDotIconStyles } = getIconStyles(variant);

    switch (result) {
        case 'WON':
            return <WonIcon width={iconStyles.width} height={iconStyles.height} />;
        case 'LOST':
            return <LossSbkIcon width={iconStyles.width} height={iconStyles.height} />;
        case 'CANCELLED':
        case 'PUSHED':
        case 'CASHEDOUT':
            return (
                <Box
                    backgroundColor="gray3"
                    borderRadius="r40"
                    width={iconStyles.width}
                    height={iconStyles.height}
                    borderWidth={4}
                    borderColor="gray3"
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <StrikedThroughDotLarge
                        width={strikedDotIconStyles.width}
                        height={strikedDotIconStyles.height}
                        fillColor="#000000"
                    />
                </Box>
            );
        default:
            return (
                <Box
                    borderRadius="r96"
                    width={iconStyles.width}
                    height={iconStyles.height}
                    borderWidth={2}
                    borderColor="gray5"
                    backgroundColor="gray8"
                />
            );
    }
};

const getIconStyles = (variant: 's' | 'l') => {
    const { s, l } = betSelectionItemStyle;
    switch (variant) {
        case 'l': {
            return {
                iconStyles: l.iconStyles,
                strikedDotIconStyles: l.strikedDotIconStyles,
            };
        }
        case 's': {
            return {
                iconStyles: s.iconStyles,
                strikedDotIconStyles: s.strikedDotIconStyles,
            };
        }
    }
};
