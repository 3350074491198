import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@/assets/icons/info';
import StrikedThroughDotLarge from '@/assets/icons/striked-through-dot-large';
import { Text } from '@/components/TextComponent';
import { Box, TouchableBox } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { designSystem } from '@/styles/styles';

import { BetSelection } from '../../hooks/types';

export const BetSelectionVoidedInfo = ({
    selection,
    isCashedOut,
}: {
    selection: BetSelection;
    isCashedOut: boolean;
}) => {
    const { t } = useTranslation(['bets']);
    const { showInfoSheet } = useAlerts();

    const isSelectionCashedOut = !selection.result && isCashedOut;

    const handleVoidAlertPress = () =>
        showInfoSheet({
            title: t('voided_leg'),
            description: isSelectionCashedOut ? t('cashed_out_desc') : t('voided_leg_desc'),
            buttonLabel: t('got_it'),
            icon: (
                <Box
                    width={72}
                    height={72}
                    backgroundColor="gray5"
                    borderRadius="r96"
                    justifyContent="center"
                    alignItems="center"
                >
                    <StrikedThroughDotLarge width={'36'} height={'36'} fillColor="white" />
                </Box>
            ),
        });

    return (
        <Box flexDirection="row" alignItems="center" gap="s10" paddingVertical={'s2'}>
            <Text variant="titleSmall" color="gray3">
                {isSelectionCashedOut ? t('voided_cashout_leg') : t('voided_leg')}
            </Text>
            <TouchableBox onPress={handleVoidAlertPress}>
                <InfoIcon width="14" height="14" color={designSystem.colors.gray3} />
            </TouchableBox>
        </Box>
    );
};
