import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { getComboBetType, isComboSelectionEnabled } from '@/feature/betslip-sbk/utils/betslip-utils';

export const ComboBetName = () => {
    const { t } = useTranslation('bets');
    const activeSelectionCount = useSbkBetSlipStore(
        state => Object.values(state.selections).filter(selection => isComboSelectionEnabled(selection, state)).length
    );
    const comboBetType = useSbkBetSlipStore(state => getComboBetType(state));
    const betTypeKey = comboBetType === 'SGP' ? 'script_bet_name' : 'combo_bet_name';
    const betName = t(betTypeKey, { count: activeSelectionCount });
    return (
        <Text variant="titleMedium" color="gray1" testID="comboCardTitle">
            {betName}
        </Text>
    );
};
