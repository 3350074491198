import { designSystem } from '@/styles/styles';
import { SpacingProps, SpacingShorthandProps, createTheme, useTheme } from '@shopify/restyle';

import { textVariants } from './typography';

export const appTheme = createTheme({
    name: 'betrTheme',
    colors: {
        mainBackground: designSystem.colors.gray8,
        primaryText: designSystem.colors.gray1,
        ...designSystem.colors,
    },
    spacing: {
        s0: 0,
        s2: 2,
        s3: 3,
        s4: 4,
        s6: 6,
        s8: 8,
        s10: 10,
        s12: 12,
        s14: 14,
        s16: 16,
        s18: 18,
        s20: 20,
        s22: 22,
        s24: 24,
        s26: 26,
        s28: 28,
        s30: 30,
        s32: 32,
        s34: 34,
        s36: 36,
        s38: 38,
        s40: 40,
        s42: 42,
        s44: 44,
        s46: 46,
        s48: 48,
        s56: 56,
        s80: 80,
        s64: 64,
        s96: 96,
    },
    breakpoints: {
        phone: 0,
        tablet: 768,
    },
    textVariants: {
        defaults: { ...textVariants.bodyMedium },
        ...textVariants,
    },
    borderRadii: {
        r2: 2,
        r4: 4,
        r6: 6,
        r8: 8,
        r10: 10,
        r12: 12,
        r16: 16,
        r20: 20,
        r22: 22,
        r24: 24,
        r26: 26,
        r32: 32,
        r36: 36,
        r38: 38,
        r40: 40,
        r48: 48,
        r64: 64,
        r96: 96,
    },
} as const);

export type AppTheme = typeof appTheme;

export type AppColors = keyof AppTheme['colors'];

export type AppSpacing = keyof AppTheme['spacing'];

export type DesignSystemColor = keyof typeof designSystem.colors;

export const useAppTheme = () => useTheme<AppTheme>();

export type AppSpacingProps = SpacingProps<AppTheme> & SpacingShorthandProps<AppTheme>;
