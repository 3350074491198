import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../types/api.generated';
import { SharedEntryInfoFragmentDoc } from '../../feature/entry-share/api/query.generated';
import {
    EventInfoDataFragmentDoc,
    PlayerInfoWithProjectionsFragmentDoc,
    TeamInfoWithPlayersFragmentDoc,
} from '../events/query.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetLobbyContentQueryVariables = Types.Exact<{
    skipTopTen: Types.Scalars['Boolean']['input'];
    skipTrendingPlayers: Types.Scalars['Boolean']['input'];
    skipSharedEntries: Types.Scalars['Boolean']['input'];
}>;

export type GetLobbyContentQuery = {
    __typename?: 'Query';
    getTopTenPlayersData?: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
    getUpcomingLobbyEventsV2?: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
    getSharedEntries?: Array<{
        __typename?: 'SharedEntry';
        id: string;
        title: string;
        description: string;
        logo?: string;
        headerImage?: string;
        entry: {
            __typename?: 'EntryOutput';
            id: string;
            toWin?: number;
            initialToWin?: number;
            status: Types.Status;
            result?: Types.Result;
            reasonCode?: number;
            reasonText?: string;
            state: Types.State;
            amount: number;
            initialAmount?: number;
            createdDate: string;
            settlementDate?: string;
            currency: Types.Currency;
            initialMultiplier: number;
            multiplier: number;
            userId: string;
            gameMode?: Types.GameMode;
            gameType?: Types.GameType;
            canBeCanceled?: boolean;
            cancelTimeFrame?: number;
            edgeCombos?: Array<Array<string>>;
            winningDynamicMultiplier?: {
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            };
            dynamicMultipliers?: Array<{
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            boostedMultipliers?: Array<{
                __typename?: 'BoostedMultiplier';
                numberOfPicks: number;
                numberOfBoostedPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            picks: Array<{
                __typename?: 'PickOutput';
                currentValue?: number;
                eventDate: string;
                eventId: string;
                eventStatus: Types.EventStatus;
                league: Types.League;
                outcome: Types.Outcome;
                sport: Types.Sport;
                teamId?: string;
                teamName?: string;
                result?: Types.Result;
                projection: {
                    __typename?: 'Projection';
                    marketId?: string;
                    marketStatus?: Types.MarketStatus;
                    isLive?: boolean;
                    type: Types.ProjectionType;
                    label: string;
                    name: string;
                    key: string;
                    order: number;
                    value: number;
                    nonRegularPercentage?: number;
                    nonRegularValue?: number;
                    currentValue?: number;
                    allowedOptions?: Array<{
                        __typename?: 'MarketOptionOutput';
                        marketOptionId: string;
                        outcome: Types.Outcome;
                    }>;
                };
                player: {
                    __typename?: 'Player';
                    id: string;
                    firstName: string;
                    lastName: string;
                    icon: string;
                    position: string;
                    jerseyNumber?: number;
                    attributes?: Array<{
                        __typename?: 'PlayerAttribute';
                        key?: Types.PlayerAttributesType;
                        value: string;
                    }>;
                };
            }>;
        };
    }>;
};

export const GetLobbyContentDocument = gql`
    query GetLobbyContent($skipTopTen: Boolean!, $skipTrendingPlayers: Boolean!, $skipSharedEntries: Boolean!) {
        getTopTenPlayersData @skip(if: $skipTopTen) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
        }
        getUpcomingLobbyEventsV2 @skip(if: $skipTrendingPlayers) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            dedicated
        }
        getSharedEntries @skip(if: $skipSharedEntries) {
            ...SharedEntryInfo
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoWithPlayersFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
    ${SharedEntryInfoFragmentDoc}
`;

export function useGetLobbyContentQuery(options: Omit<Urql.UseQueryArgs<GetLobbyContentQueryVariables>, 'query'>) {
    return Urql.useQuery<GetLobbyContentQuery, GetLobbyContentQueryVariables>({
        query: GetLobbyContentDocument,
        ...options,
    });
}
