import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetCard } from '@/components/BetCard';
import { DotStatus } from '@/components/ProgressDots';

import { Bet, BetEvent } from '../../hooks/types';
import { useFooter, useOpenBadge, voidedBetCardProps } from '../../utils/bet-card-utils';
import { getProgressDots } from '../../utils/utils';
import { SBKBetCardTypeProps } from '../SBKBetCard';
import { CommonBetCardProps } from './SingleSbkBetCard';
import { useOpenMultiBetSummary, useSettledMultiBetSummary, useVoidedBetSummary } from './utils';

type CommonComboBetCardProps = CommonBetCardProps & {
    progressDots: DotStatus[];
};

/**
 * Helper Hooks
 */
const formatEventTeams = (event: Pick<BetEvent, 'home_team' | 'away_team'>) => {
    const awayTeam = event.away_team?.short_code;
    const homeTeam = event.home_team?.short_code;
    return `${awayTeam} @ ${homeTeam}`;
};

const useTitle = (bet: Bet) => {
    const { t } = useTranslation('bets');
    return t('combo_bet_name', { count: bet.selection_count });
};

export const useSubTitle = (events: Pick<BetEvent, 'home_team' | 'away_team'>[]) => {
    return events.map(event => formatEventTeams(event)).join(' • ');
};

/**
 * Components
 */
const OpenComboBetCard = ({ bet, ...commonBetDetails }: CommonComboBetCardProps) => {
    const status = useOpenBadge(bet);
    const summary = useOpenMultiBetSummary(bet);

    return <BetCard status={status} summary={summary} {...commonBetDetails} />;
};

const SettledComboBetCard = ({
    bet,
    status,
    ...commonBetDetails
}: CommonComboBetCardProps & {
    status: 'won' | 'lost';
}) => {
    const summary = useSettledMultiBetSummary(bet);
    return <BetCard status={status} summary={summary} {...commonBetDetails} />;
};

const VoidedComboBetCard = ({ bet, ...commonBetDetails }: CommonComboBetCardProps) => {
    const summary = useVoidedBetSummary(bet);

    return <BetCard status="voided" summary={summary} {...commonBetDetails} {...voidedBetCardProps} />;
};

export const ComboSbkBetCard = ({ bet, onSharePress }: SBKBetCardTypeProps) => {
    const title = useTitle(bet);
    const subTitle = useSubTitle(bet.events);
    const footer = useFooter(bet);
    const progressDots = getProgressDots(bet);
    const commonBetDetails = { title, subTitle, footer, progressDots, onSharePress };

    switch (bet.result) {
        case 'PENDING':
            return <OpenComboBetCard bet={bet} {...commonBetDetails} />;
        case 'WON':
        case 'CASHEDOUT':
            return <SettledComboBetCard bet={bet} status={'won'} {...commonBetDetails} />;
        case 'LOST':
            return <SettledComboBetCard bet={bet} status={'lost'} {...commonBetDetails} />;
        case 'CANCELLED':
        case 'PUSHED':
            return <VoidedComboBetCard bet={bet} {...commonBetDetails} />;
    }
};
