import React, { PropsWithChildren, useState } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { appTheme } from '@/components/lib/theme';
import { UrqlClientOptions, createUrqlClient, queryClient } from '@/data';
import { common } from '@/styles/styles';
import { ThemeProvider } from '@shopify/restyle';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'urql';

const GraphqlClientContext = React.createContext<{
    resetUrqlCache: () => void;
    recreateGraphqlClient: (opts?: UrqlClientOptions) => void;
}>({
    resetUrqlCache: () => {},
    recreateGraphqlClient: () => {},
});

export const ReactQueryClientProvider = ({ children }: PropsWithChildren) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export const FantasyUrqlClientProvider = ({ children }: PropsWithChildren) => {
    const [client, setClient] = useState(createUrqlClient());

    return (
        <GraphqlClientContext.Provider
            value={{
                resetUrqlCache: () => {
                    setClient(createUrqlClient());
                },
                recreateGraphqlClient: (opts?: UrqlClientOptions) => {
                    setClient(createUrqlClient(opts));
                },
            }}
        >
            <Provider value={client}>{children}</Provider>
        </GraphqlClientContext.Provider>
    );
};

export const GestureHandlerRootViewProvider = ({ children }: PropsWithChildren) => {
    return <GestureHandlerRootView style={common.flex}>{children}</GestureHandlerRootView>;
};

export const BetrThemeProvider = ({ children }: PropsWithChildren) => {
    return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

export const useGraphqlClient = () => {
    return React.useContext(GraphqlClientContext);
};
