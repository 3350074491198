import React from 'react';

import Switch from '@/components/Switch';
import { BetListItem } from '@/components/bet-list/BetListItem';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useIsBetSlipSubmitting, useIsOptionSuspended } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useBetSlipMarketName } from '@/feature/betslip-sbk/hooks/use-bet-slip-market-name';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { isConflictingSelection } from '@/feature/betslip-sbk/utils/betslip-utils';
import { useOptionName } from '@/feature/event-details-sbk/hooks/use-option-name';

type ComboBetListItemProps = {
    selectionId: string;
    isLastEvent: boolean;
    isFirstLeg: boolean;
    isLastLeg: boolean;
    testID?: string;
};

export const ComboBetListItem = ({
    selectionId,
    isLastEvent,
    isFirstLeg,
    isLastLeg,
    testID,
}: ComboBetListItemProps) => {
    const toggleComboSelectionStatus = useSbkBetSlipStore(state => state.actions.toggleComboSelectionStatus);
    const selection = useSbkBetSlipStore(state => state.selections[selectionId]);
    const isConflicting = useSbkBetSlipStore(state => isConflictingSelection(selection, state));
    const isBetSlipSubmitting = useIsBetSlipSubmitting();
    const isSgpFetching = useSbkBetSlipStore(state => state.isSgpFetching);

    const { optionId, eventId, marketId, isComboEnabled: isEnabled } = selection;
    const option = useSbkBetSlipStore(state => state.options[optionId]);
    const event = useSbkBetSlipStore(state => state.events[eventId]);
    const market = useSbkBetSlipStore(state => state.markets[marketId]);
    const previousOdds = useSbkBetSlipStore(state => state.oddsChanges[selectionId]);
    const isSuspended = useIsOptionSuspended(optionId);
    const sportName = event.sport.name;
    const odds = option.odds;

    const optionNameParams = {
        optionType: option.optionType,
        marketType: market.marketType,
        homeTeam: event.home_team,
        awayTeam: event.away_team,
        fallback: option.description,
        sportName,
    };

    const optionName = useOptionName(optionNameParams);
    const marketName = useBetSlipMarketName({ marketId, eventId });

    return (
        <BetListItem
            isLastEvent={isLastEvent}
            isFirstLeg={isFirstLeg}
            isLastLeg={isLastLeg}
            isEnabled={isEnabled}
            isSuspended={isSuspended}
            isConflicting={isConflicting}
            endComponent={
                <Switch
                    val={isEnabled}
                    warning={isConflicting}
                    disabled={isSuspended}
                    editable={!isBetSlipSubmitting && !isSgpFetching}
                    handleOnPress={() => {
                        GASbkEvents.toggleSelection();
                        toggleComboSelectionStatus(selection.optionId);
                    }}
                    testID={`comboSelectionToggle-${testID}`}
                />
            }
            optionName={optionName}
            marketName={marketName}
            odds={odds}
            previousOdds={previousOdds}
        />
    );
};
