import React from 'react';

import BetrBucks from '@/assets/icons/betr-bucks';
import { Bet } from '@/feature/bets-sbk/hooks/types';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { Odds } from './Odds';
import { Text, TextProps } from './TextComponent';
import { Box, BoxProps, Column, Row } from './lib/components';

export type BetSummarySectionProps = {
    stake: number;
    multiplier?: number;
    multiplierColor?: TextProps['color'];
    strikeThroughMultiplier?: number;
    strikeThroughMultiplierColor?: TextProps['color'];
    header1Text: string;
    header1TextColor?: TextProps['color'];
    body1TextColor?: TextProps['color'];
    body1TextDecorationLine?: TextProps['textDecorationLine'];
    header2Text: string;
    header2TextColor?: TextProps['color'];
    header3Text?: string;
    header3TextColor?: TextProps['color'];
    body3Text: string;
    body3TextColor?: TextProps['color'];
    bodyTextVariant?: TextProps['variant'];
    isBetrBucks: boolean;
    betSummarySeparatorColour?: BoxProps['backgroundColor'];
    betSummarySeparatorOpacity?: number;
    testID?: string;
};

export type BetSummaryProps = {
    bet: Bet;
    betSummarySectionProps?: Partial<BetSummarySectionProps>;
};

const VerticalSeparator = ({
    betSummarySeparatorColour,
    betSummarySeparatorOpacity,
}: {
    betSummarySeparatorColour: BoxProps['backgroundColor'];
    betSummarySeparatorOpacity: number;
}) => (
    <Box width={1} height={'100%'} backgroundColor={betSummarySeparatorColour} opacity={betSummarySeparatorOpacity} />
);

export const BetSummarySection = ({
    stake,
    multiplier,
    multiplierColor = 'gray1',
    strikeThroughMultiplier,
    strikeThroughMultiplierColor = 'gray2',
    header1Text,
    header1TextColor = 'gray2',
    body1TextColor = 'gray1',
    body1TextDecorationLine = 'none',
    header2Text,
    header2TextColor = 'gray2',
    header3Text,
    header3TextColor = 'gray2',
    body3Text,
    body3TextColor = 'gray1',
    bodyTextVariant = 'labelLarge',
    isBetrBucks,
    betSummarySeparatorColour = 'gray7',
    betSummarySeparatorOpacity = 1,
    testID,
}: BetSummarySectionProps) => {
    return (
        <>
            <Column flex={1} alignItems="center">
                <Text variant="bodySmall" color={header1TextColor} testID={`wagerHeader-${testID}`}>
                    {header1Text}
                </Text>
                <Row alignItems="center">
                    {isBetrBucks ? <BetrBucks height={16} /> : null}
                    <Text
                        variant={bodyTextVariant}
                        color={body1TextColor}
                        textDecorationLine={body1TextDecorationLine}
                        testID={`wagerAmount-${testID}`}
                    >
                        {toLocaleCurrency(stake)}
                    </Text>
                </Row>
            </Column>
            <VerticalSeparator
                betSummarySeparatorColour={betSummarySeparatorColour}
                betSummarySeparatorOpacity={betSummarySeparatorOpacity}
            />
            <Column flex={1} alignItems="center">
                <Text variant="bodySmall" color={header2TextColor} testID={`multiplierHeader-${testID}`}>
                    {header2Text}
                </Text>
                <Row columnGap="s4" testID={`multiplierAmount-${testID}`}>
                    {strikeThroughMultiplier ? (
                        <Odds
                            variant={bodyTextVariant}
                            odds={strikeThroughMultiplier}
                            textDecorationLine="line-through"
                            color={strikeThroughMultiplierColor}
                        />
                    ) : null}
                    {multiplier ? <Odds odds={multiplier} variant={bodyTextVariant} color={multiplierColor} /> : null}
                </Row>
            </Column>
            <VerticalSeparator
                betSummarySeparatorColour={betSummarySeparatorColour}
                betSummarySeparatorOpacity={betSummarySeparatorOpacity}
            />
            <Column flex={1} alignItems="center" justifyContent="center">
                {header3Text ? (
                    <Text
                        variant="bodySmall"
                        color={header3TextColor}
                        textTransform="capitalize"
                        testID={`winningsHeader-${testID}`}
                    >
                        {header3Text}
                    </Text>
                ) : null}
                <Text variant={bodyTextVariant} color={body3TextColor} testID={`winningsAmount-${testID}`}>
                    {body3Text}
                </Text>
            </Column>
        </>
    );
};
