import React, { ReactElement, useEffect, useState } from 'react';
import { Image, StyleSheet } from 'react-native';
import Svg, { Text as SvgText } from 'react-native-svg';

import ArrowDown from '@/assets/icons/arrowDown';
import ArrowUp from '@/assets/icons/arrowUp';
import CorrectThickIcon from '@/assets/icons/correctThickIcon';
import EntryProgressBar from '@/assets/icons/entryProgressBar';
import IncorrectIcon from '@/assets/icons/incorrectIcon';
import VoidIcon from '@/assets/icons/voidIcon';
import PlayerImagePlaceholder from '@/assets/images/playerImagePlaceholder';
import { Box } from '@/components/lib/components';
import { AppColors } from '@/components/lib/theme';
import { CollegeBasketballShirt } from '@/components/player-profile/tiles/college-sports/CollegeBasketballShirt';
import { CollegeFootballShirt } from '@/components/player-profile/tiles/college-sports/CollegeFootballShirt';
import { designSystem } from '@/styles/styles';
import { EventStatus, League, Outcome, Result } from '@/types/api.generated';
import { addResizeParamToURL } from '@/utils/add-resize-param-url';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { isPlayerImgPlaceholder } from '@/utils/player';
import { ResponsiveValue } from '@shopify/restyle';

import { EntryItemPick } from './EntryItem';

const statusType = {
    INCORRECT: 'incorrect',
    CORRECT: 'correct',
    VOID: 'void',
    UPCOMING: 'upcoming',
    IN_PROGRESS_LESS: 'in_progress_less',
    IN_PROGRESS_MORE: 'in_progress_more',
};

type PickCircleWithStatusProps = {
    pick: EntryItemPick;
};

const colorMap: Record<string, ResponsiveValue<AppColors, {}>> = {
    void: 'gray3',
    correct: 'successGreen',
    incorrect: 'redError',
};

const iconsMap: Record<string, ReactElement> = {
    void: <VoidIcon />,
    correct: <CorrectThickIcon />,
    incorrect: <IncorrectIcon />,
    in_progress_less: <ArrowDown />,
    in_progress_more: <ArrowUp />,
};

const resultMap: Record<Result, string> = {
    [Result.Loss]: statusType.INCORRECT,
    [Result.Win]: statusType.CORRECT,
    [Result.Void]: statusType.VOID,
};

export const PickCircleWithStatus = ({ pick }: PickCircleWithStatusProps) => {
    let status = '';

    if (pick.eventStatus === EventStatus.InProgress) {
        status = pick.outcome === Outcome.Less ? statusType.IN_PROGRESS_LESS : statusType.IN_PROGRESS_MORE;
    } else {
        status = pick.result !== undefined ? resultMap[pick.result] || statusType.UPCOMING : statusType.UPCOMING;
    }
    const [source, setSource] = useState<{ uri: string }>();
    const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(false);
    const isInProgress = status.includes('in_progress');
    const borderColor = colorMap[status] || 'gray5';
    const isPlaceholderImage = isPlayerImgPlaceholder(pick.player.icon) || shouldShowPlaceholder;
    const playerNumber = getPlayerJerseyNumber(pick.league, pick.player?.jerseyNumber);
    const hasPlayerNumber = typeof playerNumber === 'number';
    const isCBB = pick.league === League.Cbb;

    useEffect(() => {
        pick.player.icon && setSource({ uri: addResizeParamToURL(pick.player.icon) });
    }, [hasPlayerNumber, pick.player.icon]);

    return (
        <Box height={45}>
            <Box
                backgroundColor={'gray6'}
                width={40}
                height={40}
                borderRadius={'r40'}
                borderWidth={2}
                borderColor={borderColor}
                alignItems={'center'}
                justifyContent={'center'}
                style={styles.shadow}
            >
                {hasPlayerNumber ? (
                    <>
                        <Box style={styles.collegeShirtContainer}>
                            {isCBB ? (
                                <CollegeBasketballShirt
                                    playerId={pick.player.id}
                                    teamColor={pick.teamColor}
                                    secondaryColor={pick.secondaryTeamColor}
                                    width={40}
                                    height={40}
                                    blur={10}
                                    maskWidth={80}
                                    maskHeight={80}
                                    maskX={-20}
                                    maskY={-20}
                                />
                            ) : (
                                <CollegeFootballShirt
                                    playerId={pick.player.id}
                                    teamColor={pick.teamColor}
                                    secondaryColor={pick.secondaryTeamColor}
                                    width={40}
                                    height={40}
                                    blur={5}
                                    maskR={45}
                                />
                            )}
                        </Box>
                        <Svg style={styles.collegeSvgNumber}>
                            <SvgText
                                fill={designSystem.colors.white}
                                stroke={designSystem.colors.gray8}
                                strokeWidth="0.5"
                                y={10}
                                x={36 / 2}
                                fontSize="13"
                                fontWeight="600"
                                textAnchor="middle"
                                fontFamily="Oswald-SemiBold"
                                letterSpacing={-1.2}
                            >
                                {playerNumber}
                            </SvgText>
                        </Svg>
                    </>
                ) : isPlaceholderImage ? (
                    <Box style={styles.placeholderContainer}>
                        <PlayerImagePlaceholder width={40} height={40} />
                    </Box>
                ) : source ? (
                    <Image
                        resizeMode={'cover'}
                        source={source}
                        style={styles.image}
                        onError={() => {
                            if (source?.uri !== pick.player.icon) {
                                setSource({ uri: pick.player.icon });
                            } else {
                                setShouldShowPlaceholder(true);
                            }
                        }}
                    />
                ) : null}
                {isInProgress ? (
                    <Box style={styles.progressBar}>
                        <EntryProgressBar />
                    </Box>
                ) : null}
            </Box>
            {status !== statusType.UPCOMING ? <StatusCircle status={status} /> : null}
        </Box>
    );
};

const StatusCircle = ({ status }: { status: string }) => {
    const circleColor = colorMap[status] || 'white';
    const isInProgress = status.includes('in_progress');

    return (
        <Box
            backgroundColor={circleColor}
            width={16}
            height={16}
            borderRadius={isInProgress ? 'r6' : 'r16'}
            position={'absolute'}
            bottom={0}
            left={12}
            alignItems={'center'}
            justifyContent={'center'}
        >
            {status !== statusType.UPCOMING ? iconsMap[status] : null}
        </Box>
    );
};

const styles = StyleSheet.create({
    image: {
        width: 36,
        height: 36,
        borderRadius: 38,
    },
    progressBar: {
        position: 'absolute',
    },
    placeholderContainer: {
        width: 36,
        height: 36,
        borderRadius: 18,
        overflow: 'hidden',
        alignItems: 'center',
        paddingTop: 3,
    },
    collegeShirtContainer: {
        width: 36,
        height: 36,
        alignItems: 'center',
        borderRadius: 18,
        overflow: 'hidden',
        paddingTop: 1,
    },
    collegeSvgNumber: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 16,
    },
    shadow: {
        shadowOffset: {
            height: 2,
            width: 0,
        },
        shadowRadius: 8,
        shadowOpacity: 0.8,
        shadowColor: 'rgb(0, 0, 0)',
        elevation: 10,
    },
});
