import { PaymentMethodTypes } from '@/feature/deposit/const';

import { Action, Transaction } from '../hooks/types';

export enum TransactionStatus {
    withdrawalSuccess = 'WITHDRAWAL',
    withdrawalPending = 'WITHDRAWAL_ATTEMPT',
    withdrawalFailed = 'WITHDRAWAL_FAILED',
    withdrawalCancelled = 'WITHDRAWAL_CANCELLED',
    paymentSuccess = 'PAYMENT_CONFIRMED',
    paymentPending = 'PAYMENT_CREATED',
    paymentAborted = 'PAYMENT_ABORTED',
    paymentFailed = 'PAYMENT_FAILED',
    paymentDeclined = 'PAYMENT_DECLINED',
    paymentExpired = 'PAYMENT_EXPIRED',
    paymentCancelled = 'PAYMENT_CANCELLED',
}

export enum FailedTransactionReason {
    accountAlreadyUsed = 'account_already_used',
    wrongAccount = 'wrong_account',
    paymentIncomplete = 'payment_incomplete',
}

/**
 * Check if a transaction is failed or not
 * @param status - Transaction status
 */
export const isFailedTransaction = (status: string) => {
    switch (status) {
        case TransactionStatus.withdrawalFailed:
        case TransactionStatus.withdrawalCancelled:
        case TransactionStatus.paymentFailed:
        case TransactionStatus.paymentAborted:
        case TransactionStatus.paymentDeclined:
        case TransactionStatus.paymentCancelled:
        case TransactionStatus.paymentExpired:
            return true;
        default:
            return false;
    }
};

/**
 * Check if a transaction is pending or not
 * @param status - Transaction status
 */
export const isPendingTransaction = (status: string) => {
    switch (status) {
        case TransactionStatus.paymentPending:
        case TransactionStatus.withdrawalPending:
            return true;
        default:
            return false;
    }
};

/**
 * Get sub text for a transaction
 * @param transaction - Transaction details
 */
export const getSubText = (transaction: Transaction) => {
    const { amount, email, cc_last4, failed_reason, payment_method, action, venmo_id } = transaction;

    if (failed_reason === FailedTransactionReason.accountAlreadyUsed) {
        return 'Paypal linked to another user';
    }
    if (failed_reason === FailedTransactionReason.wrongAccount) {
        return 'Cannot add multiple paypal accts';
    }

    // Based on payment method
    switch (payment_method) {
        case PaymentMethodTypes.Paysafe:
            switch (action) {
                case 'deposit':
                    return `From debit card ending in ${cc_last4} to Betr`;
                case 'withdrawal':
                    return `To debit card ending in ${cc_last4} from Betr`;
            }
            break;
        case PaymentMethodTypes.PaysafeMazooma:
            switch (action) {
                case 'deposit':
                    return `From account ending in ${cc_last4} to Betr`;
                case 'withdrawal':
                    return `To account ending in ${cc_last4} from Betr`;
            }
            break;
        case PaymentMethodTypes.PaysafePaypal:
            const transactionParty = cc_last4 ? `Card ending in ${cc_last4}` : email;
            const isPositiveAmount = amount >= 0;
            const defaultSubtext = `${isPositiveAmount ? 'From' : 'To'} ${transactionParty} ${
                isPositiveAmount ? 'to' : 'from'
            } Betr`;
            return defaultSubtext; // If it's paypal, the subtext depends on the amount
        case PaymentMethodTypes.PaysafeVenmo:
            const venmoId = venmo_id ? ` for ${venmo_id} ` : ' ';
            switch (action) {
                case 'deposit':
                    return `From Venmo${venmoId}to Betr`;
                case 'withdrawal':
                    return `To Venmo${venmoId}from Betr`;
            }
            break;
        default:
            return '';
    }
};

export const getActionTranslation = (action: Action) => {
    if (action === 'fre_bonus') {
        return 'betr_bucks';
    }
    return action;
};
