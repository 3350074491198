import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';

import { Loading } from '@/components/Loading';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components/Box';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { PlayerRow } from '@/feature/lobby/components/PlayerRow';
import { useLobbyPlayers } from '@/feature/lobby/hooks/use-lobby-players';
import { usePickSelection } from '@/feature/lobby/hooks/use-pick-selection';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { common } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { FlashList } from '@shopify/flash-list';

import { PlayerAndEventInfo, usePlayersSearch } from '../hooks/use-players-search';
import { mapTrendingPlayersToSearchPlayers } from '../util/search-util';

export const SearchResults = ({
    query,
    league,
    animationIsInProgress,
}: {
    query: string;
    league: League;
    animationIsInProgress: boolean;
}) => {
    const { openPlayerPickModal, makeSelection, removeSelection } = usePickSelection();
    const { betslip, validating } = useBetslipStore();

    const { t } = useTranslation(['search']);
    const trendingPlayersTitle = t('trending_players', { league });

    const { playersToDisplay: searchPlayers, loading } = usePlayersSearch(query, league);
    const { trendingPlayers } = useLobbyPlayers({ requestPolicy: 'cache-first' });

    const trendingPlayersByLeague = useMemo(() => {
        return mapTrendingPlayersToSearchPlayers(trendingPlayers).filter(player => player.event.league === league);
    }, [trendingPlayers, league]);

    const players = useMemo(() => {
        // if there's no query, show the trending players
        return query.length === 0 ? trendingPlayersByLeague : searchPlayers;
    }, [trendingPlayersByLeague, query.length, searchPlayers]);

    const openCardAndDismissKeyboard = useCallback(
        (pick: PlayerCardProps) => {
            Keyboard.dismiss();
            openPlayerPickModal(pick);
            BetrAnalytics.trackEvent(AnalyticsEvent.SEARCH_PLAYER_SELECTED, {
                league: league,
                trending: query.length === 0,
            });
        },
        [openPlayerPickModal, league, query]
    );

    const renderItem = useCallback(
        ({ item }: { item: PlayerAndEventInfo }) => {
            const playerPickedEntry = isPlayerSelected({
                eventId: item.event.id,
                playerId: item.player.id,
            });

            if (playerPickedEntry) {
                return (
                    <MaxWidthWrapper flex={1}>
                        <PlayerRow
                            player={item.player}
                            projection={playerPickedEntry.projection}
                            mode={'selection'}
                            event={item.event}
                            outcome={playerPickedEntry.outcome}
                            pressable
                            testID={'testID'}
                            analyticsTag={'searchPage'}
                            openPlayerPickModal={openCardAndDismissKeyboard}
                            removeSelection={removeSelection}
                            makeSelection={makeSelection}
                        />
                    </MaxWidthWrapper>
                );
            }
            return (
                <MaxWidthWrapper flex={1}>
                    <PlayerRow
                        player={item.player}
                        mode={'all'}
                        event={item.event}
                        pressable
                        testID={'testID'}
                        analyticsTag={'searchPage'}
                        openPlayerPickModal={openCardAndDismissKeyboard}
                        removeSelection={removeSelection}
                        makeSelection={makeSelection}
                    />
                </MaxWidthWrapper>
            );
        },
        [makeSelection, openCardAndDismissKeyboard, removeSelection]
    );

    const shouldShowTrending = query.length === 0;

    if (players.length === 0 && loading) {
        return (
            <Box flex={1} justifyContent={'center'} alignItems={'center'}>
                <Loading />
            </Box>
        );
    }

    if ((shouldShowTrending && players.length === 0) || animationIsInProgress) {
        return null;
    }

    if (players.length === 0) {
        return (
            <Box flex={1} justifyContent={'center'} alignItems={'center'} px={'s16'} gap={'s4'}>
                <Text variant={'titleLarge'}>{t('no_results_found')}</Text>
                <Text variant={'bodyMedium'} textAlign={'center'} color={'gray2'}>
                    {t('no_results', { query })}
                </Text>
            </Box>
        );
    }

    return (
        <Box flex={1}>
            <FlashList
                keyboardDismissMode={'on-drag'}
                keyboardShouldPersistTaps={'handled'}
                data={players}
                extraData={JSON.stringify({ ...betslip, validating })}
                ListHeaderComponent={
                    shouldShowTrending ? (
                        <MaxWidthWrapper>
                            <Box mt={'s20'} mb={'s8'}>
                                <Text variant={'headlineSmall'} fontWeight={700}>
                                    {trendingPlayersTitle}
                                </Text>
                            </Box>
                        </MaxWidthWrapper>
                    ) : null
                }
                contentContainerStyle={common.paddingHorizontal}
                estimatedItemSize={100}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
            />
        </Box>
    );
};

const keyExtractor = (item: PlayerAndEventInfo, index: number) => `${item.player.id}-${index}`;
