import { EventInfo, EventInfoWithoutPlayers } from '@/api/events/types/types';
import { PICKEM_SPORT_SECTION_KEYS, SECTION_SCREENS, Sections } from '@/feature/lobby/utils/tabs/types';
import { League } from '@/types/api.generated';
import { ProductDocumentDataBodyLeaguepagetabsorderSliceItem } from '@/types/prismic.generated';
import { isPGA, isUFC, nflFullSeason } from '@/utils/league';

import { LeagueType } from '../../hooks/use-league-selector';

export const DEFAULT_SECTIONS_ORDER: ProductDocumentDataBodyLeaguepagetabsorderSliceItem[] = [
    { key: 'GAMES' },
    { key: 'PLAYERS' },
    { key: 'PROJECTIONS' },
];

const sectionsByLeague: Record<League, Partial<Sections>> = {
    [League.Ufc]: {
        GAMES: { label: 'Fights' },
        PLAYERS: { label: 'Fighters' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Nhl]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Nfl]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Nba]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Mlb]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.NflFullSeason]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Pga]: {
        GAMES: { label: 'Events' },
        PLAYERS: { label: 'Golfers' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Simulation]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Cfb]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
    [League.Cbb]: {
        GAMES: { label: 'Games' },
        PLAYERS: { label: 'Players' },
        PROJECTIONS: { label: 'Projections' },
    },
};

export const getSectionsByLeague = (
    league: { id: League; label: string },
    isSingleEvent?: boolean
): Partial<Sections> => {
    const isNflFullSeason = nflFullSeason(league.id);
    const { GAMES, ...section } = sectionsByLeague[league.id];
    return isSingleEvent || isNflFullSeason ? section : { GAMES, ...section };
};

export const getLeagueCategoryLabel = (league: League) => {
    const isUfc = isUFC(league);
    const isPga = isPGA(league);

    if (isUfc) {
        return 'fight';
    } else if (isPga) {
        return 'event';
    } else {
        return 'game';
    }
};

export const TABS_NAMES: SECTION_SCREENS[] = ['PickemSport-GAMES', 'PickemSport-PLAYERS', 'PickemSport-PROJECTIONS'];

export const sectionsUtilsData = (
    sortedEvents: (EventInfo | EventInfoWithoutPlayers)[],
    sectionsOrdering: ProductDocumentDataBodyLeaguepagetabsorderSliceItem[],
    selectedLeague?: LeagueType
) => {
    const isUfcEventPageEnabled =
        isUFC(selectedLeague?.id) && !!sortedEvents.find(event => event.headerImage && event.venueDetails);
    const isSingleEvent = sortedEvents.length === 1;
    const eventPageEnabled = isSingleEvent ? sortedEvents[0].dedicated : isUfcEventPageEnabled;
    const firstEvent = (isSingleEvent && eventPageEnabled) || isUfcEventPageEnabled ? sortedEvents[0] : null;

    const sectionsOrderingKeys = sectionsOrdering.map(section => section.key) as PICKEM_SPORT_SECTION_KEYS[];
    const allSectionsByLeague: Partial<Sections> =
        selectedLeague !== undefined
            ? getSectionsByLeague(selectedLeague, eventPageEnabled && !isUfcEventPageEnabled)
            : {};
    const allSectionsForLeague = Object.keys(allSectionsByLeague);
    const sections = sectionsOrderingKeys.filter(section => allSectionsForLeague.includes(section));
    return {
        isUfcEventPageEnabled,
        eventPageEnabled,
        firstEvent,
        allSectionsByLeague,
        sections,
    };
};
