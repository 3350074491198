import React from 'react';
import { useTranslation } from 'react-i18next';

import AlertStraightTriangleIcon from '@/assets/icons/alertStraightTriangle';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useIsSuspendedOption } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { totalPayoutSelector } from '@/feature/betslip-sbk/utils/betslip-selectors';
import { designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { Banner } from '../Banner';
import { BetInfoSheet } from '../bet-info-sheet/BetInfoSheet';
import { useBetSummary } from './hooks/use-bet-summary';
import { useTotalStakeErrorMessages } from './hooks/use-total-stake-error-message';

export const BetSummary = () => {
    const { t } = useTranslation('betslip_sbk');

    const totalPayout = useSbkBetSlipStore(totalPayoutSelector);
    const bets = useBetSummary();
    const errorMessages = useTotalStakeErrorMessages();
    const isSuspendedOption = useIsSuspendedOption();

    const renderErrorMessage = () => {
        if (errorMessages.length > 0 || isSuspendedOption) {
            return (
                <Box pb="s12">
                    {isSuspendedOption ? (
                        <Box pb="s12">
                            <Banner
                                backgroundColor="gray5"
                                textColor="gray1"
                                leftIcon={
                                    <AlertStraightTriangleIcon
                                        width="24"
                                        height="24"
                                        fill={designSystem.colors.gray1}
                                    />
                                }
                                message={t('suspended_selection_bet_summary_description')}
                            />
                        </Box>
                    ) : null}
                    {errorMessages.map((msg, index) => (
                        <Box pb="s12" key={index}>
                            <Banner
                                backgroundColor="gray5"
                                textColor="gray1"
                                leftIcon={
                                    <AlertStraightTriangleIcon width="24" height="24" fill={designSystem.colors.red} />
                                }
                                message={msg}
                                testID={index.toString()}
                            />
                        </Box>
                    ))}
                </Box>
            );
        }
    };

    return (
        <BetInfoSheet
            header={
                <>
                    {renderErrorMessage()}
                    <Text variant="headlineSmall" color="gray1" textAlign="center" testID="betSummaryTitle">
                        {t('bet_summary')}
                    </Text>
                    <Text variant="bodyMedium" color="gray2" textAlign="center">
                        {t('total_payout')}: {toLocaleCurrency(totalPayout)}
                    </Text>
                </>
            }
            bets={bets}
            footerHeight={150}
        />
    );
};
