import React from 'react';
import { Pressable, View } from 'react-native';

import { CurrencyInput } from '@/components/CurrencyInput';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';

import { betPayoutSelector } from '../utils/betslip-selectors';
import { fromPayoutInput } from '../utils/formatter';

export const PayoutInput = ({ betId }: { betId: string }) => {
    const payout = useSbkBetSlipStore(betPayoutSelector(betId));
    return (
        <Pressable onPress={() => GASbkEvents.clickPayoutSection()}>
            <View pointerEvents="none">
                <CurrencyInput editable={false} label="Payout" value={fromPayoutInput(payout)} />
            </View>
        </Pressable>
    );
};
