import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        <Path
            fill={props.color || designSystem.colors.gray1}
            fillRule="evenodd"
            d="M6.442 4.16a.93.93 0 0 0-.755.78L4.155 15.277a.466.466 0 0 0 .54.526l3.207-.567a.464.464 0 0 1 .527.592L6.772 21.4a.466.466 0 0 0 .495.596.464.464 0 0 0 .271-.127l12.169-11.629a.466.466 0 0 0-.402-.794l-3.074.543a.464.464 0 0 1-.496-.668l3.362-6.638a.467.467 0 0 0-.241-.652.463.463 0 0 0-.257-.023L6.442 4.16Zm5.26 6.335c.566.225.912.367.79-.333a2.333 2.333 0 0 0-1.787-1.871 2.32 2.32 0 0 0-2.408.937 2.335 2.335 0 0 0-.385 1.741c.124.7.407.445.854.044.353-.318.81-.728 1.368-.827.559-.098 1.121.13 1.569.309Z"
            clipRule="evenodd"
            {...props}
        />
    </Svg>
);
export default SvgComponent;
