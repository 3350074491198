import React, { useCallback, useMemo } from 'react';
import { FlatList, TouchableWithoutFeedback } from 'react-native';

import AlertStraightTriangleIcon from '@/assets/icons/alertStraightTriangle';
import { Box, Row } from '@/components/lib/components';
import { ComboBetName } from '@/feature/betslip-sbk/components/combo-card/ComboBetName';

import { useSbkBetSlipStore } from '../../hooks/use-sbk-betslip-store';
import { BetIds } from '../../utils/betslip-utils';
import { Banner } from '../Banner';
import { BetInputs } from '../bet-inputs/BetInputs';
import { ComboBetList } from './ComboBetList';
import { ComboOdds } from './ComboOdds';
import { useComboBetCardAlert } from './hooks/use-combo-bet-card-alert';

export const ComboCard = () => {
    const setShowKeyboard = useSbkBetSlipStore(state => state.actions.setShowKeyboard);
    const orderedEventIds = useSbkBetSlipStore(state => state.eventOrder);
    const betId = BetIds.COMBO;
    const lastOrderedEventId = useMemo(() => orderedEventIds[orderedEventIds.length - 1], [orderedEventIds]);

    const alerts = useComboBetCardAlert();
    const isDisabled = !!alerts.length;

    const renderItem = useCallback(
        ({ item: eventId }: { item: string }) => {
            const isLastEvent = eventId === lastOrderedEventId;
            return (
                <ComboBetList
                    isLastEvent={isLastEvent}
                    eventId={eventId}
                    testID={orderedEventIds.indexOf(eventId).toString()}
                />
            );
        },
        [lastOrderedEventId, orderedEventIds]
    );

    return (
        <TouchableWithoutFeedback onPress={() => setShowKeyboard(false)} accessible={false}>
            <Box pb="s20" mb="s20">
                {isDisabled ? (
                    <Box gap="s16" mb="s16" testID="inactiveCombosWarningMsg">
                        {alerts.map(alert => (
                            <Banner
                                key={alert.type}
                                leftIcon={<AlertStraightTriangleIcon width={24} height={24} />}
                                message={alert.message}
                                backgroundColor="gray6"
                            />
                        ))}
                    </Box>
                ) : (
                    <>
                        <Row justifyContent="space-between" alignItems="center" mb="s12">
                            <ComboBetName />
                            <ComboOdds variant="titleMedium" color="gray1" testID="comboOdds" />
                        </Row>
                        <BetInputs betId={betId} />
                    </>
                )}

                <TouchableWithoutFeedback onPress={() => setShowKeyboard(false)} accessible={false}>
                    <FlatList
                        keyExtractor={eventId => `event${eventId}`}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        data={orderedEventIds}
                        renderItem={renderItem}
                    />
                </TouchableWithoutFeedback>
            </Box>
        </TouchableWithoutFeedback>
    );
};
