import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateData } from 'react-native-calendars/src/types';

import { useCalendarSheet } from '@/feature/transactions/components/CalendarSheetProvider';
import {
    formatCalendarDateObjectToMMDDYYYY,
    formatDateToCalendarObject,
} from '@/feature/transactions/utils/calendar-helper';

import { validateDob } from '../../components/utils';
import { useKycFieldsStore } from '../../hooks/use-kyc-fields';

export const shouldDisableNextButtonForDobStep = (dateOfBirth: string) =>
    dateOfBirth.length === 0 || !validateDob(dateOfBirth);

export const useDobCalendarForWeb = () => {
    const { t } = useTranslation('kyc');
    const values = useKycFieldsStore(state => state.values);
    const setFieldValue = useKycFieldsStore(state => state.setFieldValue);

    const initialSelectedDate = useMemo(
        () => (values.dateOfBirth.length === 8 ? [formatDateToCalendarObject(values.dateOfBirth)] : []),
        [values.dateOfBirth]
    );

    const [selectedDate, setSelectedDate] = useState<DateData[]>(initialSelectedDate);

    const { showCalendarSheetModal } = useCalendarSheet();

    const handleApplyCalendarDate = (date: DateData[]) => {
        setSelectedDate(date);
        const dateOfBirth = formatCalendarDateObjectToMMDDYYYY(date[0]); // An array of DateData objects to match the expected format of the react-native-calendar library
        setFieldValue('dateOfBirth', dateOfBirth);
    };

    const showCalendar = () => {
        showCalendarSheetModal({
            onApply: handleApplyCalendarDate,
            closeIconMode: 'close',
            allowRangeSelection: false,
            selectedDates: selectedDate,
        });
    };

    useEffect(() => {
        showCalendar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Ensure the calendar is shown on user's first visit

    const errorMessage = useMemo(() => {
        return values.dateOfBirth.length === 8 && !validateDob(values.dateOfBirth) ? t('dob_error_age') : '';
    }, [t, values.dateOfBirth]);

    return {
        values,
        setFieldValue,
        selectedDate,
        showCalendar,
        errorMessage,
    };
};
