import React, { useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { useIsFocused, useNavigation } from '@react-navigation/native';

import ChevronRight from '@/assets/icons/chevron-right-small';
import { Text } from '@/components/TextComponent';
import { Box, Image, Row } from '@/components/lib/components';
import { useHasPublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Event } from '@/feature/event-details-sbk/types';
import { LeagueMarketEventCard } from '@/feature/league-hub-sbk/components/LeagueMarketEventCard';
import { useSubscribeLeagueMatchUpdate } from '@/feature/league-hub-sbk/hooks/use-subscribe-league-match-update';
import { DEFAULT_EVENT_LIMIT, useFeaturedLeagueData } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { useSubscribeFilteredLeagueOddsUpdate } from '@/feature/lobby-sbk/hooks/use-subscribe-filtered-league-odds-update';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { SbkLobbyTabNavigationProp } from '@/navigation/sbk/types';
import { common } from '@/styles/styles';

const IMAGE_SIZE = 28;
const keyExtractor = (item: Event) => item.id;

export type FeaturedLeague = {
    id: string;
    name: string;
    imageUrl?: string;
    eventCount?: number;
};

type FeaturedLeagueProps = {
    featuredLeague: FeaturedLeague;
    marketCode?: string;
};

export const FeaturedLeagueSection = ({
    featuredLeague: { id: leagueId, name, imageUrl, eventCount = DEFAULT_EVENT_LIMIT },
}: FeaturedLeagueProps) => {
    const navigation = useNavigation<SbkLobbyTabNavigationProp>();
    const isFocused = useIsFocused();
    const { marketIds, eventMarkets, events, marketCategory, marketCategoryId, refetch } = useFeaturedLeagueData(
        leagueId,
        eventCount,
        isFocused
    );

    const shouldSubscribe = isFocused && !!marketIds.length && !!marketCategoryId;
    useSubscribeLeagueMatchUpdate(leagueId, shouldSubscribe);
    useSubscribeFilteredLeagueOddsUpdate(leagueId, marketCategoryId, marketIds, shouldSubscribe);
    useFetchOnResume(refetch);

    const renderItem = useCallback(
        ({ item: event }: { item: Event }) => {
            const markets = eventMarkets[event.id] ?? [];
            return <LeagueMarketEventCard event={event} markets={markets} testID={events.indexOf(event).toString()} />;
        },
        [eventMarkets, events]
    );

    const hasPublishedMarkets = useHasPublishedMarkets(marketCategory ? [marketCategory] : []);
    if (!hasPublishedMarkets) {
        return null;
    }

    return (
        <Box>
            <TouchableOpacity
                style={styles.headerButton}
                onPress={() => navigation.navigate('LeagueHub', { leagueId, name })}
            >
                <Row px="s16" mb="s8">
                    {imageUrl ? (
                        <Image
                            source={{ uri: imageUrl }}
                            resizeMode="contain"
                            style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }}
                        />
                    ) : null}
                    <Box flexShrink={1}>
                        <Text variant="headlineMedium" marginLeft="s12" lineHeight={28} mr="s12" numberOfLines={1}>
                            {name}
                        </Text>
                    </Box>
                    <Box
                        backgroundColor="gray6"
                        width={28}
                        height={28}
                        borderRadius="r40"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ChevronRight />
                    </Box>
                </Row>
            </TouchableOpacity>

            <FlatList
                data={events}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                scrollEnabled={false}
                contentContainerStyle={[common.grow, common.paddingHorizontal]}
            />
        </Box>
    );
};

const styles = StyleSheet.create({
    headerButton: {
        alignSelf: 'flex-start',
    },
});
