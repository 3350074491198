import React from 'react';

import CollegeFootballShirtSvg from '@/assets/images/CollegeFootballShirtSvg';
import { footballDefaultValues } from '@/components/player-profile/tiles/college-sports/config';
import { CollegeFootballProps } from '@/components/player-profile/tiles/college-sports/types';

export const CollegeFootballShirt = ({
    teamColor,
    secondaryColor,
    width = footballDefaultValues.width,
    height = footballDefaultValues.height,
    blur = footballDefaultValues.blur,
    // the default values are divided by 1.5 to scale the mask properties
    // if we pass different values for the mask properties, we need to scale them accordingly
    // that's why here, the default values are divided by 1.5, since the mask properties are scaled to 150%
    // but if we send different values for the mask properties, we need to scale them accordingly
    maskCX = footballDefaultValues.maskCX / 1.5,
    maskCY = footballDefaultValues.maskCY / 1.5,
    maskR = footballDefaultValues.maskR / 1.5,
    playerId,
}: CollegeFootballProps) => {
    return (
        <CollegeFootballShirtSvg
            secondaryColor={secondaryColor}
            color={teamColor}
            height={height}
            width={width}
            // The SVG mask on web is scaled to 150% of the original size
            // so we need to scale the mask properties accordingly
            blur={blur * 1.5}
            r={maskR * 1.5}
            cx={maskCX * 1.5}
            cy={maskCY * 1.5}
            id={playerId}
        />
    );
};
