import { useCallback } from 'react';

import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { validateStakeInput } from '@/feature/betslip-sbk/utils/validators';
import { activeLimitsSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { useWallet } from '@/hooks/use-wallet';

/**
 * Returns a function that validates stake input and updates betslip state with the stake input errors
 */
export const useStakeInputValidation = () => {
    const { data: limits } = useUserLimits({ select: activeLimitsSelector('SINGLE_WAGER') });
    const updateInputStakeError = useSbkBetSlipStore(state => state.actions.updateStakeInputErrors);
    const { realMoneyTotal: walletCashBalance, betrBucks: walletBetrBucksBalance } = useWallet();

    return useCallback(
        (betId: string, betStake: number, isBetrBucks: boolean, stakeLimit?: number) => {
            if (betStake === 0) {
                updateInputStakeError(betId, undefined);
            } else {
                const error = validateStakeInput({
                    limits: limits ?? [],
                    betStake,
                    walletCashBalance,
                    walletBetrBucksBalance,
                    isBetrBucks,
                    stakeLimit,
                });
                updateInputStakeError(betId, error);
            }
        },
        [limits, updateInputStakeError, walletBetrBucksBalance, walletCashBalance]
    );
};
