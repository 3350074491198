import AsyncStorage from '@react-native-async-storage/async-storage';

import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { BetStatus } from '@/feature/bets-sbk/hooks/types';
import {
    generateBetsPayload,
    getChannel,
    getGeoToken,
} from '@/feature/betslip-sbk/utils/bet-submission/generate-bet-payload';
import { delay, pollSubmittedBets } from '@/feature/betslip-sbk/utils/bet-submission/poll-submitted-bets';
import { submitBets } from '@/feature/betslip-sbk/utils/bet-submission/submit-bet';
import { PlaceBetsRequest } from '@/feature/betslip-sbk/utils/bet-submission/types';
import {
    generateSgpOddsId,
    getActiveEventIds,
    getAdjustedOdds,
    getBetSummary,
    groupSelectionIdsByEvent,
    handleOddsUpdateMessages,
    isComboSelectionEnabled,
} from '@/feature/betslip-sbk/utils/betslip-utils';
import { UserSettings } from '@/hooks/use-auth-user-settings';
import { validateLocationAccuracy } from '@/hooks/use-entries';
import { Currency } from '@/types/api.generated';
import { logger } from '@/utils/logging';
import { MatchUpdateMessage, OddsUpdateMessageOption } from '@/utils/websocket/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import {
    AddSelectionError,
    AddSelectionErrors,
    BetSlipEvent,
    BetSlipMarket,
    BetSlipOption,
    BetSubmissionStatus,
    BetSubmissionSummary,
    BetType,
    MAX_SELECTIONS,
    SBKBetSlip,
    SelectionParam,
    StakeInputError,
    TotalStakeError,
} from '../types';
import {
    clearBetSlip,
    getEventDetails,
    getOddsChangeIndicatorTimeout,
    handleSgpError,
    handleSgpToggleOff,
    keepBetSlipSelections,
    toggleComboSelectionStatus,
    updateSgpOdds,
    updateStake,
    updateStakeCurrency,
    updateStakeInputErrors,
} from '../utils/betslip-actions';
import { addSelection } from '../utils/betslip-actions/add-selection';
import { removeSelection } from '../utils/betslip-actions/remove-selection';
import {
    addMultipleSelections,
    addSgpOdds,
    exceedsMaxSelections,
    handleConflictingSelectionsError,
} from '../utils/betslip-add-selections-actions';
import { validBetsSelector } from '../utils/betslip-selectors';

export const SBK_BETSLIP_LOG_TAG = '[Sbk Betslip]';
const POLL_INTERVAL_MS = 1000;
const MAX_POLL_RETRIES = 5;

export type SBKBetSlipState = Omit<SBKBetSlip, 'actions'>;

export const initialState: SBKBetSlipState = {
    selections: {},
    events: {},
    markets: {},
    options: {},
    bets: {},
    sgpOdds: {},
    selectionOrder: [],
    eventOrder: [],
    editBetId: null,
    lastToggledSelectionId: null,
    showKeyboard: false,
    totalStakeErrors: [],
    betSubmissionStatus: BetSubmissionStatus.Idle,
    submittedBets: {},
    submittedState: {
        selections: {},
        events: {},
        markets: {},
        options: {},
        singlesBets: [],
        submittedBets: {}, // We store submitted bets to get access to the global bet ids from PAM
    },
    closedSelections: [],
    oddsChanges: {}, // We store the old odds here to show it in the MultiplierChanges bottom sheet. The format is { selectionId: oldOdds }
    sgpOddsChanges: {},
    sgpEventDisabled: {},
    sgpTemporaryIssue: {},
    useBetrBucks: false,
    userSettings: null,
    oddsChangeTimeout: null,
    producerStatus: 'UP',
    isSgpFetching: false,
    betSubmissionStartTime: null,
    keepSelectionsInBetSlip: false,
};

export const useSbkBetSlipStore = create<SBKBetSlip>()(
    persist(
        (set, get) => ({
            ...initialState,
            actions: {
                addSelection: (
                    option: BetSlipOption,
                    market: BetSlipMarket,
                    event: BetSlipEvent,
                    onAddSelectionError: (error: AddSelectionError, selectionId?: string) => void
                ) => {
                    // check if max selections reached
                    const totalSelections = get().selectionOrder.length;
                    if (totalSelections === MAX_SELECTIONS) {
                        onAddSelectionError(AddSelectionErrors.MaxSelections);
                        return;
                    }

                    // add selections
                    GASbkEvents.addSelection(market.id, option.id, totalSelections + 1);
                    set(state => addSelection(state, option, market, event));

                    // get sgp odds
                    const handleError = () => {
                        onAddSelectionError(AddSelectionErrors.ConflictingSelections, option.id);
                    };
                    get().actions.updateSgpOdds({ onConflictingError: handleError });
                },
                addComboFeaturedBet: (
                    selections: SelectionParam[],
                    onAddSelectionError: (error: AddSelectionError, selectionIds?: string[]) => void
                ) => {
                    const state = get();

                    // check if max selections reached
                    const error = exceedsMaxSelections(state.selectionOrder, selections, onAddSelectionError);
                    if (error) {
                        return;
                    }

                    // update state
                    set({ ...addMultipleSelections(state, selections) });

                    // track event
                    const existingSelectionCount = state.selectionOrder.length;
                    GASbkEvents.addFeaturedBet(selections, existingSelectionCount + selections.length);

                    // update sgp odds if there are selections from the same event already in the bet slip
                    const betSlipHasSelectionsFromSameEvent = selections.some(selection =>
                        state.eventOrder.includes(selection.event.id)
                    );

                    if (betSlipHasSelectionsFromSameEvent) {
                        state.actions.updateSgpOdds({
                            onConflictingError: () =>
                                handleConflictingSelectionsError(selections, state.selectionOrder, onAddSelectionError),
                        });
                    }
                },
                addSgpFeaturedBet: (
                    selections: SelectionParam[],
                    sgpOdds: Record<string, number>,
                    onAddSelectionError: (error: AddSelectionError, selectionIds?: string[]) => void
                ) => {
                    const state = get();

                    // check if max selections reached
                    const error = exceedsMaxSelections(state.selectionOrder, selections, onAddSelectionError);
                    if (error) {
                        return;
                    }

                    // update state
                    set({
                        ...addMultipleSelections(state, selections),
                        ...addSgpOdds(state.sgpOdds, selections, sgpOdds),
                    });

                    // track event
                    const existingSelectionCount = state.selectionOrder.length;
                    GASbkEvents.addFeaturedBet(selections, existingSelectionCount + selections.length);

                    // fetch latest sgp odds
                    state.actions.updateSgpOdds({
                        updateEventId: selections[0].event.id,
                        shouldHandleOddsChangeFlow: true,
                        onConflictingError: () =>
                            handleConflictingSelectionsError(selections, state.selectionOrder, onAddSelectionError),
                    });
                },
                addSgpPlusFeaturedBet: (
                    selections: SelectionParam[],
                    sgpOdds: Record<string, number>,
                    onAddSelectionError: (error: AddSelectionError, selectionIds?: string[]) => void
                ) => {
                    // TODO: reduce duplicate code between this and addSgpFeaturedBet
                    const state = get();

                    // check if max selections reached
                    const error = exceedsMaxSelections(state.selectionOrder, selections, onAddSelectionError);
                    if (error) {
                        return;
                    }

                    // update state
                    set({
                        ...addMultipleSelections(state, selections),
                        ...addSgpOdds(state.sgpOdds, selections, sgpOdds),
                    });

                    // track event
                    const existingSelectionCount = state.selectionOrder.length;
                    GASbkEvents.addFeaturedBet(selections, existingSelectionCount + selections.length);

                    // fetch latest sgp odds
                    state.actions.updateSgpOdds({
                        shouldHandleOddsChangeFlow: true,
                        onConflictingError: () =>
                            handleConflictingSelectionsError(selections, state.selectionOrder, onAddSelectionError),
                    });
                },
                removeSelection: (selectionId: string) => set(state => removeSelection(state, selectionId)),
                removeFeaturedBet: (selectionIds: string[]) => {
                    const removeSelectionAction = get().actions.removeSelection;
                    selectionIds.forEach(selectionId => {
                        removeSelectionAction(selectionId);
                    });
                },
                toggleComboSelectionStatus: (selectionId: string) => {
                    set(state => toggleComboSelectionStatus(state, selectionId));
                    get().actions.updateSgpOdds({});
                },
                toggleMultipleSelectionStatus: (selectionIds: string[]) => {
                    const state = get();
                    let newState = { ...state };
                    selectionIds.forEach(selectionId => {
                        newState = toggleComboSelectionStatus(newState, selectionId);
                    });
                    get().actions.updateSgpOdds({});
                },
                clearBetSlip: () => {
                    set(clearBetSlip);
                },
                clearBetSlipKeepSelections: () => {
                    set(state => (state.keepSelectionsInBetSlip ? keepBetSlipSelections(state) : clearBetSlip()));
                },
                toggleKeepSelectionsInBetSlip: () => {
                    set(state => ({ keepSelectionsInBetSlip: !state.keepSelectionsInBetSlip }));
                },
                updateStake: (betId: string, stake: number, displayStake: string, betType: BetType) => {
                    set(state => updateStake(state, betId, stake, displayStake, betType));
                },
                updateStakeCurrency: (betId: string, currency: Currency) => {
                    set(state => updateStakeCurrency(state, betId, currency));
                },
                updateUserSettings: (settings: UserSettings) => {
                    set({ userSettings: settings });
                },
                setEditingBet: (betId: string | null) => set({ editBetId: betId }),
                setShowKeyboard: (show: boolean) => set({ showKeyboard: show }),
                toggleUseBetrBucks: () => {
                    set(state => ({
                        useBetrBucks: !state.useBetrBucks,
                        bets: {
                            ...state.bets,
                            [state.editBetId!]: {
                                ...state.bets[state.editBetId!],
                                isBetrBucks: !state.useBetrBucks,
                            },
                        },
                    }));
                },
                updateSgpOdds: async ({
                    forceUpdate,
                    updatedEventId,
                    onConflictingError,
                    shouldHandleOddsChangeFlow = false,
                    isWebsocketUpdate = false,
                }: {
                    forceUpdate?: boolean;
                    updatedEventId?: string;
                    onConflictingError?: () => void;
                    shouldHandleOddsChangeFlow?: boolean;
                    isWebsocketUpdate?: boolean;
                }) => {
                    const state = get();
                    if (isWebsocketUpdate && state.isSgpFetching) {
                        return;
                    }
                    const selectionIdsByEvent = groupSelectionIdsByEvent(state);
                    for (const [eventId, selectionIds] of Object.entries(selectionIdsByEvent)) {
                        if (updatedEventId && updatedEventId !== eventId) {
                            continue;
                        }
                        if (selectionIds.length < 2) {
                            continue;
                        }

                        let newState = await updateSgpOdds({
                            state: get(),
                            eventId,
                            selectionIds,
                            shouldHandleOddsChangeFlow,
                            onFetchingStart: () => set({ isSgpFetching: !shouldHandleOddsChangeFlow }),
                            forceUpdate,
                        });

                        set(curState => {
                            newState = {
                                ...curState,
                                sgpOdds: {
                                    ...newState.sgpOdds,
                                },
                                sgpEventDisabled: {
                                    ...newState.sgpEventDisabled,
                                },
                                sgpTemporaryIssue: {
                                    ...newState.sgpTemporaryIssue,
                                },
                                sgpOddsChanges: {
                                    ...newState.sgpOddsChanges,
                                },
                            };

                            // SGP Service down
                            if (newState.sgpEventDisabled[eventId]) {
                                newState = handleSgpError(newState, selectionIds);
                            }
                            const sgpId = generateSgpOddsId(selectionIds, eventId);

                            // SGP Conflicting selections
                            if (!forceUpdate) {
                                if (newState.sgpOdds[sgpId] === false && !newState.sgpEventDisabled[eventId]) {
                                    onConflictingError?.();
                                }
                            }

                            // SGP Temporarily issue
                            if (newState.sgpTemporaryIssue[eventId]) {
                                newState = handleSgpToggleOff(newState, eventId);
                            }

                            newState.isSgpFetching = false;
                            let timeout = null;
                            if (shouldHandleOddsChangeFlow) {
                                timeout = getOddsChangeIndicatorTimeout({
                                    state: curState,
                                    oddsChanges: curState.oddsChanges,
                                });
                            }
                            newState.oddsChangeTimeout = timeout;
                            return newState;
                        });
                    }
                },
                removeSgpOddsByEventId: (eventId: string) => {
                    set(state => {
                        const newState = { ...state };
                        Object.keys(newState.sgpOdds).forEach(key => {
                            if (key.includes(eventId) && newState.sgpOdds[key] !== false) {
                                delete newState.sgpOdds[key];
                            }
                        });
                        return newState;
                    });
                },
                updateSgpOddsForAllEvents: () => {
                    const state = get();
                    const activeEventIds = getActiveEventIds(state);
                    activeEventIds.forEach(async eventId => {
                        await state.actions.updateSgpOdds({
                            updateEventId: eventId,
                            forceUpdate: true,
                            shouldHandleOddsChangeFlow: true,
                        });
                    });
                },
                updateStakeInputErrors: (betId: string, stakeInputError?: StakeInputError) => {
                    set(state => updateStakeInputErrors(state, betId, stakeInputError));
                },
                updateTotalStakeErrors: (errors?: TotalStakeError[]) => {
                    set({ totalStakeErrors: errors });
                },
                placeBets: async (userId: string, onFail: (error: unknown) => void) => {
                    set({
                        betSubmissionStatus: BetSubmissionStatus.Submitting,
                        betSubmissionStartTime: new Date().getTime(),
                    });

                    const state = get();
                    const betSettings = {
                        currency: 'USD',
                        channel: getChannel(),
                    } as const;

                    try {
                        await validateLocationAccuracy();
                    } catch (error) {
                        onFail(error);
                        logger.warn(
                            SBK_BETSLIP_LOG_TAG,
                            'Bet submission fail',
                            'Location accuracy validation failed',
                            error
                        );
                        state.actions.updateBetSubmissionStatus(BetSubmissionStatus.Error);
                        return;
                    }

                    let geotoken;
                    try {
                        geotoken = await getGeoToken();
                    } catch (error) {
                        onFail(error);
                        logger.warn(SBK_BETSLIP_LOG_TAG, 'Bet submission fail', 'Failed to generate GeoToken', error);
                        state.actions.updateBetSubmissionStatus(BetSubmissionStatus.Error);
                        return;
                    }

                    let payload: PlaceBetsRequest;
                    try {
                        payload = generateBetsPayload(userId, state, geotoken, betSettings);
                    } catch (error: unknown) {
                        onFail(error);
                        logger.warn(SBK_BETSLIP_LOG_TAG, 'Bet submission fail', 'Failed to generate payload', error);
                        state.actions.updateBetSubmissionStatus(BetSubmissionStatus.Error);
                        return;
                    }

                    try {
                        const resp = await submitBets(payload);
                        const validSubmittedBets = validBetsSelector(state).filter(bet => !!bet?.stake);
                        /*
                            Since the response of bet submission return in the same order with the payload,
                            we can map the local bet id with the global bet id by using the index of the response data
                        **/
                        const submittedBets = resp.data.reduce(
                            (acc, bet, index) => ({
                                ...acc,
                                [bet.id]: {
                                    status: 'UNCONFIRMED',
                                    globalId: '',
                                    localId: validSubmittedBets[index].id,
                                },
                            }),
                            {}
                        );
                        const singlesBets = validSubmittedBets.filter(bet => bet.betType === 'SINGLE');
                        const comboBet = validSubmittedBets.find(bet => bet.betType === 'COMBO');
                        set({
                            submittedBets,
                            submittedState: {
                                selections: state.selections,
                                events: state.events,
                                markets: state.markets,
                                options: state.options,
                                singlesBets,
                                comboBet: comboBet
                                    ? {
                                          odds: getAdjustedOdds(comboBet, state),
                                          selections: Object.values(state.selections).filter(selection =>
                                              isComboSelectionEnabled(selection, state)
                                          ),
                                          bet: comboBet,
                                      }
                                    : undefined,
                                submittedBets: {},
                            },
                        });
                    } catch (error: unknown) {
                        onFail(error);
                        logger.warn(SBK_BETSLIP_LOG_TAG, 'Bet submission fail', error, payload);
                        state.actions.updateBetSubmissionStatus(BetSubmissionStatus.Error);
                        return;
                    }
                },
                updateSubmittedBetStatus: (
                    betId: string,
                    status: BetStatus,
                    globalBetId: string,
                    rejectionReason: string,
                    onComplete: (success: boolean, summary?: BetSubmissionSummary) => void
                ) => {
                    const state = get();
                    if (betId in state.submittedBets) {
                        const submittedBets = {
                            ...state.submittedBets,
                            [betId]: {
                                ...state.submittedBets[betId],
                                status,
                                globalId: globalBetId,
                            },
                        };
                        set({ submittedBets, submittedState: { ...state.submittedState, submittedBets } });
                        const allBetsConfirmed = Object.values(submittedBets).every(bet => bet.status === 'CONFIRMED');
                        if (allBetsConfirmed) {
                            const globalBetIds = Object.values(submittedBets).map(bet => bet.globalId);
                            const summary = getBetSummary(state, globalBetIds);
                            state.actions.updateBetSubmissionStatus(BetSubmissionStatus.Success);
                            onComplete(true, summary);
                        }

                        const anyBetsRejected = Object.values(submittedBets).some(bet => bet.status === 'REJECTED');
                        if (anyBetsRejected) {
                            logger.warn(SBK_BETSLIP_LOG_TAG, 'Bet submission rejected', JSON.parse(rejectionReason));
                            state.actions.updateBetSubmissionStatus(BetSubmissionStatus.Error);
                            onComplete(false);
                        }
                    }
                },
                handleOddsUpdateMessages: (oddsUpdateMessages: OddsUpdateMessageOption[]) => {
                    let shouldFetchSgpOdds = false;
                    set(state => {
                        const { closedSelections, options, oddsChanges, bets, didStatusChange } =
                            handleOddsUpdateMessages(state, oddsUpdateMessages);
                        shouldFetchSgpOdds = didStatusChange;
                        const timeout = getOddsChangeIndicatorTimeout({ state, oddsChanges });
                        return {
                            ...state,
                            bets,
                            closedSelections,
                            options,
                            oddsChanges,
                            oddsChangeTimeout: timeout,
                        };
                    });
                    const state = get();
                    if (shouldFetchSgpOdds) {
                        state.actions.updateSgpOdds({});
                    }
                },
                handleMatchUpdateMessage: (matchUpdateMessage: MatchUpdateMessage) => {
                    set(state => ({
                        events: {
                            ...state.events,
                            [matchUpdateMessage.id]: {
                                ...state.events[matchUpdateMessage.id],
                                event_details: matchUpdateMessage,
                            },
                        },
                    }));
                },
                acceptAllOddsChanges: () => {
                    set(state => {
                        const { options } = state;
                        const newOptions = { ...options };

                        if (state.oddsChangeTimeout) {
                            clearTimeout(state.oddsChangeTimeout);
                        }

                        // update all options.odds with original odds
                        Object.entries(newOptions).forEach(([optionId, option]) => {
                            if (state.oddsChanges[optionId]) {
                                newOptions[optionId] = {
                                    ...option,
                                    originalOdds: option.odds,
                                };
                            }
                        });

                        return {
                            ...state,
                            oddsChanges: {},
                            sgpOddsChanges: {},
                            oddsChangeTimeout: null,
                            options,
                        };
                    });
                },
                clearOddsChanges: () => {
                    set({ oddsChanges: {}, sgpOddsChanges: {} });
                },
                clearHigherOddsChanges: () => {
                    const state = get();
                    const {
                        oddsChanges,
                        sgpOddsChanges,
                        selectionOrder: selectionIds,
                        selections,
                        options,
                        sgpOdds,
                    } = state;
                    let newOddsChanges = { ...oddsChanges };
                    let newSgpOddsChanges = { ...sgpOddsChanges };
                    // Single bets
                    selectionIds.forEach(selectionId => {
                        const optionId = selections[selectionId]?.optionId;
                        const currentOdds = options[optionId].odds;
                        const previousOdds = oddsChanges[optionId];
                        if (currentOdds && previousOdds && currentOdds > previousOdds) {
                            delete newOddsChanges[selectionId];
                        }
                    });

                    // SGP/SGP+ bets
                    const selectionIdsByEvent = groupSelectionIdsByEvent(state);
                    Object.entries(selectionIdsByEvent).forEach(([eventId, ids]) => {
                        const spgId = generateSgpOddsId(ids, eventId);
                        const currentOdds = sgpOdds[spgId];
                        const previousOdds = sgpOddsChanges[spgId];
                        if (currentOdds && previousOdds && currentOdds > previousOdds) {
                            delete newSgpOddsChanges[spgId];
                        }
                    });

                    set({ oddsChanges: newOddsChanges, sgpOddsChanges: newSgpOddsChanges });
                },
                removeClosedSelections: () => {
                    const state = get();
                    state.closedSelections.forEach(selection => {
                        state.actions.removeSelection(selection.option.id);
                    });
                },
                clearClosedSelections: () => {
                    set({ closedSelections: [] });
                },
                updateProducerStatus: producerStatus => set({ producerStatus }),
                pollSubmittedBets: async onPollFinish => {
                    if (get().betSubmissionStatus !== BetSubmissionStatus.Submitting) {
                        logger.debug(SBK_BETSLIP_LOG_TAG, 'Polling exited before starting');
                        return;
                    }
                    logger.info(SBK_BETSLIP_LOG_TAG, 'Polling started');
                    for (let i = 0; i < MAX_POLL_RETRIES; i++) {
                        logger.debug(SBK_BETSLIP_LOG_TAG, `Polling attempt #${i + 1}`);
                        const state = get();
                        if (state.betSubmissionStatus !== BetSubmissionStatus.Submitting) {
                            logger.debug(SBK_BETSLIP_LOG_TAG, 'Polling exited');
                            return; // If bet finished submitting, exit the loop
                        }
                        const { success, globalBetIds } = await pollSubmittedBets(Object.keys(state.submittedBets));
                        if (success) {
                            const summary = getBetSummary(state, globalBetIds);
                            if (get().betSubmissionStatus !== BetSubmissionStatus.Submitting) {
                                logger.debug(SBK_BETSLIP_LOG_TAG, 'Polling exited');
                                return;
                            }
                            state.actions.updateBetSubmissionStatus(BetSubmissionStatus.Success);
                            logger.debug(SBK_BETSLIP_LOG_TAG, 'Polling success');
                            return onPollFinish(true, summary);
                        }
                        await delay(POLL_INTERVAL_MS);
                    }
                    logger.warn(SBK_BETSLIP_LOG_TAG, 'Bet submission timeout');
                    get().actions.updateBetSubmissionStatus(BetSubmissionStatus.Error);
                    onPollFinish(false);
                },
                updateBetSubmissionStatus: (betSubmissionStatus: 'SUCCESS' | 'ERROR') => {
                    set({
                        betSubmissionStatus,
                        submittedBets: {},
                        betSubmissionStartTime: null,
                    });
                },
                updateAllEventDetails: async () => {
                    const results = await Promise.allSettled(get().eventOrder.map(getEventDetails));
                    set(state => {
                        const newEvents = results.reduce(
                            (acc, result) => {
                                if (result.status === 'fulfilled' && result.value) {
                                    const event = result.value;
                                    acc[event.id] = {
                                        ...state.events[event.id],
                                        event_details: event.event_details,
                                    };
                                }
                                return acc;
                            },
                            { ...state.events }
                        );
                        return { events: newEvents };
                    });
                },
            },
        }),
        {
            name: 'sbk-betslip-storage',
            version: 9,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: SBKBetSlip) => rest,
        }
    )
);
