import { useEffect, useRef } from 'react';

import { useUser } from '@/hooks/use-user';
import { useGraphqlClient } from '@/providers';

//5 minutes
const CACHE_INVALIDATION_INTERVAL_MS = 5 * 60 * 1000;

/**
 * Invalidates the URQL / GraphQL cache every 5 minutes if the user is a guest.
 * We want guest users to make less frequent request to our BE so we cache most of the events data.
 *
 * @see [use-gql-request-policy.ts](./use-gql-request-policy.ts) and its usages
 */
export const useGuestCacheInvalidation = () => {
    const { resetUrqlCache } = useGraphqlClient();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const { guest } = useUser();

    useEffect(() => {
        if (guest) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            intervalRef.current = setInterval(() => {
                resetUrqlCache();
            }, CACHE_INVALIDATION_INTERVAL_MS);
        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [guest, resetUrqlCache]);
};
