import React from 'react';

import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';

import { PayoutInput } from '../PayoutInput';
import { StakeInput } from '../StakeInput';
import { useStakeInputErrorMessage } from './hooks/use-stake-input-error-message';

export const BetInputs = ({ betId }: { betId: string }) => {
    const errorMessage = useStakeInputErrorMessage(betId);
    return (
        <Box mb="s16">
            <Row columnGap="s12">
                <Column flex={1}>
                    <StakeInput betId={betId} hasError={!!errorMessage} />
                </Column>
                <Column flex={1}>
                    <PayoutInput betId={betId} />
                </Column>
            </Row>
            {errorMessage ? (
                <Row pl="s16" mt="s4">
                    <Text color="red" variant="bodySmall" testID="wagerErrorMessage">
                        {errorMessage}
                    </Text>
                </Row>
            ) : null}
        </Box>
    );
};
