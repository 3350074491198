import AsyncStorage from '@react-native-async-storage/async-storage';

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const defaultPaymentFlags = {
    isDebugMode: {
        enabled: false,
        label: 'Debug Mode',
        description: 'Enables Payment Method debug mode for the app',
    },
    nuveiEnabled: {
        enabled: false,
        label: 'Nuvei Enabled',
        description:
            'Controls the availability of Nuvei as a payment provider. Corresponds to "enable_nuvei" flag in Prismic. When true, allows Nuvei payment methods to be displayed and used, subject to other conditions. Applies to both web and mobile versions.',
    },
    enableInstantBankTransfers: {
        enabled: true,
        label: 'Enable Instant Bank Transfers',
        description:
            'Toggles the ability to use Instant Bank Transfers for deposits. Mirrors "deposit_instant_bank_transfers_enabled" flag in Prismic. When true, allows IBT options to be displayed, subject to other conditions like Nuvei enablement. Applicable to both web and mobile platforms.',
    },
    enableDebitCardDeposit: {
        enabled: false,
        label: 'Enable Debit Card Deposit',
        description:
            'Controls the availability of debit card deposits. Corresponds to "deposit_debit_card_enabled" flag in Prismic. When true, allows debit card deposit options to be shown and used. Relevant for both web and mobile versions of the app.',
    },
    enablePayByBank: {
        enabled: true,
        label: 'Enable Pay By Bank',
        description:
            'Controls the availability of pay by bank in deposit and withdrawal flow. Corresponds to "deposit_pay_by_bank_enabled" flag in Prismic. When true, allows pay by bank options to be shown and used. Relevant for both web and mobile versions of the app.',
    },
    enablePaypal: {
        enabled: false,
        label: 'Enable PayPal',
        description:
            'Controls the availability of paypal in deposit and withdrawal flow. Corresponds to "deposit_paypal_enabled" flag in Prismic. When true, allows paypal options to be shown and used. Relevant for both web and mobile versions of the app.',
    },
    enableVenmo: {
        enabled: false,
        label: 'Enable Venmo',
        description:
            'Controls the availability of venmo in deposit and withdrawal flow. Corresponds to "deposit_pay_by_bank_enabled" flag in Prismic. When true, allows venmo options to be shown and used. Only for web.',
    },
    showDepositIbt: {
        enabled: false,
        label: 'Show Deposit IBT',
        description:
            'Controls visibility of Instant Bank Transfer (IBT) option on web. Inversely related to "hide_deposit_ibt" flag in Prismic. When true, it allows IBT display if other conditions are met. Primarily for web debugging; not used in mobile versions.',
    },
    hasSavedNuveiMethods: {
        enabled: false,
        label: 'Has Saved Nuvei Methods',
        description: 'User has previously saved Nuvei payment methods',
    },
    debitCardRemovalIdpvBypassEnabled: {
        enabled: false,
        label: 'Debit Card Removal IDPV Bypass Enabled',
        description:
            'Bypasses IDPV for debit card removal. When true, allows users to remove debit cards without IDPV verification. Primarily for debugging purposes',
    },
} as const;

type PaymentFlags = typeof defaultPaymentFlags;
type PaymentFlagName = keyof PaymentFlags;

type PaymentFlagsStore = {
    paymentFlags: PaymentFlags;
    _hydrated: boolean;
    actions: {
        toggleFlag: (flagName: PaymentFlagName) => void;
        setHydrated: () => void;
        clearStorage: () => Promise<void>;
    };
};

export const usePaymentFlagsStore = create<PaymentFlagsStore>()(
    persist(
        set => ({
            paymentFlags: defaultPaymentFlags,
            _hydrated: false,
            actions: {
                toggleFlag: (flagName: PaymentFlagName) =>
                    set(state => ({
                        paymentFlags: {
                            ...state.paymentFlags,
                            [flagName]: {
                                ...state.paymentFlags[flagName],
                                enabled: !state.paymentFlags[flagName].enabled,
                            },
                        },
                    })),
                setHydrated: () => set({ _hydrated: true }),
                clearStorage: async () => {
                    await AsyncStorage.removeItem('paymentFlags-storage');
                    set({ paymentFlags: defaultPaymentFlags });
                },
            },
        }),
        {
            name: 'paymentFlags-storage',
            version: 1,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: PaymentFlagsStore) => rest,
            onRehydrateStorage: () => state => {
                state?.actions.setHydrated();
            },
        }
    )
);

// Helper hook to use the payment flags store
export const usePaymentFlags = () => {
    const store = usePaymentFlagsStore();
    return {
        flags: store.paymentFlags,
        toggleFlag: store.actions.toggleFlag,
        clearStorage: store.actions.clearStorage,
    };
};
