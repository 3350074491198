import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Carousel } from '@/components/Carousel';
import { Text } from '@/components/TextComponent';
import { Box, Image } from '@/components/lib/components';
import { common, designSystem } from '@/styles/styles';
import { PromotionDocument } from '@/types/prismic.generated';
import { isWeb } from '@/utils/constants-platform-specific';

import { usePromotions } from '../hooks/use-promotions';

const styles = StyleSheet.create({
    base: {
        borderRadius: 16,
        backgroundColor: designSystem.colors.gray6,
        height: '100%',
        overflow: 'hidden',
        borderCurve: 'continuous',
    },
    imageContainer: {
        width: 84,
    },
});

export const LobbyCarousel = () => {
    const { promotions, isLoading, goToPromo } = usePromotions();
    const { t } = useTranslation(['league_hub_sbk']);

    if (!promotions.length) {
        return null;
    }

    return (
        <Box paddingBottom={'s28'}>
            <Carousel<PromotionDocument>
                data={promotions}
                isLoading={isLoading}
                showCarouselArrows={isWeb}
                renderCarouselItem={item => {
                    return (
                        <TouchableOpacity
                            style={[styles.base, common.row]}
                            onPress={() => goToPromo(item.uid)}
                            accessible={false}
                        >
                            <Box style={styles.imageContainer}>
                                <Image source={{ uri: item.data?.image.url ?? '' }} style={common.fullHeight} />
                            </Box>
                            <Box
                                flex={1}
                                paddingTop={'s16'}
                                paddingBottom={'s24'}
                                paddingHorizontal={'s20'}
                                testID="promoCarouselItem"
                            >
                                <Box flex={1} paddingBottom={'s12'} justifyContent={'center'}>
                                    <Text variant="titleLarge" textAlign={'left'} numberOfLines={3} testID="promoTitle">
                                        {item.data?.title}
                                    </Text>
                                </Box>
                                <Box alignSelf="flex-start" borderBottomColor="white" borderBottomWidth={1}>
                                    <Text
                                        lineHeight={24}
                                        fontSize={15}
                                        textAlign="left"
                                        fontWeight="bold"
                                        testID="promoSubTitle"
                                    >
                                        {item.data?.cta ?? t('more_info')}
                                    </Text>
                                </Box>
                            </Box>
                        </TouchableOpacity>
                    );
                }}
            />
        </Box>
    );
};
