import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { SCREEN_NAV_BAR_HEIGHT } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';

const NO_MARKETS_HEIGHT = 120;

export type MarketsUnavailableProps = {
    title?: string;
    description: string;
    offHeight?: number;
    paddingHorizontal?: number;
};

export const MarketsUnavailable = ({
    title,
    description,
    offHeight = 0,
    paddingHorizontal = 0,
}: MarketsUnavailableProps) => {
    const { t } = useTranslation('league_hub_sbk');
    const { height: defaultHeight } = useWindowDimensions();
    const insets = useSafeAreaInsets();
    const height = defaultHeight - insets.top - insets.bottom - SCREEN_NAV_BAR_HEIGHT - NO_MARKETS_HEIGHT - offHeight;

    return (
        <Box style={{ height, paddingHorizontal }} alignItems="center" justifyContent="center">
            <Text mb="s4" variant="titleLarge">
                {title ? title : t('markets_unavailable')}
            </Text>

            <Text variant="bodyMedium" color="gray2" textAlign="center">
                {description}
            </Text>
        </Box>
    );
};
