import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import {
    CountryDetectError,
    CountyDetectError,
    NoLocationAccessError,
    actions as locationActions,
} from '@/hooks/use-location';
import { useResumeEffect } from '@/hooks/use-resume';
import { actions } from '@/hooks/use-user';
import { handleKYCNavigation, useCoreNavigation } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Splash'>;

const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.purple,
    },
});

const LOG_TAG = '[Bootstrap]';

export function SplashScreen({ navigation }: ScreenProps) {
    const { openLaunch, showIneligibleStateError, showIneligibleCountry, openHome } = useCoreNavigation();
    const storedState = useJurisdictionStore(state => state.jurisdiction);
    const storedStateSource = useJurisdictionStore(state => state.jurisdictionSource);
    /**
     * If the user is on the web and has manually selected a US state before, we will use that state.
     * Otherwise this can causes some issues with redirects on web, after manually selecting a state.
     */
    const preSetManualState = storedStateSource === 'manual' && isWeb;
    const [manualUsState, setManualUsState] = useState<string | undefined>(preSetManualState ? storedState : undefined);
    const setJurisdictionAndUpdateSettings = useJurisdictionStore(
        store => store.actions.setJurisdictionAndUpdateSettings
    );
    const clearGpsData = useJurisdictionStore(store => store.actions.clearGpsData);

    const runInitSequence = useCallback(() => {
        const execute = async () => {
            try {
                //Load previous user session if any. This also loads the user profile/wallet.
                const user = await actions.loadPreviousSession();
                if (user.sessionHasExpired()) {
                    // if the session expires or doesn't exist, make the user a guest
                    actions.setupGuestSession();
                }
                //If we are resuming a previous session,
                //re-check location, location access or default to the manual state if the user
                //just manually selected a US state
                const newUserState = manualUsState ?? (await locationActions.detectJurisdiction());
                const savedState = useJurisdictionStore.getState().jurisdiction;
                if (newUserState !== savedState) {
                    //State is different from the last detected state
                    logger.debug(LOG_TAG, `State changed from ${savedState} to ${newUserState}, must re-fetch configs`);
                }
                //Set jurisdiction and fetch new settings
                const settings = await setJurisdictionAndUpdateSettings(
                    newUserState,
                    manualUsState ? 'manual' : 'detected'
                );

                if (settings.productType === Product.None) {
                    showIneligibleStateError();
                    return;
                }

                if (user.isGuest()) {
                    openHome();
                    return;
                }

                await handleKYCNavigation(navigation);
            } catch (e) {
                logger.warn(LOG_TAG, e);
                clearGpsData();
                if (e instanceof NoLocationAccessError) {
                    navigation.navigate('AllowLocation', {
                        errorMode: 'locationAccess',
                        onSelectUsState: setManualUsState,
                    });
                    return;
                } else if (e instanceof CountyDetectError) {
                    navigation.navigate('AllowLocation', {
                        errorMode: 'countyDetect',
                        onSelectUsState: setManualUsState,
                    });
                    return;
                } else if (e instanceof CountryDetectError) {
                    showIneligibleCountry();
                    return;
                }

                openLaunch();
            }
        };
        execute();
    }, [
        manualUsState,
        setJurisdictionAndUpdateSettings,
        navigation,
        showIneligibleStateError,
        openHome,
        clearGpsData,
        openLaunch,
        showIneligibleCountry,
    ]);

    useResumeEffect(runInitSequence);

    return (
        <View style={[styles.root, common.paddingVertical, common.flex, common.justifyCenter]}>
            <LoadingSpinner />
        </View>
    );
}
