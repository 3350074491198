import React from 'react';
import { TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Bet } from '../hooks/types';
import { ComboSbkBetCard } from './bet-cards/ComboSbkBetCard';
import { ScriptSbkBetCard } from './bet-cards/ScriptSbkBetCard';
import { SingleSbkBetCard } from './bet-cards/SingleSbkBetCard';

const getBetCard = (bet: Bet, onSharePress: () => void) => {
    switch (bet.bet_type) {
        case 'SINGLE':
            return <SingleSbkBetCard bet={bet} onSharePress={onSharePress} />;
        case 'SCRIPT':
            return <ScriptSbkBetCard bet={bet} onSharePress={onSharePress} />;
        case 'COMBO':
            return <ComboSbkBetCard bet={bet} onSharePress={onSharePress} />;
        default:
            return null;
    }
};

type SBKBetCardProps = {
    bet: Bet;
};

export type SBKBetCardTypeProps = {
    bet: Bet;
    onSharePress: () => void;
};

export const SBKBetCard = ({ bet }: SBKBetCardProps) => {
    const navigation = useNavigation();
    const onSharePress = () => {
        navigation.navigate('BetShareScreen', { betId: bet.id, bet });
    };

    return (
        <TouchableOpacity
            onPress={() => navigation.navigate('SbkBetScreen', { betId: bet.id, bet: bet })}
            accessible={false}
        >
            {getBetCard(bet, onSharePress)}
        </TouchableOpacity>
    );
};
