import React from 'react';

import { TextProps } from '@/components/TextComponent';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';

import { BetSlipOdds } from '../BetSlipOdds';

type SinglesOddsProps = {
    optionId: string;
} & TextProps;

export const SinglesOdds = ({ optionId, ...textProps }: SinglesOddsProps) => {
    const odds = useSbkBetSlipStore(state => state.options[optionId].odds);
    const previousOdds = useSbkBetSlipStore(state => state.oddsChanges[optionId]);
    const hasOddsChanged = previousOdds !== odds;

    return (
        <BetSlipOdds betId={optionId} {...{ odds, previousOdds, hasOddsChanged }} {...textProps} testID="singlesOdds" />
    );
};
