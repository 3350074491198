import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { failedKycCountSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useUser } from '@/hooks/use-user';

// If it's not the first screen of Kyc flow, go back to the previous screen; if it's the first screen, go back to the login screen.
export const useKycClose = (isFirstStep: boolean) => {
    const { logout } = useUser();
    const { data: retryCount = 0 } = useAuthUserConfig({
        select: failedKycCountSelector,
    });
    const isFirstAttempt = retryCount === 0;

    const trackClosePress = () => {
        const event = isFirstAttempt ? AnalyticsEvent.FIRST_ATTEMPT_ABORT : AnalyticsEvent.SECOND_ATTEMPT_ABORT;
        BetrAnalytics.trackEvent(event);
    };

    const onClose = () => {
        trackClosePress();
        logout();
    };

    // if it's not first step of kyc flow, onClose is undefined
    return {
        onClose: isFirstStep ? onClose : undefined,
    };
};
