import AsyncStorage from '@react-native-async-storage/async-storage';

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type WarningBanners = {
    closedEntryWarningBanners: Record<string, number>;
    actions: {
        addToEntryWarningBanners: (entryId: string, expireTime: number) => void;
        removeFromEntryWarningBanners: (currentTime: number) => void;
    };
};

// Store for managing the warning banners that are displayed for entries that have voided picks
// We need the current time because we want to remove the banners that have expired (ex. after the entry is settled)
export const useEntriesWarningStore = create<WarningBanners>()(
    persist(
        set => ({
            closedEntryWarningBanners: {},
            actions: {
                addToEntryWarningBanners: (entryId: string, expireTime: number) => {
                    set(state => ({
                        closedEntryWarningBanners: { ...state.closedEntryWarningBanners, [entryId]: expireTime },
                    }));
                },
                removeFromEntryWarningBanners: (currentTime: number) => {
                    set(state => ({
                        closedEntryWarningBanners: Object.fromEntries(
                            Object.entries(state.closedEntryWarningBanners).filter(([_, value]) => {
                                return value > currentTime;
                            })
                        ),
                    }));
                },
            },
        }),
        {
            name: 'warning-banner-store',
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: WarningBanners) => rest,
        }
    )
);
