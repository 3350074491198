import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { LooseValue, Value } from 'react-calendar/dist/esm/shared/types.js';
import { useTranslation } from 'react-i18next';
import { DateData } from 'react-native-calendars';

import { Button } from '@/components/ButtonComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Column } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { convertDateToDateData } from '../utils/calendar-helper';
import './calendar-sheet-modal.css';
import { CalendarSheetForWebProps, CalendarSheetModalForWebRef } from './types';

export const CalendarSheetModal = forwardRef<CalendarSheetModalForWebRef, {}>((_props, ref) => {
    const modalRef = useRef<BottomSheetModal>(null);
    const [data, setData] = useState<CalendarSheetForWebProps>();

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal id="calendar-sheet" modalRef={modalRef} dismissible={false}>
            <ModalContent {...data} />
        </Modal>
    );
});

const ModalContent = (props: CalendarSheetForWebProps) => {
    const { t } = useTranslation(['date_picker', 'common']);
    const {
        onApply,
        selectDate,
        selectedDates: initialSelectedDates = [],
        closeIconMode = 'back',
        allowRangeSelection = true,
    } = props;
    const { dismissAll } = useModals();

    const today = new Date();

    // The dates that are currently selected on the calendar. Marked with a different color (Betr color).
    const [selectedDates, setSelectedDates] = useState<DateData[]>(initialSelectedDates);

    const onDayPress = useCallback((reactCalendarValue: Value) => {
        // Exit if no value is selected
        if (!reactCalendarValue) {
            return;
        }

        // If the value is an array, it means a range is selected
        const dates = Array.isArray(reactCalendarValue)
            ? reactCalendarValue.filter((date): date is Date => date !== null).map(convertDateToDateData)
            : [convertDateToDateData(reactCalendarValue)];

        setSelectedDates(dates);
    }, []);

    const defaultValue: LooseValue = useMemo(() => {
        if (selectedDates.length === 1) {
            return new Date(selectedDates[0].timestamp);
        } else if (selectedDates.length > 1) {
            return [new Date(selectedDates[0].timestamp), new Date(selectedDates[1].timestamp)];
        }
        return null;
    }, [selectedDates]);

    const onClose = () => {
        dismissAll();
        selectDate?.();
    };

    const handleApplyDateRange = () => {
        onApply?.(selectedDates);
        setSelectedDates([]);
        dismissAll();
    };

    const modalTitle = allowRangeSelection ? t('choose_a_date_or_date_range') : t('choose_a_date');

    return (
        <Column width="100%" paddingHorizontal="s16" pb="s8" gap="s12">
            <ScreenNavBar closeIconMode={closeIconMode} title={modalTitle} onClose={onClose} />
            <Calendar
                maxDate={today}
                showFixedNumberOfWeeks
                onChange={onDayPress}
                selectRange={allowRangeSelection}
                allowPartialRange
                value={defaultValue}
            />
            <Button label={t('common:apply')} hierarchy={'primary'} onPress={handleApplyDateRange} />
            {allowRangeSelection ? <Button label={t('clear_selection')} onPress={() => setSelectedDates([])} /> : null}
        </Column>
    );
};
