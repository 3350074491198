export const basketballDefaultValues = {
    width: 47,
    height: 70,
    blur: 8,
    maskWidth: 48,
    maskHeight: 60,
    maskX: 0,
    maskY: -10,
};

export const footballDefaultValues = {
    width: 72,
    height: 70,
    blur: 8,
    maskR: 40,
    maskCY: 12,
    maskCX: 35,
};
