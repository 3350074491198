import { useMemo } from 'react';

import { useFeaturedBets } from '@/feature/lobby-sbk/hooks/use-featured-bets';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { useLeague } from './use-league';

const SUB_TAB_HEIGHT = 68;
export const GAME_SECTION_HEIGHT = 130;
const ONE_MARKET_GAME_SECTION_HEIGHT = 170;

export const useSectionHeights = (leagueId: string) => {
    const { data: league } = useLeague(leagueId);
    const { featuredBetsEnabled } = useJurisdictionStore(state => ({
        featuredBetsEnabled: state.jurisdictionSettings?.productConfig?.settings?.sbk_lobby_featured_bets_enabled,
    }));
    const { data: featuredBets } = useFeaturedBets({ leagueId }, featuredBetsEnabled);
    const hasFeaturedBets = featuredBetsEnabled && featuredBets && featuredBets.length > 0;

    const gameSectionHeight = useMemo(() => {
        if (hasFeaturedBets) {
            return GAME_SECTION_HEIGHT;
        }
        return league?.market_categories.length === 1 ? ONE_MARKET_GAME_SECTION_HEIGHT : GAME_SECTION_HEIGHT;
    }, [hasFeaturedBets, league?.market_categories.length]);

    const marketHeaderSectionHeight = useMemo(() => {
        return gameSectionHeight + SUB_TAB_HEIGHT;
    }, [gameSectionHeight]);

    return { marketHeaderSectionHeight, gameSectionHeight };
};
