import React, { memo, useMemo } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { League } from '@/types/api.generated';

import { OldPlayerImagePlaceholderContainer, OldPlayerProfileImage } from './OldPlayerProfileImage';
import {
    PlayerImagePlaceholderContainerWithGradient,
    PlayerProfileImageWithGradient,
} from './PlayerProfileImageWithGradient';
import { useGradientImagesEnabled } from './hooks/use-gradient-images-enabled';
import { Rectangle } from './types';

type Props = {
    playerImageUrl?: string;
    arcText?: string;
    teamImageUrl?: string;
    playerNumber?: number | null;
    onLoadImage?: () => void;
    imageVariant?: 'small' | 'big' | 'extra-large';
    style?: StyleProp<ViewStyle>;
    customSize?: Rectangle;
    teamColor?: string;
    teamSecondaryColor?: string;
    bgColor?: 'gray6' | 'gray5';
    league?: League;
    playerId: string;
};

type PlaceholderProps = {
    onLoad?: () => void;
    placeholderSize?: Rectangle;
    masked?: boolean;
    marginTop?: number;
    id: string;
};

export const PlayerImagePlaceholderContainer = memo((props: PlaceholderProps) => {
    const enableGradientPlayerProfileImage = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.enable_gradient_player_profile_image
    );

    // We do not want the reference to change on re-renders
    const Component = useMemo(
        () =>
            enableGradientPlayerProfileImage
                ? PlayerImagePlaceholderContainerWithGradient
                : OldPlayerImagePlaceholderContainer,
        [enableGradientPlayerProfileImage]
    );

    return <Component {...props} />;
});

export const PlayerProfileImage = (props: Props) => {
    const enableGradientPlayerProfileImage = useGradientImagesEnabled();

    // We do not want the reference to change on re-renders
    const Component = useMemo(
        () => (enableGradientPlayerProfileImage ? PlayerProfileImageWithGradient : OldPlayerProfileImage),
        [enableGradientPlayerProfileImage]
    );

    return <Component {...props} />;
};
