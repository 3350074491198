import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import RightIcon from '@/assets/icons/right';
import { PaymentLogo } from '@/components/PaymentLogo';
import { RadioItem } from '@/components/RadioItem';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { ExpiredCardTag } from '@/feature/deposit/components/ExpiredCardTag';
import { useSelectedWithdrawalMethodStore } from '@/feature/withdraw/hooks/use-selected-withdrawal-method';
import { common } from '@/styles/styles';
import { runAfterInteractions } from '@/utils/runAfterInteractions';

import { WithdrawMethod } from '../hooks/use-withdrawal-methods';
import { getWithdrawalMethodName, paymentMethodLabels } from '../utils/utils';

const styles = StyleSheet.create({
    image: {
        width: 73,
        height: 34,
        marginRight: 15,
        borderRadius: 5,
    },
    listItem: {
        display: 'flex',
        columnGap: 8,
        minHeight: 96,
        paddingVertical: 16,
    },
});

type WithdrawalMethodItemProps = {
    method: WithdrawMethod;
    methods: WithdrawMethod[];
};

/**
 * Withdrawal method item
 * */
export const WithdrawalMethodItem = ({ method, methods }: WithdrawalMethodItemProps) => {
    const navigation = useNavigation();
    const { dismissAll } = useModals();
    const setSelectedMethod = useSelectedWithdrawalMethodStore(state => state.setSelectedMethod);
    const selectedMethod = useSelectedWithdrawalMethodStore(state => state.selectedWithdrawalMethod);

    const handlePress = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.SELECT_WITHDRAW_METHOD, { method: method?.type });
        const route = method.is_expired ? 'WithdrawalExpiryUpdate' : 'WithdrawForm';
        setSelectedMethod(method);
        navigation.navigate(route, {
            selectedMethod: method,
            methods,
        });

        runAfterInteractions(dismissAll);
    };

    return (
        <TouchableOpacity
            style={[styles.listItem, common.row, common.alignCenter, common.justifySpaceBetween]}
            onPress={handlePress}
            testID={method.type}
        >
            <Box flexDirection="row" flex={1} alignItems={'center'}>
                <PaymentLogo source={method?.payment_logos} />
                <Box flex={1}>
                    <Text fontSize={15}>{getWithdrawalMethodName(method.type, method?.cc_type ?? '')}</Text>
                    <Box>
                        <Text color="gray3" fontSize={13} numberOfLines={1}>
                            {paymentMethodLabels[method.type]} {method?.cc_last4 ? `(•••• ${method?.cc_last4})` : null}
                            {method?.venmo_id ? `(${method?.venmo_id})` : null}
                        </Text>

                        {method.closed_loop_remain > 0 ? (
                            <>
                                <Text color="gray3" fontSize={13}>
                                    Required distribution: ${method.closed_loop_remain}
                                </Text>
                            </>
                        ) : null}
                    </Box>
                </Box>
            </Box>

            {method.is_expired ? (
                <>
                    <Box mr={'s8'}>
                        <ExpiredCardTag />
                    </Box>
                    <Box justifyContent="center" alignItems="flex-end">
                        <RightIcon />
                    </Box>
                </>
            ) : (
                <RadioItem selected={method === selectedMethod} />
            )}
        </TouchableOpacity>
    );
};
