import { Platform } from 'react-native';

import { getRemoteConfigByKey } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { FirebaseRemoteSettings } from '@/feature/analytics/utils/firebaseSettings';
import { ProductSettings, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { isAndroid } from '@/utils/constants-platform-specific';

/**
 * Hook used to check if the player image gradient is enabled for a device type.
 * @returns {boolean}
 */
export const useGradientImagesEnabled = () => {
    const enableGradientSettingKey = Platform.select<keyof ProductSettings>({
        android: 'enable_gradient_player_profile_image_android',
        ios: 'enable_gradient_player_profile_image_ios',
        default: 'enable_gradient_player_profile_image',
    });

    // This feature flag is used for A/B testing Skia’s stability on Android.
    // We’ll enable it for a small subset of users to observe any changes in crash rates.
    const firebaseEnableSkiaOnAndroid = getRemoteConfigByKey(FirebaseRemoteSettings.ENABLE_SKIA_ON_ANDROID).asBoolean();

    // This is the primary flag to enable gradient images.
    // Since there’s a delay between Firebase updates and the app fetching those updates,
    // we need a quick way to disable gradient images if Skia has issues.
    // This helps prevent an excessive number of crashes.
    const enableGradientPrismicFlag = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.[enableGradientSettingKey]
    );

    if (isAndroid) {
        return enableGradientPrismicFlag && firebaseEnableSkiaOnAndroid;
    }
    return enableGradientPrismicFlag;
};
