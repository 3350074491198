import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M2.5 7.75L5.64645 4.60355C5.84171 4.40829 6.15829 4.40829 6.35355 4.60355L9.5 7.75"
            stroke={designSystem.colors.gray8}
            strokeWidth={1.5}
        />
    </Svg>
);
export default SVGComponent;
