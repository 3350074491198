import { PaymentMethodType } from './hooks/use-deposit-payment-methods';

export enum PaymentMethodTypes {
    PaysafePaypal = 'paysafe_paypal',
    PaysafeMazooma = 'paysafe_mazooma', // also known as Nuvei
    Paysafe = 'paysafe',
    PaysafePayByBank = 'paysafe_pay_by_bank', // also known as Paysafe ACH
    PaysafeVenmo = 'paysafe_venmo',
}

export const paymentMethodOrder: PaymentMethodType[] = [
    PaymentMethodTypes.Paysafe,
    PaymentMethodTypes.PaysafePayByBank,
    PaymentMethodTypes.PaysafePaypal,
    PaymentMethodTypes.PaysafeMazooma,
    PaymentMethodTypes.PaysafeVenmo,
];

export const ccTypeMap: Record<string, string> = {
    VI: 'VISA',
    MC: 'Mastercard',
};

export const WALLET_GENERIC_ERROR_KEY = 'wallet.generic_error';

export const DEPOSIT_STATUS_DEEPLINK_URL = 'https://link.betr.app/deposit-status';
