import React, { useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useDeposit } from '@/feature/deposit/hooks/use-deposit';
import { handleTokenizePayment, useInitializeVenmoContext } from '@/hooks/payments/use-initialize-venmo-context';
import { useAuthUserInfo } from '@/hooks/use-auth-user-info';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { getWalletErrorCode } from '@/utils/get-wallet-error-code';

import { PaymentMethodTypes } from '../const';
import { useDepositPaymentMethods, venmoPaymentSelector } from '../hooks/use-deposit-payment-methods';

const styles = StyleSheet.create({
    loaderContainer: { backgroundColor: designSystem.colors.gray8 },
});

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'VenmoDepositScreen'>;

export const VenmoDepositScreen = ({ route }: ScreenProps) => {
    const { selectedAmount, currency = 'USD' } = route.params || {};
    const selectedPaymentMethodType = PaymentMethodTypes.PaysafeVenmo;
    const navigation = useNavigation();
    const { data: userInfo } = useAuthUserInfo();
    const { data: venmoMethod } = useDepositPaymentMethods({
        select: venmoPaymentSelector,
    });

    const initializeVenmo = useInitializeVenmoContext();

    // This is to prevent redundant initialization attempts, maintain the correct state of the application,
    // optimize performance by avoiding unnecessary operations, and handle errors effectively.
    const isVenmoInitialized = useRef(false);
    const paysafeAccountId = venmoMethod?.accountId;

    const { mutate: deposit } = useDeposit();

    useEffect(() => {
        const handleVenmoDeposit = async () => {
            if (userInfo && !isVenmoInitialized.current && paysafeAccountId) {
                try {
                    await initializeVenmo();
                    const token = await handleTokenizePayment(
                        parseFloat(selectedAmount),
                        paysafeAccountId,
                        userInfo,
                        navigation.navigate
                    );

                    isVenmoInitialized.current = true;

                    // Call deposit mutation after successful Venmo transaction
                    deposit(
                        {
                            amount: selectedAmount,
                            type: PaymentMethodTypes.PaysafeVenmo,
                            currency,
                            payment_token: token,
                        },
                        {
                            onSuccess: () => {
                                navigation.navigate('SuccessfulDepositModal', {
                                    selectedAmount,
                                    paymentProvider: selectedPaymentMethodType,
                                });
                            },
                            onError: error => {
                                navigation.navigate('FailedDepositModal', {
                                    selectedAmount,
                                    paymentProvider: selectedPaymentMethodType,
                                    errorCode: getWalletErrorCode(error),
                                });
                            },
                        }
                    );
                } catch (error) {
                    navigation.navigate('ErrorModal', {
                        title: 'Error Processing Payment',
                        subtitle: 'There is an error tokenizing your payment.',
                        primaryButton: 'Close',
                    });
                }
            }
        };

        handleVenmoDeposit();
    }, [
        initializeVenmo,
        navigation,
        selectedAmount,
        userInfo,
        deposit,
        currency,
        selectedPaymentMethodType,
        paysafeAccountId,
    ]);

    return (
        <View style={[common.flex, styles.loaderContainer, common.justifyCenter]}>
            <LoadingSpinner />
        </View>
    );
};
