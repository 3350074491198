import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = ({ fill, ...props }) => (
    <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.1611 19.3755C21.9255 17.4245 23 14.8377 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C14.468 23 16.7462 22.1872 18.5815 20.8147C19.1536 20.3868 19.6827 19.9045 20.1611 19.3755ZM16.5 10.5C16.5 12.9853 14.4853 15 12 15C9.51472 15 7.5 12.9853 7.5 10.5C7.5 8.01472 9.51472 6 12 6C14.4853 6 16.5 8.01472 16.5 10.5ZM18.6718 18.0406C17.0251 19.8583 14.6459 21 12 21C9.35422 21 6.97508 19.8583 5.32837 18.0407C7.09817 16.7718 9.43651 16 12.0002 16C14.5637 16 16.902 16.7717 18.6718 18.0406Z"
            fill={fill ?? '#EDEFF3'}
        />
    </Svg>
);

export default SvgComponent;
