import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';

export const getFormattedOdds = (odds?: number): string => {
    return odds ? `${odds.toFixed(2)}x` : '';
};

export const Odds = ({ odds, ...textProps }: { odds: number } & TextProps) => {
    const formattedOdds = getFormattedOdds(odds);

    return <Text {...textProps}>{formattedOdds}</Text>;
};
