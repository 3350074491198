import React, { useCallback, useRef } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import Reanimated, { AnimatedStyle } from 'react-native-reanimated';

import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { common } from '@/styles/styles';

import { SinglesCard } from '../singles-card/SinglesCard';

export const betSlipTabStyle = StyleSheet.create({
    root: { paddingVertical: 18 },
});

export const SinglesTabScreen = ({ animatedStyle }: { animatedStyle: AnimatedStyle }) => {
    const selectionIds = useSbkBetSlipStore(state => state.selectionOrder);
    const showKeyboard = useSbkBetSlipStore(state => state.showKeyboard);
    const editBetId = useSbkBetSlipStore(state => state.editBetId);
    const ref = useRef<FlatList>(null);

    const renderItem = useCallback(
        ({ item, index }: { item: string; index: number }) => {
            const isLastItem = index === selectionIds.length - 1;
            return <SinglesCard selectionId={item} isLastItem={isLastItem} />;
        },
        [selectionIds.length]
    );

    return (
        <Reanimated.FlatList
            ref={ref}
            style={[betSlipTabStyle.root, common.paddingHorizontal, animatedStyle]}
            data={selectionIds}
            renderItem={renderItem}
            keyExtractor={item => item}
            onLayout={() => {
                if (showKeyboard) {
                    const index = selectionIds.findIndex(id => id === editBetId);
                    if (index >= 0) {
                        ref.current?.scrollToIndex({ index, animated: false });
                    }
                }
            }}
        />
    );
};
