import { URLS, createJurisdictionHeaders } from '@/data';
import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { MarketCategory } from '@/feature/event-details-sbk/types';
import { featuredLeagueKeys } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { QueryClient, UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';

import { leagueKeys } from './use-league';

export type GetLeagueMarketsParams = {
    leagueId: string;
    marketCategoryId: string;
    live?: boolean;
    marketType?: string;
};

/**
 * Get markets for a specific league.
 *
 * @param leagueId - The ID of the league.
 * @param marketCategoryId - The ID of the market category.
 * @param live - Whether to get only live markets.
 * @param marketType - Filter with marketType.
 * @returns The response containing the markets.
 */
export const getLeagueMarkets = async ({
    leagueId,
    marketCategoryId,
    live,
    marketType,
}: GetLeagueMarketsParams): Promise<MarketCategory[]> => {
    const queryParams = new URLSearchParams();
    if (live) {
        queryParams.append('live', (live ? 1 : 0).toString());
    }

    if (marketType) {
        queryParams.append('market_type', marketType);
    }

    const response = await fetch(
        `${URLS.CHAMELON_API_URL}/leagues/${leagueId}/markets/${marketCategoryId}?${queryParams}`,
        {
            method: 'GET',
            headers: {
                ...createJurisdictionHeaders(),
                ...cacheControlHeader,
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }

    useMarketCacheStore
        .getState()
        .actions.populate(
            data?.data?.map((marketCategory: MarketCategory) => marketCategory?.markets ?? []).flat() ?? []
        );
    return data.data;
};

// Grab RESULT only markets category data from cache as placeholder data
const getPlaceholderData = (queryClient: QueryClient, params: GetLeagueMarketsParams) => {
    const data = queryClient.getQueryData<Array<MarketCategory>>(
        featuredLeagueKeys.marketCategories({
            leagueId: params.leagueId,
            marketCategoryId: params.marketCategoryId,
            marketType: 'RESULT',
        })
    );
    return data;
};

/**
 * hook to fetch markets from a specific league.
 *
 * @param params - The parameters to fetch the markets.
 * @param options - The options for the query.
 * @param enabled - Whether the query is enabled.
 * @returns The response containing the markets.
 */
export const useLeagueMarkets = (
    params: GetLeagueMarketsParams,
    options?: UseQueryOptions<MarketCategory[], unknown, MarketCategory[], any>,
    enabled?: boolean
) => {
    const { leagueId, marketCategoryId } = params;
    const queryClient = useQueryClient();
    const queryOptions = {
        enabled: !!leagueId && !!marketCategoryId && enabled,
        staleTime: 500, // prevent double fetching on initial load
        refetchInterval: 30 * 1000, // 30 seconds, we fetch often here as when there are live events, more markets are consistently added
        placeholderData: getPlaceholderData(queryClient, params),
        ...options,
    };
    const queryData = useQuery(leagueKeys.marketCategories(params), () => getLeagueMarkets(params), queryOptions);
    return queryData;
};
