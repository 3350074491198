import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import Animated from 'react-native-reanimated';

import { useIsFocused } from '@react-navigation/native';

import { useStickyTabList } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { Box } from '@/components/lib/components';
import { LoadingBox } from '@/feature/event-details-sbk/components/LoadingBox';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { Event, MarketCategoryItem } from '@/feature/event-details-sbk/types';
import { FeaturedBetCard } from '@/feature/lobby-sbk/components/FeaturedBets/CardEvent/FeaturedBetCard';
import { FeaturedBet } from '@/feature/lobby-sbk/components/FeaturedBets/types';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';

import { useLeagueMarketList } from '../hooks/use-league-market-list';
import { useLeagueTabs } from '../hooks/use-league-tabs';
import { GAME_SECTION_HEIGHT } from '../hooks/use-section-heights';

type FeaturedBetsContentProps = {
    leagueId: string;
    marketCategories: MarketCategoryItem[];
    events: Event[];
    tabKey: string;
};

export const FeaturedBetsContent = ({ leagueId, marketCategories, events, tabKey }: FeaturedBetsContentProps) => {
    const { t } = useTranslation('league_hub_sbk');
    const isFocused = useIsFocused();
    const { featuredBets, featuredBetsRefetch, featuredBetsIsLoading } = useLeagueTabs({
        leagueId,
        marketCategories,
        events,
        isFocused,
    });
    const { forceRefreshing, onRefresh } = useForceRefresh(featuredBetsRefetch);
    const { scrollableProps, setStickyRef, contentHeight } = useStickyTabList(tabKey, forceRefreshing, onRefresh);
    useFetchOnResume(featuredBetsRefetch);

    const { flatListProps } = useLeagueMarketList(
        !!featuredBets?.length,
        contentHeight,
        true,
        GAME_SECTION_HEIGHT,
        t('featured_bets_unavailable'),
        t('featured_bets_unavailable_description')
    );

    const listRef = useRef<FlatList<any> | null>();

    const renderCardBodyCallback = useCallback(({ item }: { item: FeaturedBet }) => {
        return (
            <Box marginTop={'s16'}>
                <FeaturedBetCard key={item.id} featuredBet={item} />
            </Box>
        );
    }, []);

    if (featuredBetsIsLoading) {
        return <LoadingBox style={{ paddingTop: GAME_SECTION_HEIGHT + TAB_HEIGHT }} />;
    }

    return (
        <Box>
            <Animated.FlatList
                data={featuredBets}
                renderItem={renderCardBodyCallback}
                keyExtractor={featuredBet => `${featuredBet.id}`}
                {...scrollableProps}
                // ! flatListProps should be after scrollableProps as it overrides contentContainerStyle
                {...flatListProps}
                // ! ref cannot be set through spread operator
                ref={ref => {
                    setStickyRef(ref);
                    listRef.current = ref;
                }}
            />
        </Box>
    );
};
