import { initReactI18next } from 'react-i18next';

import { getEnv } from '@/data/config';
import i18n from 'i18next';

import enTranslation from './locales/en/translation.json';

export const defaultNS = 'common';

// Bundled resources to be used for translations.
export const resources = {
    en: {
        ...enTranslation,
    },
};

export const setupI18N = () => {
    const ENV = getEnv();

    i18n.use(initReactI18next).init({
        defaultNS,
        debug: ENV === 'STAGING',
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        ns: Object.keys(enTranslation),
        compatibilityJSON: 'v3',
        resources,
    });

    // Custom formatter function to select the first available option based on the provided keys
    i18n.services.formatter?.add('selectFirst', (_value, _lng, options) => {
        if (typeof options?.interpolationkey === 'string') {
            // split the keys to get an array example: `line|milestone_yards` --> ['line', 'milestone_yards']
            const keys = options.interpolationkey.split('|');

            // loop keys and find first option[key] that exists and return it
            for (let i = 0; i < keys.length; i++) {
                if (options[keys[i]] !== undefined) {
                    return options[keys[i]];
                }
            }
        }

        return options.fallback;
    });

    type Num = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
    //Formatter used to transform a number to word: 1 -> One
    //To apply the formatter you need to pass numberToWord as the second argument in translation file, e.g.  {{number, numberToWord}}
    i18n.services.formatter?.add('numberToWord', value => {
        const num = Math.floor(Number(value));

        if (num >= 1 && num <= 20) {
            return i18n.t(`numbers:${num as Num}`);
        }

        return value.toString();
    });
};
