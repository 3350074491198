import * as React from 'react';
import { StyleSheet } from 'react-native';
import Svg, { G, Mask, Path } from 'react-native-svg';

const styles = StyleSheet.create({
    mask: {
        maskType: 'alpha',
    },
});

const SVGComponent = props => (
    <Svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Mask id="mask0_35694_33551" style={styles.mask} maskUnits="userSpaceOnUse" x={0} y={0} width={40} height={40}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38ZM20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                fill="white"
            />
        </Mask>
        <G mask="url(#mask0_35694_33551)">
            <Path
                d="M13.2493 1.17376C8.81682 2.76314 5.08732 5.86549 2.7175 9.93446C0.347675 14.0034 -0.510474 18.7781 0.294166 23.4176C1.09881 28.0571 3.51463 32.264 7.11622 35.2973C10.7178 38.3307 15.2742 39.996 19.983 40L20 20L13.2493 1.17376Z"
                fill="white"
            />
        </G>
    </Svg>
);
export default SVGComponent;
