import { BetSummaryProps, BetSummarySectionProps } from '@/components/BetSummarySection';

import { useSharedBetSummaryProps, useVoidedBetSummary } from '../bet-cards/utils';

/**
 * Shared bet summary props for Bet Details
 */
export const useSharedBetDetailsBetSummary = (props: BetSummaryProps) => {
    const bodyTextVariant = 'titleMedium' as const;
    return {
        ...useSharedBetSummaryProps(props),
        bodyTextVariant,
    };
};

/**
 * Handles all 'Voided' bet summary for Bet Details
 */
export const useBetDetailsVoidedBetSummary = (props: BetSummaryProps): BetSummarySectionProps => {
    const { bet } = props;
    return {
        ...useSharedBetDetailsBetSummary(props),
        ...useVoidedBetSummary(bet, 'gray4'),
    };
};
