import React, { memo } from 'react';

import { common, designSystem } from '@/styles/styles';

import { useResizeImage } from './hooks/use-resize-image.web';
import './playerImage.css';
import { PlayerImageCanvasProps } from './types';
import { sizes, teamColorCircleOffsets } from './utils';

export const PlayerImageCanvas = memo(
    ({ teamColor, source, bgColor, onLoad, hideImage, variant, onError }: PlayerImageCanvasProps) => {
        const containerSize = sizes[variant];

        const teamColorCircleOffset = teamColorCircleOffsets[variant];

        const teamColorCircleRadius = (containerSize.width - 8) / 2;

        const imageSource = useResizeImage({ source, onLoad, onError });

        return (
            <>
                <svg
                    width={containerSize.width}
                    height={containerSize.height}
                    fill="none"
                    className="playerTeamColorBlur"
                >
                    <g>
                        <circle
                            cx={containerSize.width / 2}
                            cy={containerSize.height - teamColorCircleOffset}
                            r={teamColorCircleRadius}
                            fill={teamColor || designSystem.colors[bgColor]}
                        />
                    </g>
                </svg>
                {!hideImage && imageSource ? (
                    // we cannot apply position absolute directly on img (ts error)
                    <div style={common.absolute}>
                        <img
                            alt=""
                            src={imageSource}
                            className="playerImage"
                            style={{ width: containerSize.width, height: containerSize.width }}
                        />
                    </div>
                ) : null}
            </>
        );
    }
);
