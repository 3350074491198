import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Animated, View } from 'react-native';
import { DateData } from 'react-native-calendars/src/types';

import { ChipButton } from '@/components/ChipButton';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Box } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { NoActivityHistory } from '@/feature/transactions/components/NoActivityHistory';
import { TransactionList } from '@/feature/transactions/components/TransactionList';
import { useInfiniteTransactionActivity } from '@/feature/transactions/hooks/use-infinite-transaction-activity';
import { common } from '@/styles/styles';

import { useCalendarSheet } from '../components/CalendarSheetProvider';
import { TRANSACTION_DATE_OPTIONS, TRANSACTION_TYPE_OPTIONS } from '../const';
import {
    TransactionDateLabel,
    TransactionDateOptions,
    TransactionDateOptionsType,
    TransactionFilterOption,
    TransactionTypeLabel,
    TransactionTypes,
    TransactionTypesType,
} from '../hooks/types';
import { TransactionFilterCategory, useFilterTransaction } from '../hooks/use-filter-transaction';
import { filterTransactionActivity } from '../utils/filter';
import { getSelectedOptionLabel, useFilterSelector } from '../utils/filter-selection-helper';
import { getDateRangeFromOption } from '../utils/get-date-range-from-option';

export const TransactionHistoryScreen = () => {
    const { t } = useTranslation('transactions');
    const { filterOptions, setFilter } = useFilterTransaction();

    const [customDateRange, setCustomDateRange] = useState<DateData[]>([]);

    const { data, isLoading, fetchNextPage, isFetchingNextPage } = useInfiniteTransactionActivity({
        ...getDateRangeFromOption(filterOptions.date, customDateRange),
        type: filterOptions.type,
    });
    const { showCalendarSheetModal } = useCalendarSheet();

    const scrollAnim = useRef(new Animated.Value(0));

    const createFilterSelector = useFilterSelector({ setFilter, filterOptions });

    const createSelector = (
        options: Array<TransactionFilterOption<TransactionTypesType | TransactionDateOptionsType>>,
        category: TransactionFilterCategory,
        defaultOption: {
            id: TransactionDateOptionsType | TransactionTypesType;
            label: TransactionTypeLabel | TransactionDateLabel;
            selectDate?: () => void;
            onApply?: (selectedDates: DateData[]) => void;
        }
    ) => {
        const select = () => createFilterSelector(options, category, defaultOption);

        const getSelectedLabel = () => getSelectedOptionLabel(options, category, defaultOption.id, filterOptions);

        return { select, getSelectedLabel };
    };

    const handleApplyDateRange = (selectedDates: DateData[]) => {
        setFilter('date', TransactionDateOptions.DATE_RANGE);
        setCustomDateRange(selectedDates);
    };

    const selectDate = () => {
        dateSelector.select();
    };

    const dateSelectorOnPress = () => {
        if (filterOptions.date === TransactionDateOptions.DATE_RANGE) {
            showCalendarSheetModal({
                selectDate,
                onApply: handleApplyDateRange,
                selectedDates: customDateRange,
            });
        } else {
            dateSelector.select();
        }
    };

    const typeSelector = createSelector(TRANSACTION_TYPE_OPTIONS, 'type', {
        id: TransactionTypes.ALL_TYPES,
        label: TransactionTypes.ALL_TYPES,
    });

    const dateSelector = createSelector(TRANSACTION_DATE_OPTIONS, 'date', {
        id: TransactionDateOptions.ALL_TIME,
        label: TransactionDateOptions.ALL_TIME,
        onApply: handleApplyDateRange,
        selectDate,
    });

    const secondaryBar = (
        <MaxWidthWrapper>
            <Box
                backgroundColor="gray8"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                gap="s10"
                paddingVertical="s10"
                paddingHorizontal="s16"
            >
                <ChipButton
                    label={t(typeSelector.getSelectedLabel() as TransactionTypeLabel)}
                    onPress={typeSelector.select}
                    isDropdown
                    testID="txtTypeDropdown"
                />
                <ChipButton
                    label={t(dateSelector.getSelectedLabel() as TransactionDateLabel)}
                    onPress={dateSelectorOnPress}
                    isDropdown
                    testID="timePeriodDropdown"
                />
            </Box>
        </MaxWidthWrapper>
    );

    const getViewContent = () => {
        const transactions = filterTransactionActivity(data?.pages);

        if (transactions?.length === 0) {
            return (
                <NoActivityHistory title={t('no_transaction_yet')} description={t('no_transaction_yet_description')} />
            );
        }

        return (
            <TransactionList
                transactions={transactions}
                fetchNextPage={() => !isFetchingNextPage && fetchNextPage()}
                isFetchingNextPage={isFetchingNextPage}
                scrollAnim={scrollAnim}
            />
        );
    };

    if (isLoading) {
        return (
            <View testID="loadingIndicator" style={[common.flex, common.alignCenter, common.justifyCenter]}>
                <ActivityIndicator />
            </View>
        );
    }

    const title = t('transaction_history');

    return (
        <Screen unsafe={false}>
            <ScreenNavBar {...{ title, scrollAnim, secondaryBar }} />
            <Box flex={1}>{getViewContent()}</Box>
        </Screen>
    );
};
