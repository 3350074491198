import { ImageStyle, StyleProp } from 'react-native';

export const gradientWidth = 744;

export const getRadialGradientStyle = (screenWidth: number): StyleProp<ImageStyle> => {
    if (gradientWidth < screenWidth) {
        return { width: screenWidth };
    } else {
        const marginLeft = (gradientWidth - screenWidth) / 2;
        return { marginLeft: -marginLeft };
    }
};
