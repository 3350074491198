import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetSubmissionStatus } from '@/feature/betslip-sbk/types';
import {
    comboOddsSelector,
    eventSelector,
    isOddsBetrBucksSelector,
    previousComboOddsSelector,
    selectionCountSelector,
    selectionSelector,
} from '@/feature/betslip-sbk/utils/betslip-selectors';
import { isComboSelectionEnabled } from '@/feature/betslip-sbk/utils/betslip-utils';
import { isOptionSuspended } from '@/utils/option-status';

export const useActiveSelectionCount = () =>
    useSbkBetSlipStore(
        state => Object.values(state.selections).filter(selection => isComboSelectionEnabled(selection, state)).length
    );

export const useSelectionCount = () => useSbkBetSlipStore(selectionCountSelector);

export const useEvent = (eventId: string) => useSbkBetSlipStore(eventSelector(eventId));

export const useSelection = (selectionId: string) => useSbkBetSlipStore(selectionSelector(selectionId));

export const useIsBetSlipSubmitting = () =>
    useSbkBetSlipStore(state => state.betSubmissionStatus === BetSubmissionStatus.Submitting);

export const useIsOddsBetrBucks = (betId: string) => useSbkBetSlipStore(isOddsBetrBucksSelector(betId));

export const useComboOdds = () => useSbkBetSlipStore(comboOddsSelector);

export const usePreviousComboOdds = () => useSbkBetSlipStore(previousComboOddsSelector);

export const useIsOptionSuspended = (optionId: string) => {
    return useSbkBetSlipStore(state => {
        const option = state.options[optionId];
        return isOptionSuspended(option.status, state.producerStatus, state.markets[option.marketId].published);
    });
};

export const useProducerStatus = () => useSbkBetSlipStore(state => state.producerStatus);

export const useIsSuspendedOption = () => {
    let isSuspendedOption = false;
    const producerStatus = useProducerStatus();
    useSbkBetSlipStore(state => {
        Object.values(state.options).forEach(option => {
            const optionStatus = option.status;
            const isMarketPublished = state.markets[option.marketId].published;
            if (isOptionSuspended(optionStatus, producerStatus, isMarketPublished)) {
                isSuspendedOption = true;
            }
        });
    });
    return isSuspendedOption;
};
