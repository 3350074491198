import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetCard } from '@/components/BetCard';
import { useMarketStatus } from '@/feature/bets-sbk/utils/bet-card-utils';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { Bet } from '@/feature/betslip-sbk/types';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { useOptionName } from '@/feature/event-details-sbk/hooks/use-option-name';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { renderDate } from '@/utils/renderDate';

export const SingleBetPlacedCard = ({
    submittedBet,
    onSharePress,
    testID,
}: {
    submittedBet: Bet;
    onSharePress: (betId: string) => void;
    testID?: string;
}) => {
    const { t } = useTranslation(['betslip_sbk', 'bets', 'wallet']);

    const { option, event, market, globalId } = useSbkBetSlipStore(state => {
        const submittedState = state.submittedState;
        const submittedBets = submittedState.submittedBets;
        const selectedBet = Object.values(submittedBets).find(bet => bet.localId === submittedBet.id);
        const selection = submittedState.selections[submittedBet.id];
        return {
            option: submittedState.options[submittedBet.id],
            event: submittedState.events[selection.eventId],
            market: submittedState.markets[selection.marketId],
            globalId: selectedBet?.globalId || '',
        };
    });

    const odds = option.odds;
    const { isBetrBucks, stake } = submittedBet;

    const title = useOptionName({
        optionType: option.optionType,
        marketType: market.marketType,
        fallback: option.description,
        homeTeam: event.home_team,
        awayTeam: event.away_team,
        sportName: event.sport.name,
        isDisplayFullName: true,
    });

    const marketName = useMarketName({
        sportName: event.sport.name,
        event: { home_team: event.home_team, away_team: event.away_team },
        fallback: market.description,
        marketType: market.marketType,
        player: market.player,
        appendPlayerName: true,
        appendMicroMarketDescription: market.isMicroMarket,
        isExtendedName: true,
    });

    const marketStatus = useMarketStatus(event);

    const eventStartTime = renderDate(new Date(event.start_time));

    const subTitle = `${marketName} • ${marketStatus} • ${eventStartTime}`;

    const summary = {
        header1Text: isBetrBucks ? t('wallet:betr_bucks') : t('bets:wager'),
        header2Text: t('multiplier'),
        header3Text: t('bets:to_pay'),
        body3Text: toLocaleCurrency(odds * (stake ?? 0)),
        multiplier: odds,
        isBetrBucks: !!isBetrBucks,
        stake: stake ?? 0,
        betSummarySeparatorColour: 'gray5' as const,
    };

    return (
        <BetCard
            title={title}
            subTitle={subTitle}
            summary={summary}
            outerBorderColor={'gray5'}
            shareIconInHeader={true}
            onSharePress={() => onSharePress(globalId)}
            testID={testID}
        />
    );
};
