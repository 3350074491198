import { useEffect, useState } from 'react';

import { logger } from '@/utils/logging';

import { addResizeParamToURL } from '../utils';

/**
 * Hook used to fetch the resized image for web.
 * @param {string} source - source of the image
 * @param {Function} onLoad
 * @returns {string}
 */
export const useResizeImage = ({
    source,
    onLoad,
    onError,
}: {
    source: string | undefined;
    onLoad?: () => void;
    onError?: (error: unknown) => void;
}) => {
    const [imageSource, setImageSource] = useState<string>();

    useEffect(() => {
        let url: string | null = null;

        const fetchResizedImage = async () => {
            if (!source) {
                return;
            }

            try {
                let response = await fetch(addResizeParamToURL(source));

                if (response.ok) {
                    const blob = await response.blob();
                    url = URL.createObjectURL(blob);
                    setImageSource(url);
                    onLoad?.();
                } else {
                    if (response.status === 415) {
                        logger.debug('[Resize]', `Error loading resized image - ${url} - `, response);
                        onError?.(new Error(`Error loading resized image - ${source}`));
                        return;
                    }

                    response = await fetch(source);
                    if (response.ok) {
                        const blob = await response.blob();
                        url = URL.createObjectURL(blob);
                        setImageSource(url);
                        onLoad?.();
                    } else {
                        logger.debug('[Resize]', `Error loading original image - ${url} - `, response);
                        onError?.(new Error(`Error loading original image - ${url}`));
                    }
                }
            } catch (e) {
                logger.debug('[Resize]', `Error loading any images - ${url} - `, e);
                onError?.(new Error(`Error loading any images - ${url}`));
            }
        };

        fetchResizedImage();

        return () => {
            // Cleanup function to revoke the object URL so we do not have memory issues
            if (url !== null) {
                URL.revokeObjectURL(url);
                url = null;
            }
        };
    }, [source, onLoad, onError]);

    return imageSource;
};
