import React from 'react';

import CollegeBasketballShirtSvg from '@/assets/images/CollegeBasketballShirtSvg';
import { basketballDefaultValues } from '@/components/player-profile/tiles/college-sports/config';

import { CollegeBasketballProps } from './types';

export const CollegeBasketballShirt = ({
    teamColor,
    secondaryColor,
    width = basketballDefaultValues.width,
    height = basketballDefaultValues.height,
    // the default values are divided by 1.5 to scale the mask properties
    // if we pass different values for the mask properties, we need to scale them accordingly
    // that's why here, the default values are divided by 1.5, since the mask properties are scaled to 150%
    // but if we send different values for the mask properties, we need to scale them accordingly
    blur = basketballDefaultValues.blur / 1.5,
    maskWidth = basketballDefaultValues.maskWidth / 1.5,
    maskHeight = basketballDefaultValues.maskHeight / 1.5,
    maskX = basketballDefaultValues.maskX / 1.5,
    maskY = basketballDefaultValues.maskY / 1.5,
    playerId,
}: CollegeBasketballProps) => {
    return (
        <CollegeBasketballShirtSvg
            secondaryColor={secondaryColor}
            color={teamColor}
            width={width}
            height={height}
            // The SVG mask on web is scaled to 150% of the original size
            // so we need to scale the mask properties accordingly
            blur={blur * 1.5}
            rx={(maskWidth / 2) * 1.5}
            ry={(maskHeight / 2) * 1.5}
            cx={(maskX + maskWidth / 2) * 1.5}
            cy={(maskY + maskHeight / 2) * 1.5}
            id={playerId}
        />
    );
};
