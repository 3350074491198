import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { Masks } from 'react-native-mask-input';

import { TextInput } from '@/components/TextInput';
import { AnimatedPressableOpacity } from '@/components/animated-pressable-opacity/AnimatedPressableOpacity';
import { Box } from '@/components/lib/components';
import { format, parse } from 'date-fns';

export type InputProps = {
    value: string;
    setFieldValue: (value: string) => void;
    autoFocus?: boolean;
    errorMessage?: string;
    onFocus?: () => void;
};

export const DobInput: React.FC<InputProps> = ({
    value,
    autoFocus,
    errorMessage,
    setFieldValue,
    onFocus = () => {},
}) => {
    const { t } = useTranslation('kyc');

    // Using Platform.OS directly instead of isWeb util due to test environment limitations
    if (Platform.OS === 'web') {
        return (
            <TextInput
                testID="dob-web-input"
                label="MM/DD/YYYY"
                keyboardType="numeric"
                value={value}
                onChangeText={(_masked: string, unmasked: string) => setFieldValue(unmasked)}
                mask={Masks.DATE_MMDDYYYY}
                hasError={!!errorMessage}
                errorText={errorMessage}
                onFocus={onFocus}
            />
        );
    }

    return (
        <View testID="dobInput">
            <AnimatedPressableOpacity testID="dob-input-pressable" onPress={onFocus} accessible={false}>
                <Box pointerEvents="none">
                    <TextInput
                        testID="dob-mobile-input"
                        label={t('date_of_birth')}
                        value={value ? format(parse(value, 'MMddyyyy', new Date()), 'MM/dd/yyyy') : ''}
                        editable={false}
                        hasError={!!errorMessage}
                        errorText={errorMessage}
                        autoFocus={autoFocus}
                    />
                </Box>
            </AnimatedPressableOpacity>
        </View>
    );
};
