import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { Header } from '@/feature/betslip-pickem/components/Header';
import { runStoreUpdate } from '@/utils/zustand';

import { useIsBetSlipSubmitting } from '../hooks/betslip-hooks';
import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';

export const BetSlipHeader: React.FC = () => {
    const clearBetSlip = useSbkBetSlipStore(state => state.actions.clearBetSlip);
    const { showInfoSheet } = useAlerts();
    const { t } = useTranslation('common');
    const isBetSlipSubmitting = useIsBetSlipSubmitting();

    const handleClearPress = () => {
        if (isBetSlipSubmitting) {
            return;
        }

        showInfoSheet({
            title: 'Clear bet slip?',
            description: "You'll not be able to undo.",
            buttonLabel: 'Clear Bet Slip',
            handlePress: () => {
                GASbkEvents.clearBetSlip();
                // We want to batch update the store to prevent crash here
                runStoreUpdate(clearBetSlip);
            },
            errorStyling: true,
            showCancel: true,
        });
    };

    return (
        <Header
            isSubmitting={isBetSlipSubmitting}
            rightItem={
                <Box borderBottomColor="red" borderBottomWidth={1}>
                    <Text variant="buttonLabel" color="red" onPress={handleClearPress}>
                        {t('clear')}
                    </Text>
                </Box>
            }
            onRightItemPress={handleClearPress}
        />
    );
};
