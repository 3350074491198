import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import VoidedProgressDot from '@/assets/icons/voided-progress-dot';
import { BetDetailShell } from '@/components/BetDetailShell';
import { Box } from '@/components/lib/components';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { designSystem, withOpacity } from '@/styles/styles';
import { format } from 'date-fns';

import { Bet } from '../hooks/types';
import { getProgressDots } from '../utils/utils';
import { EventGrouping } from './EventGrouping';
import { BetCashout } from './bet-details/BetCashout';
import { BetSummarySection } from './bet-details/BetSummarySection';

const useBetTitle = (bet: Bet) => {
    const { t } = useTranslation('bets');
    switch (bet.bet_type) {
        case 'SINGLE':
            return `${bet.events[0].away_team.short_code} @ ${bet.events[0].home_team.short_code} ${t('single')}`;
        case 'SCRIPT':
            return t('script_bet_name', { count: bet.selection_count });
        case 'COMBO':
            return t('combo_bet_name', { count: bet.selection_count });
    }
};

export const formatDate = (dateString: number | null): string =>
    dateString ? format(new Date(dateString), 'Pp') : '--';

const useBetFooter = (bet: Bet) => {
    const { t } = useTranslation(['bets', 'bet_status']);
    return [
        {
            label: `${t('bet_id')}:`,
            value: bet.id,
            testID: 'bet-id',
        },
        {
            label: `${t('location')}:`,
            value: bet.location,
        },
        {
            label: `${t('placed')}:`,
            value: formatDate(bet.created_at),
        },
        {
            label: `${t('settled')}:`,
            value: bet.settled_at ? formatDate(bet.settled_at) : '--',
        },
        {
            label: `${t('result')}:`,
            value: bet.result !== 'PENDING' ? t(`bet_status:${bet.result}`) : '--',
        },
    ];
};

const gradientColors = (color: string) => {
    return [withOpacity(color, 0.15), withOpacity(color, 0)];
};

const useBetDetail = (bet: Bet) => {
    return {
        title: useBetTitle(bet),
        progressDots: getProgressDots(bet),
        summary: <BetSummarySection bet={bet} />,
        footer: useBetFooter(bet),
        gradientColor: gradientColors(designSystem.colors.purple1), // Keep top gradient purple for every scenarios for now
        hasActionTray: bet.can_be_cashed_out,
        shouldShowProgressDots: bet.bet_type !== 'SINGLE',
        canBeCashedOut: bet.can_be_cashed_out,
    };
};

type SBKBetDetailProps = {
    bet: Bet;
    isRefreshing?: boolean;
    onRefresh?: () => void;
};

export const SBKBetDetail = ({ bet, isRefreshing, onRefresh }: SBKBetDetailProps) => {
    const betDetailShellProps = useBetDetail(bet);
    const isVoided = bet.result === 'CANCELLED' || bet.result === 'PUSHED';
    const { t } = useTranslation(['bets']);
    const navigation = useNavigation();

    const onSharePress = () => {
        navigation.navigate('BetShareScreen', { betId: bet.id, bet });
    };

    return (
        <>
            <BetDetailShell
                isRefreshing={isRefreshing}
                onRefresh={onRefresh}
                onSharePress={onSharePress}
                {...betDetailShellProps}
            >
                {isVoided ? (
                    <Banner
                        leftIcon={<VoidedProgressDot width={20} height={20} fill={'#EDEFF3'} />}
                        message={t('bet_voided_warning')}
                        backgroundColor="gray6"
                    />
                ) : null}
                <Box alignItems="center">
                    <EventGrouping bet={bet} />
                </Box>
            </BetDetailShell>
            <BetCashout betId={bet.id} canCashout={bet.can_be_cashed_out} title={betDetailShellProps.title} />
        </>
    );
};
