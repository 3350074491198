import { useEffect } from 'react';

import { useIsBetSlipSubmitting, useIsOddsBetrBucks } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useBetStakeLimit } from '@/feature/betslip-sbk/hooks/use-bet-stake-limit';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetTypes } from '@/feature/betslip-sbk/types';
import { totalBetrBucksStakeSelector, totalCashStakeSelector } from '@/feature/betslip-sbk/utils/betslip-selectors';
import {
    getNextValueOnBackspacePress,
    getNextValueOnDecimalPress,
    getNextValueOnNumberPress,
} from '@/utils/currency-num-pad-utils';

import { useStakeInputValidation } from './use-stake-input-validation';
import { useTotalStakeValidation } from './use-total-stake-validation';

export const useBetSlipNumPad = (editBetId: string | null) => {
    const updateStake = useSbkBetSlipStore(state => state.actions.updateStake);
    const stakeDisplay = useSbkBetSlipStore(state => state.bets[editBetId!]?.displayStake ?? '');
    const validateStakeInput = useStakeInputValidation();
    const validateTotalStake = useTotalStakeValidation();
    const totalCashStake = useSbkBetSlipStore(totalCashStakeSelector);
    const totalBetrBucksStake = useSbkBetSlipStore(totalBetrBucksStakeSelector);
    const isBetSlipSubmitting = useIsBetSlipSubmitting();
    const { data: stakeLimit } = useBetStakeLimit(editBetId || '');
    const isBetrBucks = useIsOddsBetrBucks(editBetId || '');

    useEffect(() => {
        validateTotalStake(totalCashStake, totalBetrBucksStake);
    }, [totalCashStake, totalBetrBucksStake, validateTotalStake]);

    useEffect(() => {
        if (editBetId && !isNaN(Number(stakeDisplay))) {
            validateStakeInput(editBetId, Number(stakeDisplay), isBetrBucks, stakeLimit);
        }
    }, [stakeLimit, editBetId, isBetrBucks, stakeDisplay, validateStakeInput]);

    const handleNextStake = (nextStake: string) => {
        const canUpdateStake = editBetId && nextStake !== stakeDisplay && !isBetSlipSubmitting;
        if (canUpdateStake) {
            const nextStakeNumber = Number(nextStake);
            const betType = editBetId === 'COMBO' ? BetTypes.Combo : BetTypes.Single;
            updateStake(editBetId, nextStakeNumber, nextStake, betType);
            if (!isNaN(nextStakeNumber)) {
                validateStakeInput(editBetId, nextStakeNumber, isBetrBucks);
            }
        }
    };

    const onNumberPress = (number: number) => handleNextStake(getNextValueOnNumberPress(stakeDisplay, number));

    const onDecimalPress = (decimal: '.') => handleNextStake(getNextValueOnDecimalPress(stakeDisplay, decimal));

    const onBackspacePress = () => handleNextStake(getNextValueOnBackspacePress(stakeDisplay));

    return {
        onNumberPress,
        onDecimalPress,
        onBackspacePress,
    };
};
