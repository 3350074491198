import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            stroke={props.color ?? designSystem.colors.gray1}
            strokeWidth={2}
            d="M3 9V3h6M15 3h6v6M9 21H3v-6M21 15v6h-6"
        />
    </Svg>
);
export default SvgComponent;
