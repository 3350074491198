import { BadgeStatus } from '@/components/StatusBadge';
import { defaultNegativeToZero, getToWin } from '@/hooks/use-entries-utils';
import { Currency, EventStatus, GameMode, GameType, League, Result, Status } from '@/types/api.generated';
import { renderDate } from '@/utils/renderDate';
import { format, parseISO } from 'date-fns';
import { TFunction } from 'i18next';

import { EntryItemPick, EntryItemType } from './components/EntryItem';

const titleModes = {
    P2P: 'Perfect Group Play',
    DYNAMIC: 'Dynamic Play',
    PERFECT: 'Perfect Play',
};

export const getEntryTitle = (gameMode?: GameMode, gameType?: GameType) => {
    return gameType === GameType.P2P ? titleModes.P2P : gameMode ? titleModes[gameMode] : '';
};

export const getEntryCurrencyTitle = (currency?: Currency) => {
    return currency === Currency.Fre ? 'Betr Bucks' : 'Entry';
};

export const sortOnTimestamp = (a: EntryItemType, b: EntryItemType) => {
    if (a.settlementDate && b.settlementDate) {
        return Date.parse(b.settlementDate) - Date.parse(a.settlementDate);
    }
    return Date.parse(b.createdDate) - Date.parse(a.createdDate);
};

export const getEntryStatusBadge = (args: { isRefunded: boolean; status?: Status; result?: Result }) => {
    const { isRefunded, status, result } = args;

    if (status === Status.Pending) {
        return BadgeStatus.Open;
    }

    if (isRefunded) {
        return BadgeStatus.Refunded;
    }

    if (result === Result.Win) {
        return BadgeStatus.Won;
    }

    return BadgeStatus.Lost;
};

export const getEntryAmountText = (entryStatus: BadgeStatus) => {
    if (entryStatus === BadgeStatus.Open) {
        return 'to_win';
    }

    if (entryStatus === BadgeStatus.Refunded) {
        return 'bets:refunded';
    }

    return 'bets:paid';
};

export const getAmount = (entry?: EntryItemType) => {
    if (entry?.result === Result.Void) {
        return `$${defaultNegativeToZero(entry?.amount)}`;
    }

    if (entry?.result === Result.Loss && entry?.status !== Status.Pending) {
        return '--';
    }

    return `$${defaultNegativeToZero(getToWin(entry))}`;
};

export const getGameType = (t: TFunction<['common', 'bets'], undefined>, gameMode?: GameMode) => {
    if (gameMode === GameMode.Dynamic) {
        return t('dynamic_play');
    }

    return t('perfect_play');
};

export const getCardState = (
    picks: EntryItemPick[],
    createdDate: string,
    t: TFunction<['common', 'bets'], undefined>
) => {
    const eventIds = picks.map(pick => pick.eventId);
    const uniqueEventIds = new Set(eventIds);
    const isSameEvent = uniqueEventIds.size === 1;

    const date = new Date(createdDate);
    const dateString = format(date, 'M/d/yyyy');
    const timeString = format(date, 'h:mm a');
    const liveEvents = picks.filter(pick => pick.eventStatus === EventStatus.InProgress).length;

    if (picks.every(pick => pick.result)) {
        return `${dateString}\n at ${timeString}`;
    }

    if (isSameEvent && liveEvents === 0) {
        return t('bets:start_time', { time: renderDate(parseISO(picks[0].eventDate)) });
    } else {
        if (liveEvents > 0) {
            return t('bets:pick_live', { count: liveEvents });
        } else {
            const sortedPicks = picks.sort((a, b) => Date.parse(a.eventDate) - Date.parse(b.eventDate));
            const firstEvent = sortedPicks[0];
            const nextEvent = sortedPicks.find(pick => !pick.result);
            const isEvent = (pick: EntryItemPick) => {
                if ([League.Pga, League.Ufc].includes(pick.league)) {
                    return true;
                }
                return false;
            };
            if (!firstEvent.result) {
                return t(isEvent(firstEvent) ? 'bets:first_event' : 'bets:first_game', {
                    time: renderDate(parseISO(firstEvent.eventDate)),
                });
            } else if (nextEvent) {
                return t(isEvent(nextEvent) ? 'bets:next_event' : 'bets:next_game', {
                    time: renderDate(parseISO(nextEvent.eventDate)),
                });
            }
        }
    }
};
