import { ProductDocumentDataBodyLobbycomponentsSliceItem } from '@/types/prismic.generated';

export enum LobbyComponent {
    PromoCarousel = 'promo_carousel',
    Leagues = 'leagues',
    TrendingPlayersList = 'trending_players',
    PrebuiltLineups = 'prebuilt_lineups',
    PopularPlayers = 'popular_players',
}

export const DEFAULT_LOBBY_ORDERING: ProductDocumentDataBodyLobbycomponentsSliceItem[] = [
    { enabled: true, header: '', orderingkey: LobbyComponent.Leagues },
    { enabled: true, header: '', orderingkey: LobbyComponent.PromoCarousel },
    { enabled: true, header: 'Featured lineups', orderingkey: LobbyComponent.PrebuiltLineups },
    { enabled: true, header: 'Trending Players', orderingkey: LobbyComponent.TrendingPlayersList },
    { enabled: true, header: 'Top 10 players', orderingkey: LobbyComponent.PopularPlayers },
];
