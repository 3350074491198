import { PermissionStatus } from 'react-native-permissions';

import Geolocation from '@react-native-community/geolocation';

import { locationSimulator } from '@/utils/locationSimulator';
import { logger } from '@/utils/logging';

const tag = 'LOCATION WEB';

export const checkLocationPermissionGranted = async () => {
    return (await getLocationPermissionStatus()) === 'granted';
};

export const getLocationPermissionStatus = async () => {
    if (locationSimulator.isEnabled()) {
        return Promise.resolve(locationSimulator.permissionStatus);
    }
    const result = await navigator.permissions.query({ name: 'geolocation' });
    return result.state;
};

export const requestLocationPermission = async (): Promise<PermissionStatus | undefined> => {
    if (locationSimulator.isEnabled()) {
        return locationSimulator.permissionStatus;
    }
    return new Promise(resolve => {
        // trigger the browser's location prompt
        Geolocation.getCurrentPosition(
            () => {
                logger.debug(tag, 'Location granted');
                resolve('granted');
            },
            error => {
                logger.debug(tag, 'Web location error', error);
                resolve('denied');
            },
            {}
        );
    });
};
