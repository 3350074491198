import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import { useFocusEffect, useNavigation } from '@react-navigation/native';

import InfoIcon from '@/assets/icons/info-full-white';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { trackRUMAction, trackRUMView } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { common } from '@/styles/styles';

export const InvalidPicksInfo = () => {
    const { t } = useTranslation('betslip_pickem');
    const navigation = useNavigation();

    const handlePress = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.CLICK, { button: 'Invalid picks card' });
        trackRUMAction('invalid-picks-pressed');
        navigation.navigate('PickemHome', {
            screen: 'Lobby',
            params: {
                screen: 'LobbyScreen',
            },
        });
    };

    useFocusEffect(
        useCallback(() => {
            BetrAnalytics.trackEvent(AnalyticsEvent.VIEW, { content: 'Invalid picks card' });
            BetrAnalytics.trackEvent(AnalyticsEvent.PRE_SUBMIT_ERRORS, {
                errorCode: '101',
                errorKey: 'INVALID_CRITERIA',
            });
        }, [])
    );

    useEffect(() => {
        trackRUMView('invalid-picks');
    }, []);

    return (
        <TouchableOpacity onPress={handlePress} accessible={false}>
            <Row
                backgroundColor={'gray6'}
                borderRadius={'r10'}
                paddingHorizontal={'s16'}
                paddingVertical={'s14'}
                marginBottom={'s24'}
            >
                <View style={common.justifyCenter}>
                    <InfoIcon />
                </View>
                <SizedBox value={16} />
                <View style={common.flex}>
                    <Text variant={'titleMedium'}>{t('more_players')}</Text>
                    <Text variant={'bodySmall'} color={'gray2'}>
                        {t('no_valid_picks')}
                        <Text variant={'titleSmall'}> {t('add_players')}</Text>
                    </Text>
                </View>
            </Row>
        </TouchableOpacity>
    );
};
