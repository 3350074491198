import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAllLeaguesUpcomingEventsQuery } from '@/api/events/query.generated';
import {
    GetAllLeaguesConfigsQueryVariables,
    LeagueConfigsFragment,
    useGetAllLeaguesConfigsQuery,
} from '@/api/leagues/query.generated';
import { STATES } from '@/feature/kyc/const';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { League } from '@/types/api.generated';
import { nflFullSeason } from '@/utils/league';
import { isEqual } from 'lodash';
import { RequestPolicy, UseQueryArgs } from 'urql';

export const useLeaguesConfigs = (options?: Omit<UseQueryArgs<GetAllLeaguesConfigsQueryVariables>, 'query'>) => {
    const { session, guest } = useUser();
    return useGetAllLeaguesConfigsQuery({
        ...options,
        requestPolicy: options?.requestPolicy ?? 'cache-first',
        pause: (!session?.access_token && !guest) || options?.pause,
    });
};

type UpcomingEvents = {
    _typename?: string;
    id: string;
    league: League;
};

/**
 * returns the array of leagues enabled & available in the current jurisdiction. All sorted by order
 */
export const useGetOrderedLeagues = (pause = false) => {
    const [{ data, fetching }, refetchLeagues] = useLeaguesConfigs({ pause });
    const jurisdiction = useJurisdictionStore(state => state.jurisdiction);

    const leagues = useMemo(
        () =>
            getActiveLeaguesForJurisdiction(data?.getLeagueConfigs, jurisdiction)
                .filter(league => league.enabled)
                .sort((a, b) => a.order - b.order),

        [data?.getLeagueConfigs, jurisdiction]
    );

    return {
        fetching,
        leagues,
        refetchLeagues,
    };
};

type SortedLeagues = {
    league: LeagueConfigsFragment;
    eventsCount: number;
};

/**
 * hook that returns the leagues sorted by the number of events they have
 * @param pause: boolean - if true, the `useAllLeaguesUpcomingEventsQuery` query will not be executed
 */
export const useGetOrderedLeaguesByEvents = (pause = true, requestPolicy: RequestPolicy = 'cache-and-network') => {
    const [{ data }, execute] = useAllLeaguesUpcomingEventsQuery({
        pause,
        requestPolicy,
    });

    const { leagues, refetchLeagues, fetching } = useGetOrderedLeagues(pause);

    const refetch = useCallback(() => {
        refetchLeagues({ requestPolicy });
        execute({ requestPolicy });
    }, [execute, refetchLeagues, requestPolicy]);

    const [sortedLeagues, setSortedLeagues] = useState<SortedLeagues[]>([]);
    const [isSorting, setIsSorting] = useState(true);
    useEffect(() => {
        const updatedLeagues = sortLeaguesByEvents(leagues, data?.getUpcomingEventsV2);

        if (!isEqual(sortedLeagues, updatedLeagues)) {
            setSortedLeagues([...updatedLeagues]);
        }

        if (data) {
            setIsSorting(false);
        }
    }, [data, leagues, sortedLeagues]);

    return { sortedLeagues, isSorting, fetching, refetch };
};

/**
 *
 * @param {LeagueConfigsFragment[]} leagues - list of all leagues
 * @param events
 * @returns
 */
export const sortLeaguesByEvents = (leagues: LeagueConfigsFragment[], events: UpcomingEvents[] | undefined) => {
    const leaguesWithEvents: SortedLeagues[] = [];
    const emptyLeagues: SortedLeagues[] = [];

    leagues.forEach(league => {
        const count = events?.filter(it => it.league === league.league).length;

        if (count === 0 && !nflFullSeason(league.league)) {
            emptyLeagues.push({ league, eventsCount: 0 });
        } else {
            leaguesWithEvents.push({ league, eventsCount: count ?? 0 });
        }
    });

    const updatedLeagues = [...leaguesWithEvents, ...emptyLeagues];

    return updatedLeagues;
};

/**
 * filters all the leagues that are ENABLED & active in the current jurisdiction
 */
export const getActiveLeaguesForJurisdiction = (
    leagueConfigs?: LeagueConfigsFragment[],
    jurisdiction?: string
): LeagueConfigsFragment[] => {
    if (!leagueConfigs || !jurisdiction) {
        return [];
    }

    const enabledLeagues = leagueConfigs.filter(league => league.enabled);
    const enabledLeaguesForJurisdiction = enabledLeagues.filter(league => {
        const enabledForUnknownVal = league.jurisdictions.find(j => j.symbol === 'UNKNOWN');
        const userIsNotInUS = !STATES.find(state => state.value === jurisdiction);
        const enabledForStatesOutsideUS = enabledForUnknownVal && userIsNotInUS;

        const enabledForCurrentState = league.jurisdictions.find(j => j.symbol === jurisdiction);
        return enabledForStatesOutsideUS || enabledForCurrentState;
    });

    return enabledLeaguesForJurisdiction || [];
};
