import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetCard } from '@/components/BetCard';
import { useSubTitle } from '@/feature/bets-sbk/components/bet-cards/ComboSbkBetCard';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { Bet, BetSlipEvent } from '@/feature/betslip-sbk/types';
import { toLocaleCurrency } from '@/utils/numeric/currency';

export const ComboBetPlacedCard = ({
    submittedBet,
    onSharePress,
    testID,
}: {
    submittedBet: Bet;
    onSharePress: (betId: string) => void;
    testID?: string;
}) => {
    const { uniqueEvents, count, odds, globalId } = useSbkBetSlipStore(state => {
        const submittedState = state.submittedState;
        const comboBet = submittedState.comboBet!;
        const submittedBets = submittedState.submittedBets;
        const selectedBet = Object.values(submittedBets).find(bet => bet.localId === submittedBet.id);
        const comboBetSelections = comboBet.selections;
        const uniqueBetEvents = comboBetSelections.reduce((acc, selection) => {
            const eventId = selection.eventId;
            if (!acc.get(eventId)) {
                acc.set(eventId, submittedState.events[eventId]);
            }
            return acc;
        }, new Map<string, BetSlipEvent>());
        return {
            uniqueEvents: uniqueBetEvents,
            count: comboBetSelections.length,
            odds: comboBet.odds,
            globalId: selectedBet?.globalId || '',
        };
    });

    const { t } = useTranslation(['bets', 'wallet']);

    const { isBetrBucks, stake } = submittedBet;

    const title = t('bets:combo_bet_name', { count });

    const summary = {
        header1Text: isBetrBucks ? t('wallet:betr_bucks') : t('bets:wager'),
        header2Text: t('multiplier'),
        header3Text: t('bets:to_pay'),
        stake: stake ?? 0,
        odds,
        isBetrBucks: !!isBetrBucks,
        multiplier: odds,
        body3Text: toLocaleCurrency(odds * (stake ?? 0)),
        betSummarySeparatorColour: 'gray5' as const,
    };

    const subTitle = useSubTitle([...uniqueEvents.values()]);

    return (
        <BetCard
            title={title}
            subTitle={subTitle}
            summary={summary}
            outerBorderColor={'gray5'}
            shareIconInHeader={true}
            onSharePress={() => onSharePress(globalId)}
            testID={testID}
        />
    );
};
