import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Event, MarketCategoryItem, MarketCategoryType } from '@/feature/event-details-sbk/types';
import { useFeaturedBets } from '@/feature/lobby-sbk/hooks/use-featured-bets';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { getSportKey } from '@/utils/get-sport-key';

interface UseLeagueTabsProps {
    marketCategories: MarketCategoryItem[];
    events: Event[];
    leagueId: string;
    isFocused?: boolean;
}

export type BaseTab = {
    id: string;
    type: MarketCategoryType | 'featured';
};

export type LeagueTab = BaseTab & {
    keyTab: number;
    title: string;
};

/**
 * Hook to populate league tabs with market categories and featured bets
 */
export const useLeagueTabs = ({ marketCategories, events, leagueId, isFocused }: UseLeagueTabsProps) => {
    const { t } = useTranslation(['league_hub_sbk', 'market_categories']);
    const { featuredBetsEnabled } = useJurisdictionStore(state => ({
        featuredBetsEnabled: state.jurisdictionSettings?.productConfig?.settings?.sbk_lobby_featured_bets_enabled,
    }));

    const {
        data: featuredBets,
        refetch: featuredBetsRefetch,
        isLoading: featuredBetsIsLoading,
        isPlaceholderData,
        isFetching,
    } = useFeaturedBets({ leagueId }, featuredBetsEnabled && isFocused);

    const showFeaturedBets = featuredBetsEnabled && featuredBets?.length;

    const tabs = useMemo((): LeagueTab[] => {
        const marketCategoriesWithFeaturedBets: BaseTab[] = [...marketCategories];
        if (showFeaturedBets) {
            marketCategoriesWithFeaturedBets.unshift({
                id: 'featured',
                type: 'featured',
            });
        }

        return (
            marketCategoriesWithFeaturedBets.map((item, i) => {
                const sportNamespace = getSportKey(events[0]?.sport);
                const key = `${sportNamespace}.${item.type}`;
                const title = t(`market_categories:${key}`, {
                    defaultValue: t(`market_categories:${item.type}`),
                }) as string;
                return {
                    keyTab: i,
                    title,
                    id: item.id,
                    type: item.type,
                };
            }) ?? []
        );
    }, [marketCategories, events, t, showFeaturedBets]);

    const isTabBarVisible = tabs.length > 1;
    const isFeaturedBetsInitialLoading = featuredBets?.length === 0 && isFetching && isPlaceholderData;

    return {
        tabs,
        isTabBarVisible,
        showFeaturedBets,
        featuredBets,
        featuredBetsRefetch,
        featuredBetsIsLoading,
        isFeaturedBetsInitialLoading,
    };
};
