import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import IconPencil from '@/assets/icons/pencil';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { useWallet } from '@/hooks/use-wallet';
import { Currency, GameMode, GameType } from '@/types/api.generated';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';

import { useBetslipData } from '../hooks/use-betslip-data';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { useQuickAmounts } from '../hooks/use-quick-amounts';
import { getWinningPicksAndToWin } from '../utils/betslip-utils';

const QuickAmountButtons = ({
    gameMode,
    setInputValue,
    selectedCurrency,
}: {
    gameMode: GameMode;
    selectedCurrency: Currency;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const { betrBucks, realMoneyTotal } = useWallet();
    const quickAmountsButtons = useQuickAmounts();

    const navigation = useNavigation();
    const validationData = useBetslipStore(state => state.validationData[gameMode]);
    const maxAllowedEntryAmount = validationData.amountValidation?.maxAllowedEntryAmount;
    const usingUsd = selectedCurrency === Currency.Usd;
    const maxValue = usingUsd
        ? Math.min(maxAllowedEntryAmount, realMoneyTotal)
        : Math.min(maxAllowedEntryAmount, betrBucks);

    const handleQuickAmount = (value: number) => {
        setInputValue(value.toString());
    };

    const handleEditQuickAmounts = () => {
        navigation.navigate('EditQuickAmounts');
    };

    return (
        <Box gap="s8" flexDirection="row">
            {quickAmountsButtons.map(el => (
                <Button
                    key={el.id}
                    label={`$${el.value}`}
                    onPress={() => handleQuickAmount(el.value ?? 0)}
                    shape="pill"
                />
            ))}
            <Button label="Max" onPress={() => handleQuickAmount(maxValue)} shape="pill" />
            <Button label={<IconPencil />} shape="pill" hierarchy={'tertiary'} onPress={handleEditQuickAmounts} />
        </Box>
    );
};

const WinningDescription = ({
    gameMode,
    entryAmount,
    gameType,
}: {
    gameMode: GameMode;
    entryAmount: number;
    gameType: GameType;
}) => {
    const { dynamicMultipliers, dynamicBoostedMultiplier, perfectActiveMultiplier } = useBetslipData();
    const validationData = useBetslipStore(state => state.validationData);
    const { t } = useTranslation(['betslip_pickem', 'bets']);

    const { highestToWin, highestMultiplier } = getWinningPicksAndToWin(
        dynamicMultipliers.DYNAMIC,
        dynamicBoostedMultiplier
    );

    const toWin = gameMode === GameMode.Perfect ? validationData[gameMode].betValidation?.toWin : highestToWin;
    const multiplier = gameMode === GameMode.Perfect ? perfectActiveMultiplier : highestMultiplier;

    const showAmount = entryAmount > 0 && !!toWin && toWin > 0;
    const getTextFormat = () =>
        gameType === GameType.P2P || gameMode === GameMode.Dynamic ? `${t('wins_up_to')}` : t('bets:wins');

    const textFormat = getTextFormat();
    const winning = showAmount
        ? `${textFormat} ${toLocaleCurrency(toWin, false)} (${multiplier}x)`
        : `${textFormat} ${multiplier}x`;

    return (
        <Text variant={'bodyLarge'} color={'gray1'}>
            {winning}
        </Text>
    );
};

export const EntryInputContent = ({
    gameMode,
    gameType,
    entryAmount,
    displayValue,
    dynamicPayoutSheet,
    setInputValue,
    selectedCurrency,
}: {
    gameMode: GameMode;
    gameType: GameType;
    entryAmount: number;
    displayValue: string;
    selectedCurrency: Currency;
    dynamicPayoutSheet: React.RefObject<BottomSheetModalMethods>;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const { t } = useTranslation(['bets']);

    return (
        <Box flex={1} alignItems={'center'} justifyContent={'center'}>
            <Row mr={'s20'}>
                <Text fontSize={32} fontWeight={'500'} lineHeight={66} numberOfLines={1} adjustsFontSizeToFit>
                    $
                </Text>
                <Box flexShrink={1}>
                    <Text variant={'displayLarge'} numberOfLines={1} textAlign={'center'} adjustsFontSizeToFit>
                        {displayValue}
                    </Text>
                </Box>
            </Row>
            <Box justifyContent={'space-between'} alignItems={'center'}>
                <Box alignItems={'center'} gap="s20">
                    <WinningDescription gameMode={gameMode} entryAmount={entryAmount} gameType={gameType} />
                    {gameMode === GameMode.Dynamic ? (
                        <TouchableBox activeOpacity={0.8} onPress={() => dynamicPayoutSheet.current?.present()}>
                            <Text variant={'titleMedium'} color={'purple1'} mt={'s4'}>
                                {t('see_payout_breakdown')}
                            </Text>
                        </TouchableBox>
                    ) : null}
                    <QuickAmountButtons
                        setInputValue={setInputValue}
                        gameMode={gameMode}
                        selectedCurrency={selectedCurrency}
                    />
                </Box>
            </Box>
        </Box>
    );
};
