import React, { useCallback } from 'react';
import { FlatList, StyleSheet } from 'react-native';

import { MatchupHeader } from '@/components/bet-list/MatchupHeader';
import { Box } from '@/components/lib/components';
import { TeamLogos } from '@/components/scoreboard/types';
import { useFormatEventHeader } from '@/feature/betslip-sbk/hooks/use-format-event-header';
import { common } from '@/styles/styles';
import { getLogoColors } from '@/utils/team-logo-colors';

import { BetSelectionList } from '../../bets-sbk/components/bet-selections/BetSelectionList';
import { Bet, BetEvent, BetStatus } from '../../bets-sbk/hooks/types';

export const ShareBetInfo = ({ bet }: { bet: Bet }) => {
    const { events, status, has_been_cashed_out } = bet;

    const renderItem = useCallback(
        ({ item: event, index }: { item: BetEvent; index: number }) => (
            <ShareBetItem
                key={event.id}
                event={event}
                isLastEvent={index === events.length - 1}
                betStatus={status}
                isCashedOut={has_been_cashed_out}
            />
        ),
        [events.length, has_been_cashed_out, status]
    );

    return (
        <FlatList
            scrollEnabled={false}
            data={events}
            keyExtractor={item => item.id}
            renderItem={renderItem}
            style={[common.full, styles.container]}
        />
    );
};

const styles = StyleSheet.create({
    container: {
        paddingVertical: 20,
        paddingHorizontal: 16,
    },
});

type ShareBetItemProps = {
    event: BetEvent;
    isLastEvent: boolean;
    betStatus: BetStatus;
    isCashedOut: boolean;
};

const ShareBetItem = ({ event, isLastEvent, isCashedOut }: ShareBetItemProps) => {
    const { homeBackgroundColor, homeTextColor, awayBackgroundColor, awayTextColor, shouldShowFallbackLogo } =
        getLogoColors(event);

    const teamLogos: TeamLogos = [
        {
            icon: event.away_team.logo_url!,
            color: awayBackgroundColor,
        },
        {
            icon: event.home_team.logo_url!,
            color: homeBackgroundColor,
        },
    ];

    const teamFallbackTextColors = [awayTextColor, homeTextColor];

    const teamShortCodes = [event.away_team.short_code, event.home_team.short_code];

    const eventHeader = useFormatEventHeader(event);

    const matchUpHeaderProps = {
        logos: teamLogos,
        teamFallbackTextColors,
        teamShortCodes,
        shouldShowFallbackLogo,
        isHeaderDisabled: false,
        eventHeader,
    };

    return (
        <Box>
            <MatchupHeader {...matchUpHeaderProps} />
            <BetSelectionList event={event} isLastEvent={isLastEvent} isCashedOut={isCashedOut} variant="s" />
        </Box>
    );
};
