import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';

import AlertStraightTriangleIcon from '@/assets/icons/alertStraightTriangle';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { BetSummary } from '@/feature/betslip-sbk/components/bet-summary/BetSummary';
import { useIsBetSlipSubmitting, useIsSuspendedOption } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import {
    activeComboBetCountSelector,
    activeScriptBetCountSelector,
    activeSingleBetsSelector,
    totalPayoutSelector,
} from '@/feature/betslip-sbk/utils/betslip-selectors';
import { designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useOneLineSummary } from './hooks/use-one-line-summary';

const ONE_LINE_SUMMARY_OFFSET = 1;

const styles = StyleSheet.create({
    textStyle: {
        marginBottom: -ONE_LINE_SUMMARY_OFFSET,
    },
    textContainerStyle: {
        marginBottom: ONE_LINE_SUMMARY_OFFSET,
    },
    rowStyle: {
        marginTop: 1,
        marginBottom: 14.33,
    },
});

export const OneLineSummary = () => {
    const { t } = useTranslation(['betslip_sbk', 'transactions', 'common']);
    const { showInfoSheet } = useAlerts();
    const isBetSlipSubmitting = useIsBetSlipSubmitting();
    const hasTotalStakeError = useSbkBetSlipStore(state => state.totalStakeErrors.length > 0);

    const showBetSummaryModal = () => {
        GASbkEvents.clickBetSummary();
        showInfoSheet({
            description: <BetSummary />,
            buttonLabel: t('common:close'),
        });
    };

    const activeSingleCount = useSbkBetSlipStore(state => activeSingleBetsSelector(state).length);
    const activeComboCount = useSbkBetSlipStore(activeComboBetCountSelector);
    const activeScriptCount = useSbkBetSlipStore(activeScriptBetCountSelector);
    const totalPayout = useSbkBetSlipStore(totalPayoutSelector);
    const { generateOneLineSummary } = useOneLineSummary();

    const hasActiveBets = activeSingleCount || activeComboCount || activeScriptCount;
    const isSuspendedOption = useIsSuspendedOption();

    if (!hasActiveBets) {
        return (
            <Box alignItems="center" style={styles.rowStyle}>
                <Box borderBottomWidth={1} style={styles.textContainerStyle}>
                    <Text variant="labelLarge" color="gray4" style={styles.textStyle}>
                        {t('add_wager_amount')}
                    </Text>
                </Box>
            </Box>
        );
    }

    const summary = generateOneLineSummary({
        singleCount: activeSingleCount,
        comboCount: activeComboCount,
        scriptCount: activeScriptCount,
    });

    return (
        <Row justifyContent="center" alignItems="center" gap="s8" style={styles.rowStyle}>
            {hasTotalStakeError ? (
                <AlertStraightTriangleIcon width={16} height={16} fill={designSystem.colors.red} />
            ) : null}
            {isSuspendedOption ? (
                <AlertStraightTriangleIcon width={16} height={16} fill={designSystem.colors.gray2} />
            ) : null}
            <TouchableOpacity onPress={showBetSummaryModal} disabled={isBetSlipSubmitting} testID="betSlipSummary">
                <Box
                    borderBottomWidth={1}
                    borderColor={hasTotalStakeError ? 'red' : 'gray2'}
                    style={styles.textContainerStyle}
                >
                    <Text
                        variant="labelLarge"
                        color={hasTotalStakeError ? 'red' : 'gray2'}
                        numberOfLines={1}
                        style={styles.textStyle}
                    >
                        {summary} {t('pays_with_total_payout', { totalPayout: toLocaleCurrency(totalPayout) })}
                    </Text>
                </Box>
            </TouchableOpacity>
        </Row>
    );
};
