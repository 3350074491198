import { useTranslation } from 'react-i18next';

import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { TotalStakeErrors } from '@/feature/betslip-sbk/types';
import { totalStakeValidators } from '@/feature/betslip-sbk/utils/validators';
import { activeLimitsSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';

/**
 * Returns an error message for the total stake
 */
export const useTotalStakeErrorMessages = () => {
    const { t } = useTranslation(['betslip_sbk', 'wallet', 'common']);
    const { data: limits = [] } = useUserLimits({ select: activeLimitsSelector('WAGER') });

    return useSbkBetSlipStore(state => {
        const errors = [...state.totalStakeErrors];

        // if both cash and BetrBucks balance errors are present, it will only show ExceedWalletCashBetrBucksBalance
        if (
            errors.includes(TotalStakeErrors.ExceedWalletCashBalance) &&
            errors.includes(TotalStakeErrors.ExceedWalletBetrBucksBalance)
        ) {
            errors.splice(errors.indexOf(TotalStakeErrors.ExceedWalletCashBalance), 1);
            errors.splice(errors.indexOf(TotalStakeErrors.ExceedWalletBetrBucksBalance), 1);
            errors.push(TotalStakeErrors.ExceedWalletCashBetrBucksBalance);
        }

        return errors
            .map(error => {
                const key = totalStakeValidators[error].translationKey;
                switch (error) {
                    case TotalStakeErrors.ExceedDailyWagerLimit:
                        const dailyLimit = limits.find(limit => limit.duration === 'DAY');
                        if (dailyLimit) {
                            return t(key, { limit_type: t('common:daily') });
                        }
                        break;
                    case TotalStakeErrors.ExceedWeeklyWagerLimit:
                        const weeklyLimit = limits.find(limit => limit.duration === 'WEEK');
                        if (weeklyLimit) {
                            return t(key, { limit_type: t('common:weekly') });
                        }
                        break;
                    case TotalStakeErrors.ExceedMonthlyWagerLimit:
                        const monthlyLimit = limits.find(limit => limit.duration === 'MONTH');
                        if (monthlyLimit) {
                            return t(key, { limit_type: t('common:monthly') });
                        }
                        break;
                    case TotalStakeErrors.ExceedWalletCashBalance:
                        return t(key, { type: t('wallet:cash') });
                    case TotalStakeErrors.ExceedWalletBetrBucksBalance:
                        return t(key, { type: t('wallet:betr_bucks') });
                    case TotalStakeErrors.ExceedWalletCashBetrBucksBalance:
                        return t(key);
                }
            })
            .filter(Boolean);
    });
};
