import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { EntryDetails } from '@/api/entries/types/types';
import { CloseIcon } from '@/assets/icons/close';
import { getWarningBannerExpireTime } from '@/feature/entries-pickem/entries-warning-utils';
import { useEntriesWarningStore } from '@/feature/entries-pickem/hooks/use-entries-warning-store';
import { common, designSystem } from '@/styles/styles';
import { Result, Status } from '@/types/api.generated';

import { Text } from './TextComponent';
import { Box, Row } from './lib/components';

const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray7,
        borderRadius: 16,
        paddingVertical: 14,
        paddingHorizontal: 16,
        gap: 16,
    },
});

export const VoidedEntryWarning = ({
    entry,
    voidedPicksCount,
    newMultiplier,
    initialMultiplier,
}: {
    entry?: EntryDetails;
    voidedPicksCount?: number;
    newMultiplier: string;
    initialMultiplier: string;
}) => {
    const { t } = useTranslation(['betslip_pickem', 'numbers']);
    const [showEntryWarningBanner, setShowEntryWarningBanner] = useState(false);
    const addToEntryWarningBanners = useEntriesWarningStore(state => state.actions.addToEntryWarningBanners);
    const closedEntryWarningBanners = useEntriesWarningStore(state => state.closedEntryWarningBanners);

    useEffect(() => {
        //we don't want to display the warning banner for this entry if we have it in the storage (that means the close button was pressed)
        if (entry?.id && closedEntryWarningBanners[entry.id]) {
            return;
        }

        // In case we didn't press the close button on the banner we check if the entry is open or if the banner expiration time didn't pass current time
        const currentTime = new Date().getTime();
        const settlementDateWithExtraTime = getWarningBannerExpireTime(entry?.settlementDate);
        const showWarningBanner = entry?.status === Status.Pending || currentTime < settlementDateWithExtraTime;
        setShowEntryWarningBanner(showWarningBanner);
    }, [entry, closedEntryWarningBanners]);

    const closePopup = () => {
        setShowEntryWarningBanner(false);
        if (entry?.id) {
            const expireTime = getWarningBannerExpireTime(entry.settlementDate);
            addToEntryWarningBanners(entry.id, expireTime);
        }
    };

    if (!voidedPicksCount || entry?.result === Result.Void || !showEntryWarningBanner) {
        return null;
    }

    return (
        <Row style={styles.root}>
            <Box style={common.flex}>
                <Text variant="buttonLabel">{t('multiplier_updated')}</Text>
                <Text variant="bodySmall" color={'gray2'}>
                    {t('voided_entry_warning', {
                        number: voidedPicksCount,
                        count: voidedPicksCount,
                        initialMultiplier: initialMultiplier,
                        newMultiplier: newMultiplier,
                    })}
                </Text>
            </Box>
            <TouchableOpacity style={common.justifyCenter} onPress={closePopup}>
                <CloseIcon fillColor={designSystem.colors.white} width={16} height={16} />
            </TouchableOpacity>
        </Row>
    );
};
