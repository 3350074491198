import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { designSystem, withOpacity } from '@/styles/styles';

export const BadgeStatus = {
    Open: 'open',
    Live: 'live',
    Voided: 'voided',
    Won: 'won',
    Lost: 'lost',
    Refunded: 'refunded',
} as const;

export type BadgeStatus = (typeof BadgeStatus)[keyof typeof BadgeStatus];

export type StatusBadgeProps = {
    status: BadgeStatus;
    type?: 'pickem' | 'sbk';
};

const getStatusProps = (isSbk: boolean, status: BadgeStatus) => {
    const statusProps = {
        [BadgeStatus.Open]: {
            bgColor: isSbk ? designSystem.colors.gray6 : withOpacity(designSystem.colors.purple2, 0.15),
            textColor: isSbk ? 'gray1' : 'purple1',
        },
        [BadgeStatus.Live]: {
            bgColor: withOpacity(designSystem.colors.purple1, 0.16),
            textColor: 'purple1',
        },
        [BadgeStatus.Voided]: {
            bgColor: designSystem.colors.gray6,
            textColor: 'gray3',
        },
        [BadgeStatus.Won]: {
            bgColor: withOpacity(designSystem.colors.green, 0.16),
            textColor: 'green',
        },
        [BadgeStatus.Lost]: {
            bgColor: isSbk ? withOpacity(designSystem.colors.red, 0.16) : designSystem.colors.gray6,
            textColor: isSbk ? 'red' : 'gray3',
        },
        [BadgeStatus.Refunded]: {
            bgColor: designSystem.colors.gray6,
            textColor: 'white',
        },
    } as const;

    return statusProps[status];
};

export const StatusBadge = ({ status, type = 'sbk' }: StatusBadgeProps) => {
    const { t } = useTranslation('bets');
    const isSbk = type === 'sbk';
    const { bgColor, textColor } = getStatusProps(isSbk, status);
    return (
        <Box
            borderRadius="r8"
            paddingVertical="s4"
            paddingHorizontal="s8"
            alignItems="center"
            style={{ backgroundColor: bgColor }}
        >
            <Text color={textColor} variant="labelMedium" textTransform="capitalize" fontWeight={isSbk ? 600 : 500}>
                {t(status)}
            </Text>
        </Box>
    );
};
