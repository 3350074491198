import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { SCROLLABLE_TOP_TAB_BAR_CONFIG, TOP_TAB_BAR_CONFIG, TopTabBar, TopTabBarProps } from '@/components/TopTabBar';
import { MarketCategoryItem } from '@/feature/event-details-sbk/types';
import { Event } from '@/feature/event-details-sbk/types';
import { LeagueMarketCategoryContent } from '@/feature/league-hub-sbk/components/LeagueMarketCategoryContent';
import { designSystem } from '@/styles/styles';

import { useLeagueTabs } from '../hooks/use-league-tabs';
import { usePrefetchEventLeagueMarketCategories } from '../hooks/use-prefetch-league-market-categories';
import { useSectionHeights } from '../hooks/use-section-heights';
import { FeaturedBetsContent } from './FeaturedBetsContent';
import { MarketsUnavailable } from './MarketsUnavailable';

const TAB_OFF_HEIGHT = 200;
const Tab = createMaterialTopTabNavigator();

const fetchNextTab = (
    state: { index: number },
    marketCategories: MarketCategoryItem[],
    leagueId: string,
    prefetchMarketCategories: (leagueId: string, marketCategoryId: string) => void
) => {
    const { index: tabIndex } = state;
    const marketCategoryId = marketCategories[tabIndex + 1]?.id;

    if (marketCategoryId) {
        prefetchMarketCategories(leagueId, marketCategoryId);
    }
};

type LeagueHubContentProps = {
    leagueId: string;
    marketCategories: MarketCategoryItem[];
    events: Event[];
};

export const LeagueHubContent = ({ events, leagueId, marketCategories }: LeagueHubContentProps) => {
    const { t } = useTranslation(['league_hub_sbk', 'market_categories']);

    const { tabs, isTabBarVisible } = useLeagueTabs({
        marketCategories,
        events,
        leagueId,
    });

    const { gameSectionHeight } = useSectionHeights(leagueId);
    const { smoothScrollHeaderStyle, tabListeners } = useStickyTabsAnimation();
    const prefetchMarketCategories = usePrefetchEventLeagueMarketCategories();

    const renderMarketCategoryTabBar = (props: TopTabBarProps) => {
        return (
            <Animated.View
                style={[
                    smoothScrollHeaderStyle,
                    { backgroundColor: designSystem.colors.gray8, top: gameSectionHeight },
                ]}
            >
                <TopTabBar {...props} customEdges={[]} />
            </Animated.View>
        );
    };

    // first screen does not trigger "focus" correctly on ios
    const navigatorKey = Platform.OS === 'ios' ? tabs?.length : undefined;

    return (
        <Tab.Navigator
            tabBar={renderMarketCategoryTabBar}
            key={navigatorKey}
            style={styles.tabStyles}
            screenOptions={tabs?.length <= 3 ? TOP_TAB_BAR_CONFIG : SCROLLABLE_TOP_TAB_BAR_CONFIG}
            screenListeners={{
                ...tabListeners,
                // listen for navigation change event to prefetch data for the "next" available screen
                state: ({ data: { state } }) =>
                    fetchNextTab(state, marketCategories, leagueId, prefetchMarketCategories),
            }}
        >
            {tabs?.length ? (
                tabs.map(tab => (
                    <Tab.Screen
                        name={`MarketCategories-${tab.type || tab.keyTab}`}
                        key={tab.keyTab}
                        options={{ title: tab.title, tabBarTestID: `marketsTab-${tab.keyTab}` }}
                        initialParams={{ tabDetails: tab }}
                    >
                        {() =>
                            tab.type === 'featured' ? (
                                <FeaturedBetsContent
                                    tabKey={tab.type}
                                    marketCategories={marketCategories}
                                    events={events}
                                    leagueId={leagueId}
                                />
                            ) : (
                                <LeagueMarketCategoryContent
                                    key={tab.keyTab}
                                    marketCategoryId={tab.id}
                                    marketCategoryType={tab.type}
                                    events={events}
                                    leagueId={leagueId}
                                    tabKey={tab.type}
                                    isTabBarVisible={isTabBarVisible}
                                />
                            )
                        }
                    </Tab.Screen>
                ))
            ) : (
                <Tab.Screen name="MarketCategories-noMarkets" key="noMarket">
                    {() => (
                        <MarketsUnavailable
                            offHeight={TAB_OFF_HEIGHT}
                            description={t('league_markets_unavailable_description')}
                        />
                    )}
                </Tab.Screen>
            )}
        </Tab.Navigator>
    );
};

const styles = StyleSheet.create({
    tabStyles: {
        backgroundColor: designSystem.colors.gray8,
        paddingTop: 0,
    },
});
