import { useMemo } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetSlipMarketName } from '@/feature/betslip-sbk/components/BetSlipMarketName';
import { BetInfo, SUSPENDED_TEXT_COLOR } from '@/feature/betslip-sbk/components/bet-info-sheet/BetInfoSheet';
import { useActiveSelectionCount, useComboOdds } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetTypes } from '@/feature/betslip-sbk/types';
import { adjustedPayout, hasMaxOddsError, isComboBetSelectionsValid } from '@/feature/betslip-sbk/utils/betslip-utils';
import { formatEventTeams } from '@/feature/betslip-sbk/utils/formatter';
import { isOptionSuspended } from '@/utils/option-status';

export const useBetSummary = (): BetInfo[] => {
    const { t } = useTranslation('bets');

    const comboOdds = useComboOdds();
    const activeSelectionCount = useActiveSelectionCount();

    const { selections, options, events, markets, producerStatus } = useSbkBetSlipStore(state => ({
        selections: state.selections,
        options: state.options,
        events: state.events,
        markets: state.markets,
        producerStatus: state.producerStatus,
    }));

    const validBets = useSbkBetSlipStore(state => {
        return Object.values(state.bets)
            .filter(bet => !!bet.stake)
            .filter(bet => {
                let isBetSelectionValid = true;
                if (bet.betType === BetTypes.Combo) {
                    isBetSelectionValid = isComboBetSelectionsValid(state);
                }
                return isBetSelectionValid && !hasMaxOddsError(bet, state);
            });
    });

    return useMemo(() => {
        const data: BetInfo[] = [];
        validBets.forEach(bet => {
            let isSuspended = false;
            const stake = Number(bet.stake);
            const isBetrBucks = !!bet.isBetrBucks;
            switch (bet.betType) {
                case 'SINGLE':
                    const selection = selections[bet.id];
                    const odds = options[selection.optionId].odds;
                    const event = events[selection.eventId];
                    const option = options[selection.optionId];
                    const optionStatus = option.status;
                    const isMarketPublished = markets[option.marketId].published;
                    isSuspended = isOptionSuspended(optionStatus, producerStatus, isMarketPublished);
                    data.push({
                        name: (
                            <>
                                {`${formatEventTeams(event)} • `}
                                <BetSlipMarketName
                                    eventId={selection.eventId}
                                    marketId={selection.marketId}
                                    color={isSuspended ? SUSPENDED_TEXT_COLOR : 'white'}
                                />
                            </>
                        ),
                        odds,
                        stake,
                        payout: adjustedPayout(odds, bet),
                        isSuspended,
                        isBetrBucks,
                    });
                    break;
                case 'COMBO':
                    const name = t('combo_bet_name', { count: activeSelectionCount });
                    data.unshift({
                        name,
                        odds: comboOdds,
                        stake,
                        payout: adjustedPayout(comboOdds, bet),
                        isSuspended,
                        isBetrBucks,
                    });
                    break;
            }
        });
        return data;
    }, [activeSelectionCount, comboOdds, events, markets, options, producerStatus, selections, t, validBets]);
};
